import React from 'react';
import type { MedicalMenuTablePresenterType, MenuType } from '../util/common/type';
import { MedicalMenuRecordViews } from '../views/MedicalMenuRecordViews';
import type { MedicalMenuTableHeaderType } from '../util/components/parts';

export const MedicalMenuTablePresenter: React.FC<MedicalMenuTablePresenterType> = ({
  filteredLists,
  categoryList,
  setSortingFactorNumber,
  isSort,
  setIsSort,
  setOrder,
  bulkDeleteArr,
  setBulkDeleteArr,
  lastIndexNum,
  orderOfTable,
  setOrderOfTable,
  sortingFactorNumberOfTable,
  setSortingFactorNumberOfTable,
}) => {
  return (
    <Outline>
      <MedicalMenuTable>
        <MedicalMenuTableHeader
          isSort={isSort}
          setIsSort={setIsSort}
          sortingFactorNumberOfTable={sortingFactorNumberOfTable}
          setSortingFactorNumber={setSortingFactorNumber}
          setSortingFactorNumberOfTable={setSortingFactorNumberOfTable}
          orderOfTable={orderOfTable}
          setOrder={setOrder}
          setOrderOfTable={setOrderOfTable}
        />
        <MedicalMenuTableBody>
          {filteredLists.length > 0 ? (
            filteredLists.map((list: MenuType, index: number) => (
              <MedicalMenuRecordViews
                key={list.SDK_ID}
                record={list}
                bulkDeleteArr={bulkDeleteArr}
                setBulkDeleteArr={setBulkDeleteArr}
                index={index}
                filteredLists={filteredLists}
                categoryList={categoryList}
                lastIndexNum={lastIndexNum}
              />
            ))
          ) : (
            <NoMedicalMenuResult />
          )}
        </MedicalMenuTableBody>
      </MedicalMenuTable>
    </Outline>
  );
};

// NOTE 230604 Commonからインポートする（まだmainブランチに入ってない）
export interface OutlineType {
  children?: React.ReactNode;
}

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "relative overflow-x-auto shadow-md sm:rounded-lg"
  return <div className={outlineStyle}>{children}</div>;
};

const MedicalMenuTable: React.FC<OutlineType> = ({ children }) => {
  const tableStyle = "w-full text-sm text-left text-gray-500 dark:text-gray-400"
  return (
    <table className={tableStyle}>{children}</table>
  );
};

const handleSortClick = (
  columnNumber: number,
  isSort: boolean,
  setIsSort: (isSort: boolean) => void,
  sortingFactorNumberOfTable: number,
  orderOfTable: string,
  setOrderOfTable: (order: string) => void,
  setOrder: (order: string) => void,
  setSortingFactorNumberOfTable: (num: number) => void,
  setSortingFactorNumber: (num: number) => void
): void => {
  if (isSort) {
    if (sortingFactorNumberOfTable !== columnNumber) {
      setOrderOfTable('ASC');
    } else {
      setOrderOfTable(orderOfTable === 'ASC' ? 'DESC' : 'ASC');
    }
    setOrder(orderOfTable);
    setSortingFactorNumberOfTable(columnNumber);
    setSortingFactorNumber(columnNumber);
  } else {
    setIsSort(true);
    setOrder('ASC');
    setSortingFactorNumberOfTable(columnNumber);
    setSortingFactorNumber(columnNumber);
  }
};

const SortableHeaderCell: React.FC<{
  title: string;
  columnNumber: number;
  sortProps: {
    isSort: boolean;
    setIsSort: (isSort: boolean) => void;
    sortingFactorNumberOfTable: number;
    orderOfTable: string;
    setOrderOfTable: (order: string) => void;
    setOrder: (order: string) => void;
    setSortingFactorNumberOfTable: (num: number) => void;
    setSortingFactorNumber: (num: number) => void;
  };
}> = ({ title, columnNumber, sortProps }) => {
  const {
    isSort,
    setIsSort,
    sortingFactorNumberOfTable,
    orderOfTable,
    setOrderOfTable,
    setOrder,
    setSortingFactorNumberOfTable,
    setSortingFactorNumber,
  } = sortProps;

  return (
    <th
      scope="col"
      className="text-center"
      onClick={() => {
        handleSortClick(
          columnNumber,
          isSort,
          setIsSort,
          sortingFactorNumberOfTable,
          orderOfTable,
          setOrderOfTable,
          setOrder,
          setSortingFactorNumberOfTable,
          setSortingFactorNumber
        );
      }}
    >
      {title}
    </th>
  );
};

const MedicalMenuTableHeader: React.FC<MedicalMenuTableHeaderType> = (props): JSX.Element => {
  const theadStyle = "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400";
  const theadCheckboxStyle = "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
  
  const sortProps = {
    isSort: props.isSort,
    setIsSort: props.setIsSort,
    sortingFactorNumberOfTable: props.sortingFactorNumberOfTable,
    orderOfTable: props.orderOfTable,
    setOrderOfTable: props.setOrderOfTable,
    setOrder: props.setOrder,
    setSortingFactorNumberOfTable: props.setSortingFactorNumberOfTable,
    setSortingFactorNumber: props.setSortingFactorNumber,
  };

  return (
    <thead className={theadStyle}>
      <tr>
        <th scope="col" className="p-4">
          <div className="flex items-center">
            <input
              id="checkbox-all-search"
              type="checkbox"
              disabled
              className={theadCheckboxStyle}
            />
          </div>
        </th>
        <SortableHeaderCell title="診療名称" columnNumber={0} sortProps={sortProps} />
        <SortableHeaderCell title="略称" columnNumber={0} sortProps={sortProps} />
        <SortableHeaderCell title="治療時間" columnNumber={3} sortProps={sortProps} />
        <SortableHeaderCell title="タイプ" columnNumber={4} sortProps={sortProps} />
        <th scope="col" className="text-center">サブカテゴリ</th>
        <SortableHeaderCell title="担当" columnNumber={5} sortProps={sortProps} />
        <SortableHeaderCell title="状態" columnNumber={6} sortProps={sortProps} />
        <th scope="col" className="text-center">定期診療頻度</th>
        <th scope="col" className="px-2 py-3">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  );
};

const MedicalMenuTableBody: React.FC<OutlineType> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

const NoMedicalMenuResult: React.FC = () => {
  return (
    <tr className="h-20">
      <th className="p-5" colSpan={8}>
        該当する診療メニュー情報は見つかりませんでした。
      </th>
    </tr>
  );
};
