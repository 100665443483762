import React, { useContext, useMemo, useEffect, useState } from 'react';
import { RecallListPresenter } from '../presenter/RecallListPresenter';
import { useGraphqlClient, ClinicContext } from '@/App';
import { useListRecallItemsByClinicAndStatusQuery } from '@/_graphql/graphql-client';
import { useLocation } from '@tanstack/react-location';
import { differenceInDays } from 'date-fns';
import { RecallStatusBadge } from '../components/RecallStatusBadge';
import { StatusFilterGroup } from './StatusFilterGroup';
import PatientFilterInputs from './PatientFilterInputs';
import { SortControls } from './SortControls';
import { useRecallFilters } from '../hooks/useRecallFilters';
import { useRecallFiltering } from '../hooks/useRecallFiltering';
import { useRecallSorting } from '../hooks/useRecallSorting';
import type { Recall, RecallStatus } from '@/_graphql/graphql-client';
import type { SortKey } from '../types';
// import 'react-datepicker/dist/react-datepicker.css';
import { Text } from '@mantine/core';

const statusLabels: Record<RecallStatus, string> = {
  PENDING: '未対応',
  SCHEDULED: '予約済み',
  COMPLETED: '終了した定期診療',
  CANCELLED: '無視したリコール',
} as const;

// フィルターコントロールコンポーネント
const FilterControls = ({
  selectedStatuses,
  nameFilter,
  snumberFilter,
  handleStatusChange,
  setNameFilter,
  setSnumberFilter,
  selectedMonth,
  setSelectedMonth
}: {
  selectedStatuses: Set<RecallStatus>;
  nameFilter: string;
  snumberFilter: string;
  handleStatusChange: (status: RecallStatus) => void;
  setNameFilter: (value: string) => void;
  setSnumberFilter: (value: string) => void;
  selectedMonth: string;
  setSelectedMonth: (value: string) => void;
}) => (
  <div className="p-4 space-y-4 border-b">
    <div className="grid grid-cols-2 gap-4">
      <div className="space-y-4">
        <StatusFilterGroup 
          selectedStatuses={selectedStatuses}
          onStatusChange={handleStatusChange}
          statusLabels={statusLabels}
        />
        <PatientFilterInputs
          nameFilter={nameFilter}
          snumberFilter={snumberFilter}
          selectedMonth={selectedMonth}
          onNameChange={setNameFilter}
          onSnumberChange={setSnumberFilter}
          onMonthChange={setSelectedMonth}
        />
      </div>
    </div>
  </div>
);

// ソートコントロールコンポーネント
const SortControlSection = ({
  sortKey,
  sortOrder,
  handleSortChange
}: {
  sortKey: SortKey;
  sortOrder: 'asc' | 'desc';
  handleSortChange: (key: SortKey) => void;
}) => (
  <SortControls 
    sortKey={sortKey}
    sortOrder={sortOrder}
    onSortChange={handleSortChange}
  />
);

// データ処理フック
const useProcessedRecalls = (recallData: any) => {
  return useMemo(() => {
    const recalls = recallData?.listRecallItems?.filter((item: any): item is NonNullable<typeof item> => item !== null) ?? [];
    return recalls.map((recall: Recall) => ({
      ...recall,
      daysUntilDue: differenceInDays(new Date(recall.nextDueDate * 1000), new Date())
    }));
  }, [recallData]);
};

// データ取得フック
const useRecallData = (clinicId: string) => {
  const graphqlClient = useGraphqlClient();
  return useListRecallItemsByClinicAndStatusQuery(graphqlClient, {
    clinic_id: clinicId,
  });
};

// データ再取得フック
const useAutoRefetch = (refetch: () => void, path: string) => {
  useEffect(() => {
    if (path.endsWith('/recall-list')) {
      refetch()
    }
  }, [path, refetch]);
};

// リスト表示コンポーネント
const RecallListView: React.FC<{ 
  data: Recall[];
  selectedStatuses: Set<RecallStatus>;
  nameFilter: string;
  snumberFilter: string;
  handleStatusChange: (status: RecallStatus) => void;
  setNameFilter: (value: string) => void;
  setSnumberFilter: (value: string) => void;
  selectedMonth: string;
  setSelectedMonth: (value: string) => void;
}> = ({ data, selectedStatuses, nameFilter, snumberFilter, handleStatusChange, setNameFilter, setSnumberFilter, selectedMonth, setSelectedMonth }) => {
  // 月別フィルタリング
  const filteredData = useMemo(() => {
    if (!selectedMonth) return data;
    const [year, month] = selectedMonth.split('-').map(Number);
    
    // JSTの月範囲をUTCタイムスタンプで計算
    const startJST = Date.UTC(year, month - 1, 1) - 9 * 3600 * 1000; // JST開始時刻（前月31日15:00 UTC）
    const endJST = Date.UTC(year, month, 1) - 9 * 3600 * 1000 - 1; // JST終了時刻（当月31日14:59:59 UTC）
    
    return data.filter(item => 
      item.nextDueDate >= startJST / 1000 && 
      item.nextDueDate <= endJST / 1000
    );
  }, [data, selectedMonth]);

  // 患者別集計（フィルタリング後のデータから算出）
  const patientCount = useMemo(() => {
    const uniquePatients = new Set(
      filteredData.map(item => item.patientId)
    );
    return uniquePatients.size;
  }, [filteredData]);

  return (
    <div className="h-full flex flex-col">
      <FilterControls 
        selectedStatuses={selectedStatuses}
        nameFilter={nameFilter}
        snumberFilter={snumberFilter}
        handleStatusChange={handleStatusChange}
        setNameFilter={setNameFilter}
        setSnumberFilter={setSnumberFilter}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
      <div className="flex items-center justify-end mb-4">
        <Text size="sm" color="text.secondary">
          定期診療対象: {patientCount}名
        </Text>
      </div>
      
      <div className="flex-1 overflow-auto">
        <RecallListPresenter 
          recalls={filteredData}
          renderPatientName={(recall: Recall) => (
            <div className="flex items-center gap-2">
              <span>{`${recall.patient?.name?.last}${recall.patient?.name?.first}`}</span>
              <RecallStatusBadge status={recall.status} />
            </div>
          )}
        />
      </div>
    </div>
  );
};

// メインコンポーネント
export const RecallListViews: React.FC = () => {
  const clinic = useContext(ClinicContext);
  const location = useLocation();
  const { data: recallData, refetch } = useRecallData(clinic.clinic_id);
  const processedRecalls = useProcessedRecalls(recallData);
  
  useAutoRefetch(refetch, location.current.pathname);

  const { 
    selectedStatuses,
    sortKey,
    sortOrder,
    nameFilter,
    snumberFilter,
    handleStatusChange,
    handleSortChange,
    setNameFilter,
    setSnumberFilter
  } = useRecallFilters();

  const filteredRecalls = useRecallFiltering(processedRecalls, selectedStatuses, nameFilter, snumberFilter);
  const sortedRecalls = useRecallSorting(filteredRecalls, sortKey, sortOrder);

  const [selectedMonth, setSelectedMonth] = useState<string>(() => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`;
  });

  return (
    <div className="h-full flex flex-col">
      <SortControlSection 
        sortKey={sortKey}
        sortOrder={sortOrder}
        handleSortChange={handleSortChange}
      />
      <RecallListView 
        data={sortedRecalls}
        selectedStatuses={selectedStatuses}
        nameFilter={nameFilter}
        snumberFilter={snumberFilter}
        handleStatusChange={handleStatusChange}
        setNameFilter={setNameFilter}
        setSnumberFilter={setSnumberFilter}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
    </div>
  );
}; 