import React from 'react';
import { format } from 'date-fns';
import type { Recall } from '@/_graphql/graphql-client';

interface RecallDateInfoProps {
  recall: Recall;
}

export const RecallDateInfo: React.FC<RecallDateInfoProps> = ({ recall }) => {
  const { lastTreatmentDate, nextDueDate, daysUntilDue } = recall;

  return (
    <div className="mt-1 flex items-center gap-x-4">
      <p className="truncate text-xs leading-5 text-gray-500">
        前回治療日: {format(new Date(lastTreatmentDate * 1000), 'yyyy/MM/dd')}
      </p>
      <p className="truncate text-xs leading-5 text-gray-500">
        次回予定日: {format(new Date(nextDueDate * 1000), 'yyyy/MM/dd')}
      </p>
      {daysUntilDue > 0 ? (
        <p className="truncate text-xs leading-5 text-gray-500">
          残り{daysUntilDue}日
        </p>
      ) : (
        <p className="truncate text-xs leading-5 text-red-500">
          {Math.abs(daysUntilDue)}日経過
        </p>
      )}
    </div>
  );
}; 