import { useState } from 'react';
import { RecallStatus } from '@/_graphql/graphql-client';
import type { SortKey } from '../types';

export const useRecallFilters = () => {
  const [selectedStatuses, setSelectedStatuses] = useState<Set<RecallStatus>>(new Set([RecallStatus.Pending]));
  const [sortKey, setSortKey] = useState<SortKey>('daysUntilDue');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [nameFilter, setNameFilter] = useState('');
  const [snumberFilter, setSnumberFilter] = useState('');

  const handleStatusChange = (status: RecallStatus) => {
    const newSelectedStatuses = new Set(selectedStatuses);
    newSelectedStatuses.has(status) ? newSelectedStatuses.delete(status) : newSelectedStatuses.add(status);
    setSelectedStatuses(newSelectedStatuses);
  };

  const handleSortChange = (key: SortKey) => {
    if (sortKey === key) {
      setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  return {
    selectedStatuses,
    sortKey,
    sortOrder,
    nameFilter,
    snumberFilter,
    handleStatusChange,
    handleSortChange,
    setNameFilter,
    setSnumberFilter,
  };
}; 