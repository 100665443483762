import React from 'react';

type Props = {
  nameFilter: string;
  snumberFilter: string;
  selectedMonth: string;
  onNameChange: (value: string) => void;
  onSnumberChange: (value: string) => void;
  onMonthChange: (value: string) => void;
};

export default function PatientFilterInputs({
  nameFilter,
  snumberFilter,
  selectedMonth,
  onNameChange,
  onSnumberChange,
  onMonthChange,
}: Props) {
  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="flex flex-col">
        <label className="text-sm font-medium mb-1">患者名</label>
        <input
          type="text"
          value={nameFilter}
          onChange={(e) => onNameChange(e.target.value)}
          className="border rounded px-3 py-2"
          placeholder="名前で検索"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-sm font-medium mb-1">患者番号</label>
        <input
          type="text"
          value={snumberFilter}
          onChange={(e) => onSnumberChange(e.target.value)}
          className="border rounded px-3 py-2"
          placeholder="患者番号で検索"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-sm font-medium mb-1">対象月</label>
        <input
          type="month"
          value={selectedMonth}
          onChange={(e) => onMonthChange(e.target.value)}
          className="border rounded px-3 py-2"
        />
      </div>
    </div>
  );
} 