import { useQueryClient } from 'react-query';
import { useGraphqlClient } from '@/App';
import { useUpdateRecallStatusMutation } from '@/_graphql/graphql-client';

export const useUpdateRecallStatus = () => {
  const graphqlClient = useGraphqlClient()
  const queryClient = useQueryClient()

  const updateRecallStatus = useUpdateRecallStatusMutation(graphqlClient, {
    onSettled: () => {
      void queryClient.invalidateQueries('listRecallItemsByClinicAndStatus');
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['listRecallItemsByClinicAndStatus'] });
      void queryClient.invalidateQueries({ queryKey: ['listRecallItemsByClinicPatientAndMenu'] });
      void queryClient.invalidateQueries({ queryKey: ['listRecallItems'] });
      void queryClient.invalidateQueries({ queryKey: ['getAppointByIdV2'] });
    },
    onError: error => {
      throw error
    },
    retry: 3
  })

  return updateRecallStatus
}; 