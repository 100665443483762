import { useMemo } from 'react';
import type { Recall } from '@/_graphql/graphql-client';
import type { SortKey } from '../types';

export const useRecallSorting = (
  filteredRecalls: Recall[],
  sortKey: SortKey,
  sortOrder: 'asc' | 'desc'
) => {
  return useMemo(() => {
    console.log('ソート前データ:', filteredRecalls);
    const sorted = [...filteredRecalls].sort((a, b) => {
      const valA = a[sortKey];
      const valB = b[sortKey];
      console.log(`ソート比較: ${valA} vs ${valB}`);
      return (valA - valB) * (sortOrder === 'asc' ? 1 : -1);
    });
    console.log('ソート後データ:', sorted);
    return sorted;
  }, [filteredRecalls, sortKey, sortOrder]);
}; 