import React from 'react'
import type { CategoryType } from '../../../common/type'
import type { MedicalCheckupFrequency } from '@/_graphql/graphql-client'
import type { MedicalRole, MedicalStatus, MedicalSubject } from '../../../common/types'
import { medicalRole, medicalStatus, medicalSubjects } from '../../../common/types'

interface FormInputsProps {
  treatmentName: string
  setTreatmentName: (value: string) => void
  abbreviation: string
  setAbbreviation: (value: string) => void
  treatmentTime: number
  setTreatmentTime: (value: number) => void
  subject: MedicalSubject
  setSubject: (value: MedicalSubject) => void
  subCategoryId: string
  setSubCategoryId: (value: string) => void
  role: MedicalRole
  setRole: (value: MedicalRole) => void
  menuStatus: MedicalStatus
  setMenuStatus: (value: MedicalStatus) => void
  isPeriodicCheckup: boolean
  setIsPeriodicCheckup: (value: boolean) => void
  checkupFrequency: MedicalCheckupFrequency | null
  setCheckupFrequency: (value: MedicalCheckupFrequency | null) => void
  checkupIntervalDays: number | null
  setCheckupIntervalDays: (value: number | null) => void
  treatmentTimes: number[]
  selectableCategoryList: CategoryType[]
  addMenuExecute: () => void
}

export const FormInputs: React.FC<FormInputsProps> = ({
  treatmentName,
  setTreatmentName,
  abbreviation,
  setAbbreviation,
  treatmentTime,
  setTreatmentTime,
  subject,
  setSubject,
  subCategoryId,
  setSubCategoryId,
  role,
  setRole,
  menuStatus,
  setMenuStatus,
  isPeriodicCheckup,
  setIsPeriodicCheckup,
  checkupFrequency,
  setCheckupFrequency,
  checkupIntervalDays,
  setCheckupIntervalDays,
  treatmentTimes,
  selectableCategoryList,
  addMenuExecute
}): JSX.Element => (
  <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
    <th className='w-1/12 p-4'>
      <div className='flex items-center'>
        <input
          id='checkbox-table-search-1'
          type='checkbox'
          className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
        />
      </div>
    </th>
    <BasicInputs
      treatmentName={treatmentName}
      setTreatmentName={setTreatmentName}
      abbreviation={abbreviation}
      setAbbreviation={setAbbreviation}
    />
    <SelectInputs
      treatmentTime={treatmentTime}
      setTreatmentTime={setTreatmentTime}
      subject={subject}
      setSubject={setSubject}
      subCategoryId={subCategoryId}
      setSubCategoryId={setSubCategoryId}
      role={role}
      setRole={setRole}
      menuStatus={menuStatus}
      setMenuStatus={setMenuStatus}
      treatmentTimes={treatmentTimes}
      selectableCategoryList={selectableCategoryList}
    />
    <PeriodicCheckupInputs
      isPeriodicCheckup={isPeriodicCheckup}
      setIsPeriodicCheckup={setIsPeriodicCheckup}
      checkupFrequency={checkupFrequency}
      setCheckupFrequency={setCheckupFrequency}
      checkupIntervalDays={checkupIntervalDays}
      setCheckupIntervalDays={setCheckupIntervalDays}
    />
    <td className='w-1/12 pr-4 py-4 text-right'>
      <button
        type='submit'
        className='leading-8 text-gray-100 text-sm font-medium h-8 w-20 text-center rounded-2xl bg-red-500 hover:bg-red-600'
        onClick={(e): void => {
          e.preventDefault()
          addMenuExecute()
        }}
      >
        保存
      </button>
    </td>
  </tr>
)

const BasicInputs: React.FC<{
  treatmentName: string
  setTreatmentName: (value: string) => void
  abbreviation: string
  setAbbreviation: (value: string) => void
}> = ({ treatmentName, setTreatmentName, abbreviation, setAbbreviation }) => (
  <>
    <td scope='row' className='w-3/12 font-medium text-gray-900 dark:text-white whitespace-nowrap'>
      <input
        type='text'
        id='treatmentName'
        className='bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        placeholder='正式名称'
        required
        value={treatmentName}
        onChange={(e): void => { setTreatmentName(e.target.value) }}
      />
    </td>
    <td scope='row' className='w-3/12 font-medium text-gray-900 dark:text-white whitespace-nowrap'>
      <input
        type='text'
        id='abbreviation'
        className='bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        placeholder='略称'
        required
        value={abbreviation}
        onChange={(e): void => { setAbbreviation(e.target.value) }}
      />
    </td>
  </>
)

const SelectInputs: React.FC<{
  treatmentTime: number
  setTreatmentTime: (value: number) => void
  subject: MedicalSubject
  setSubject: (value: MedicalSubject) => void
  subCategoryId: string
  setSubCategoryId: (value: string) => void
  role: MedicalRole
  setRole: (value: MedicalRole) => void
  menuStatus: MedicalStatus
  setMenuStatus: (value: MedicalStatus) => void
  treatmentTimes: number[]
  selectableCategoryList: CategoryType[]
}> = ({
  treatmentTime,
  setTreatmentTime,
  subject,
  setSubject,
  subCategoryId,
  setSubCategoryId,
  role,
  setRole,
  menuStatus,
  setMenuStatus,
  treatmentTimes,
  selectableCategoryList
}) => (
  <>
    <td className='w-1/12 px-2 py-4'>
      <select
        id='treatmentTimes'
        className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        value={treatmentTime}
        onChange={(e): void => { setTreatmentTime(Number(e.target.value)) }}
      >
        {treatmentTimes.map((time: number, index: number) => (
          <option value={time} key={index}>
            {time}
          </option>
        ))}
      </select>
    </td>
    <td className='w-1/12 px-2 py-4'>
      <select
        id='subject'
        className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        value={subject}
        onChange={(e): void => { setSubject(e.target.value as MedicalSubject) }}
      >
        {medicalSubjects.map((subject: string, index: number) => (
          <option value={subject} key={index}>
            {subject}
          </option>
        ))}
      </select>
    </td>
    <td>
      <select
        name="subCategory"
        id="subCategory"
        value={subCategoryId}
        onChange={(e): void => { setSubCategoryId(e.target.value) }}
      >
        {selectableCategoryList.map(category => (
          <option value={category.id} key={category.id}>
            {category.name}
          </option>
        ))}
      </select>
    </td>
    <td className='w-1/12 px-2 py-4'>
      <select
        id='maneger'
        className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        value={role}
        onChange={(e): void => { setRole(e.target.value as MedicalRole) }}
      >
        <option hidden></option>
        {medicalRole.map((role: string, index: number) => (
          <option value={role} key={index}>
            {role}
          </option>
        ))}
      </select>
    </td>
    <td className='w-1/12 px-2 py-4'>
      <select
        id='status'
        className='w-full g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        value={menuStatus}
        onChange={(e): void => { setMenuStatus(e.target.value as MedicalStatus) }}
      >
        {medicalStatus.map((state: string, index: number) => (
          <option value={state} key={index}>
            {state}
          </option>
        ))}
      </select>
    </td>
  </>
)

const PeriodicCheckupInputs: React.FC<{
  isPeriodicCheckup: boolean
  setIsPeriodicCheckup: (value: boolean) => void
  checkupFrequency: MedicalCheckupFrequency | null
  setCheckupFrequency: (value: MedicalCheckupFrequency | null) => void
  checkupIntervalDays: number | null
  setCheckupIntervalDays: (value: number | null) => void
}> = ({
  isPeriodicCheckup,
  setIsPeriodicCheckup,
  checkupFrequency,
  setCheckupFrequency,
  checkupIntervalDays,
  setCheckupIntervalDays
}) => (
  <td className='w-1/12 px-2 py-4'>
    <div className='flex flex-col gap-2'>
      <label className='flex items-center'>
        <input
          type='checkbox'
          className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'
          checked={isPeriodicCheckup}
          onChange={(e): void => { setIsPeriodicCheckup(e.target.checked) }}
        />
        <span className='ml-2 text-sm'>定期診療</span>
      </label>
      {isPeriodicCheckup && (
        <>
          <select
            id='checkupFrequency'
            className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block'
            value={checkupFrequency ?? ''}
            onChange={(e): void => { setCheckupFrequency(e.target.value as MedicalCheckupFrequency | null) }}
          >
            <option value=''>頻度を選択</option>
            <option value='WEEKLY'>毎週</option>
            <option value='BIWEEKLY'>隔週</option>
            <option value='MONTHLY'>毎月</option>
            <option value='BIMONTHLY'>隔月</option>
            <option value='QUARTERLY'>3ヶ月ごと</option>
            <option value='HALFYEARLY'>半年ごと</option>
            <option value='CUSTOM'>カスタム</option>
          </select>
          {checkupFrequency === 'CUSTOM' && (
            <input
              type='number'
              id='checkupIntervalDays'
              className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block'
              placeholder='間隔（日数）'
              value={checkupIntervalDays ?? ''}
              onChange={(e): void => { setCheckupIntervalDays(e.target.value === '' ? null : Number(e.target.value)) }}
              required={checkupFrequency === 'CUSTOM'}
            />
          )}
        </>
      )}
    </div>
  </td>
) 