import type { AppointShiftWrapperPropsType } from '@/components/Reservation/entities';

export const _AppointShiftWrapper: React.FC<AppointShiftWrapperPropsType> = ({
    printRef,
    children
}) => {
    return (
        <div 
            ref={printRef}
            className="relative w-full h-full overflow-hidden"
            style={{ minWidth: '1200px' }}
        >
            {children}
        </div>
    );
};
