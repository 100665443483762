import type { AllPanesContextType } from '@/components/Main/entities'
import { createContext, useContext, useRef, useState } from "react";
import { Allotment, AllotmentHandle } from "allotment";
import { LeftPane } from "../components/panes/LeftPane/LeftPane"
import { TopPane } from "@/components/Main/components/panes/TopPane/TopPane"
import { BottomPane } from "@/components/Main/components/panes/BottomPane/BottomSubPane"
import "allotment/dist/style.css";
import { useLocation } from '@tanstack/react-location';
import { useResetPaneSize } from '../hooks/useResetPaneReset';
import { CommonHeaderViews } from '@/components/Common/components/Header/views/CommonHeaderViews';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { ClinicContext, useGraphqlClient } from '@/App';
import type { SearchType } from '@/components/Common/entities';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { useAppointParams } from '@/domain/Appointment/useAppointParams';
import { useBlockParams } from '@/domain/Block/useBlockParams';

// FIXME : util関数
function getOperation(pathname: string, search: SearchType) {
    const isAdd = pathname.endsWith('/add-appoint')
    const isReference = pathname.endsWith('/appoint-info') || (pathname.endsWith('/add-appoint') && Boolean(search?.['block-id']))
    const isEdit = pathname.endsWith('/edit') && !Boolean(search?.['copy']);
    const isUpdate = pathname.endsWith('/update')
    const isCopy = Boolean(search?.['copy'])

    if (isCopy) {
        return 'copy'
    } else if (isUpdate) {
        return 'update'
    } else if (isEdit) {
        return 'edit'
    } else if (isReference) {
        return 'reference'
    } else if (isAdd) {
        return 'add'
    }
}

export const AllPanesContext = createContext({} as AllPanesContextType)

const paneDefaultSize = {
    top: {
        open: { preferredSize: 30, minSize: 30, maxSize: 30, isOpen: true },
        close: { preferredSize: 10, minSize: 0, isClose: true },
    },
    left: {
        open: { preferredSize: 18, maxSize: 200, minSize: 200, isOpen: true },
        close: { maxSize: 20, minSize: 20, isOpen: false }
    },
    bottom: { open: { isOpen: true }, close: { isOpen: false } }
}

export const PanesProvider: React.FC = () => {

    const matches = useMediaQuery('(min-width: 926px)')

    //医院メタデータ
    const clinic = useContext(ClinicContext)

    //URLパラメータ関連------------------------------------
    const location = useLocation();
    const search: SearchType = location.current.search;
    const pathname = location.current.pathname;

    const { startTime, endTime, facilityId } = useCommonParams()
    const { patient_id, appointId } = useAppointParams()
    const { block_id } = useBlockParams()
    //FIXME
    const patientId = patient_id;
    const blockId = block_id;

    //Allotment関連------------------------------------
    // メインPane（left or right）参照
    const panesRef = useRef<AllotmentHandle>(null);
    // サブPane（top or bottom）参照
    const subPanesRef = useRef<AllotmentHandle>(null);
    // パスによるPane表示切替
    const visibility = pathname.endsWith('/main/') === false
    //Paneサイズ
    const { height } = useViewportSize()
    const rightPanesHeight = height - 33;

    // useEffect------------------------------------
    //レンダリング時にPaneサイズリセット
    useResetPaneSize(panesRef)
    // graphqlClient---------------------------------
    const graphqlClient = useGraphqlClient()
    const operation = getOperation(pathname, search);


    // 各ペインのサイズ情報
    const [paneSize, setPaneSize] = useState({
        top: { isOpen: true, preferredSize: 32, minSize: 0 },
        left: { isOpen: true, preferredSize: 18, maxSize: 200, minSize: 200, },
        bottom: { isOpen: true },
    })

    return (
        <>
            {height &&
                <AllPanesContext.Provider value={{ panesRef, subPanesRef, paneSize, setPaneSize, paneDefaultSize }}>
                    <div className="w-full h-screen overflow-hidden">

                        <Allotment ref={panesRef} snap={false} vertical={false} minSize={0} onChange={console.log}>

                            <Allotment.Pane visible={matches} minSize={paneSize.left.minSize} maxSize={paneSize.left.maxSize} preferredSize={`${paneSize.left.preferredSize}%`}>
                                <LeftPane isOpen={paneSize.left.isOpen} />
                            </Allotment.Pane>

                            <Allotment.Pane>
                                <CommonHeaderViews />
                                <div style={{ height: rightPanesHeight }}>

                                    <Allotment snap={false} vertical ref={subPanesRef}>

                                        <Allotment.Pane minSize={paneSize.top.minSize} preferredSize={`${paneSize.top.preferredSize}%`} visible={matches && visibility}>
                                            <TopPane
                                                graphqlClient={graphqlClient}

                                                //パラメータ
                                                search={search}
                                                pathname={pathname}
                                                operation={operation}

                                                patientId={patientId || ''}
                                                blockId={blockId || ''}
                                                startTime={startTime || 0}
                                                endTime={endTime || 0}
                                                facilityId={facilityId || ''}
                                                appointId={appointId || ''}
                                            />
                                        </Allotment.Pane>

                                        <Allotment.Pane>
                                            <BottomPane
                                                clinic={clinic}
                                                graphqlClient={graphqlClient}

                                                //パラメータ
                                                search={search}
                                                pathname={pathname}
                                                operation={operation}

                                                patientId={patientId || ''}
                                                blockId={blockId || ''}
                                                startTime={startTime || 0}
                                                endTime={endTime || 0}
                                                facilityId={facilityId || ''}
                                                appointId={appointId || ''}
                                            />
                                        </Allotment.Pane>

                                    </Allotment>
                                </div>
                            </Allotment.Pane>
                        </Allotment>
                    </div>
                </AllPanesContext.Provider >}
        </>
    )
}
