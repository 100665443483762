import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { MedicalMenuRecordPresenterType, MenuType } from '../util/common/type';
import type {
  DeleteMedicalMenuButtonType,
  EditMedicalMenuButtonType,
  MedicalMenuCheckboxItemType,
  MedicalMenuInfoType,
  UpdateIndexButtonType,
} from '../util/components/parts';

export const MedicalMenuRecordPresenter: React.FC<MedicalMenuRecordPresenterType> = ({
  editMode, setEditMode, EditMenuModal, categoryList,
  record, index, lastIndexNum, currentCategory,
  bulkDeleteArr, isCheck, setIsCheck, setBulkDeleteArr,
  updateMenuIndexExecute, disableBtnCss, deleteBtnCss,
  setCurrentMenu, DeleteMenuDialog, setDeleteMenu,
  openDeleteDialog, setOpenDeleteDialog, filteredLists,
}) => (
  <Outline>
    {editMode ? (
      <EditMenuModal categoryList={categoryList} setEditMode={setEditMode} />
    ) : (
      <MainContent
        record={record}
        index={index}
        lastIndexNum={lastIndexNum}
        currentCategory={currentCategory}
        bulkDeleteArr={bulkDeleteArr}
        isCheck={isCheck}
        setIsCheck={setIsCheck}
        setBulkDeleteArr={setBulkDeleteArr}
        updateMenuIndexExecute={updateMenuIndexExecute}
        disableBtnCss={disableBtnCss}
        deleteBtnCss={deleteBtnCss}
        setCurrentMenu={setCurrentMenu}
        setEditMode={setEditMode}
        setDeleteMenu={setDeleteMenu}
        setOpenDeleteDialog={setOpenDeleteDialog}
      />
    )}
    {openDeleteDialog ? (
      <DeleteMenuDialogWrapper
        openDeleteDialog={openDeleteDialog}
        filteredLists={filteredLists}
        setOpenDeleteDialog={setOpenDeleteDialog}
        setBulkDeleteArr={setBulkDeleteArr}
        record={record}
        setDeleteMenu={setDeleteMenu}
        DeleteMenuDialog={DeleteMenuDialog}
      />
    ) : null}
  </Outline>
);

// NOTE 230604 Commonからインポートする（まだmainブランチに入ってない）
export interface OutlineType {
  children?: React.ReactNode;
}

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
  return (
    <tr className={outlineStyle}>
      {children}
    </tr>
  );
};

const ArrangeMedicalMenuItems: React.FC<OutlineType> = ({ children }) => {
  return <td className="w-4 p-4 flex">{children}</td>;
};

const handleCheckboxChange = (
  index: number,
  isCheck: boolean,
  bulkDeleteArr: number[],
  setIsCheck: Dispatch<SetStateAction<boolean>>,
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>
): void => {
  setIsCheck(!isCheck);
  let tmpBulkDeleteArr: number[] = [];
  if (isCheck) {
    if (bulkDeleteArr.includes(index)) {
      tmpBulkDeleteArr = bulkDeleteArr.filter((element: number) => element !== index);
    }
  } else {
    tmpBulkDeleteArr = bulkDeleteArr.slice();
    tmpBulkDeleteArr.push(index);
  }
  tmpBulkDeleteArr.sort((a, b) => a - b);
  setBulkDeleteArr(tmpBulkDeleteArr);
};

const MedicalMenuCheckboxItem: React.FC<MedicalMenuCheckboxItemType> = ({
  index,
  bulkDeleteArr,
  isCheck,
  setIsCheck,
  setBulkDeleteArr,
}) => {
  const containerStyle = "flex items-center pr-4";
  const checkboxStyle = "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
  
  return (
    <div className={containerStyle}>
      <input
        id="checkbox-table-search-1"
        type="checkbox"
        checked={bulkDeleteArr.includes(index)}
        onChange={() => {
          handleCheckboxChange(index, isCheck, bulkDeleteArr, setIsCheck, setBulkDeleteArr);
        }}
        className={checkboxStyle}
      />
    </div>
  );
};

const UpdateIndexButtons: React.FC<OutlineType> = ({ children }) => {
  return <td className="flex flex-col">{children}</td>;
};

const IncrementIndexButton: React.FC<UpdateIndexButtonType> = ({ updateMenuIndexExecute }) => {
  const buttonIconStyle = "text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={buttonIconStyle}
      onClick={() => { updateMenuIndexExecute(true); }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

const DecrementIndexButton: React.FC<UpdateIndexButtonType> = ({ updateMenuIndexExecute }) => {
  const buttonIconStyle = "text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={buttonIconStyle}
      onClick={() => { updateMenuIndexExecute(false); }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

// NOTE 230605 現状使われてない？？？
// const UnknownIndexUpdateButton: React.FC<UpdateIndexButtonType> = ({ updateMenuIndexExecute }) => {
//   return (
//     <button
//       onClick={() => updateMenuIndexExecute()}
//       className="text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer"
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth={1.5}
//         stroke="currentColor"
//         className="w-6 h-6"
//       >
//         <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
//       </svg>
//     </button>
//   );
// };

const MenuInfoCells: React.FC<{
  record: MenuType;
  currentCategory: any;
  menuNameTdStyle: string;
  getCheckupFrequencyText: (frequency: MenuType['checkupFrequency']) => string;
}> = ({ record, currentCategory, menuNameTdStyle, getCheckupFrequencyText }) => (
  <>
    <td scope="row" className={menuNameTdStyle}>
      {record.name}
    </td>
    <td className="px-6 py-4 text-center">{record.abbreviation === '' || record.abbreviation === undefined ? '-' : record.abbreviation}</td>
    <td className="px-6 py-4 text-right">{record.time}分</td>
    <td className="px-6 py-4">{record.type === '' || record.type === undefined ? '-' : record.type}</td>
    <td className="px-6 py-4">{currentCategory?.[0]?.name === '' || currentCategory?.[0]?.name === undefined ? '削除済みカテゴリ' : currentCategory?.[0]?.name}</td>
    <td className="px-6 py-4">{record.role === '' || record.role === undefined ? '-' : record.role}</td>
    <td className="px-6 py-4">{record.status === '' || record.status === undefined ? '-' : record.status}</td>
    <td className="px-6 py-4 text-center">
      {record.isPeriodicCheckup ? getCheckupFrequencyText(record.checkupFrequency) : '-'}
    </td>
  </>
);

const MedicalMenuInfo: React.FC<MedicalMenuInfoType> = ({ record, currentCategory }): JSX.Element => {
  const menuNameTdStyle = "text-center px-12 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap";
  
  const getCheckupFrequencyText = (frequency: MenuType['checkupFrequency'], checkupIntervalDays?: number | null): string => {
    if (frequency === undefined || frequency === null) return '-';
    const frequencyMap: Record<NonNullable<MenuType['checkupFrequency']>, string> = {
      'WEEKLY': '毎週',
      'BIWEEKLY': '隔週',
      'MONTHLY': '毎月',
      'BIMONTHLY': '隔月',
      'QUARTERLY': '3ヶ月ごと',
      'HALFYEARLY': '半年ごと',
      'CUSTOM': typeof checkupIntervalDays === 'number' && checkupIntervalDays > 0 ? `${checkupIntervalDays}日` : 'カスタム'
    };
    return frequencyMap[frequency] ?? '-';
  };

  return (
    <MenuInfoCells
      record={record}
      currentCategory={currentCategory}
      menuNameTdStyle={menuNameTdStyle}
      getCheckupFrequencyText={(frequency) => getCheckupFrequencyText(frequency, record.checkupIntervalDays)}
    />
  );
};

const MedicalMenuManageButtons: React.FC<OutlineType> = ({ children }) => {
  return <td className="px-2 py-4 text-right">{children}</td>;
};

const EditMedicalMenuButton: React.FC<EditMedicalMenuButtonType> = ({
  disableBtnCss,
  record,
  setCurrentMenu,
  setEditMode,
}) => {
  return (
    <button
      type="button"
      className={disableBtnCss}
      onClick={() => {
        setCurrentMenu(record);
        setEditMode(true);
      }}
    >
      編集
    </button>
  );
};

const DeleteMedicalMenuButton: React.FC<DeleteMedicalMenuButtonType> = ({
  bulkDeleteArr,
  deleteBtnCss,
  disableBtnCss,
  index,
  isCheck,
  record,
  setDeleteMenu,
  setOpenDeleteDialog,
}) => {
  return (
    <button
      type="submit"
      className={bulkDeleteArr.includes(index) ? deleteBtnCss : disableBtnCss}
      onClick={() => {
        if (isCheck) {
          setDeleteMenu(record);
          setOpenDeleteDialog(true);
        }
      }}
    >
      削除
    </button>
  );
};

const MenuArrangeSection: React.FC<{
  index: number
  bulkDeleteArr: number[]
  isCheck: boolean
  setIsCheck: Dispatch<SetStateAction<boolean>>
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>
  record: MenuType
  lastIndexNum: number
  updateMenuIndexExecute: (isUp?: boolean) => void
}> = (props) => (
  <ArrangeMedicalMenuItems>
    <MedicalMenuCheckboxItem
      index={props.index}
      bulkDeleteArr={props.bulkDeleteArr}
      isCheck={props.isCheck}
      setIsCheck={props.setIsCheck}
      setBulkDeleteArr={props.setBulkDeleteArr}
    />
    <UpdateIndexButtons>
      {props.record.index > 1 && (
        <IncrementIndexButton updateMenuIndexExecute={props.updateMenuIndexExecute} />
      )}
      {props.record.index < props.lastIndexNum && props.record.index !== null && (
        <DecrementIndexButton updateMenuIndexExecute={props.updateMenuIndexExecute} />
      )}
    </UpdateIndexButtons>
  </ArrangeMedicalMenuItems>
);

const MenuButtons: React.FC<{
  record: MenuType
  index: number
  lastIndexNum: number
  bulkDeleteArr: number[]
  isCheck: boolean
  setIsCheck: Dispatch<SetStateAction<boolean>>
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>
  updateMenuIndexExecute: (isUp?: boolean) => void
  disableBtnCss: string
  deleteBtnCss: string
  setCurrentMenu: Dispatch<SetStateAction<MenuType>>
  setEditMode: Dispatch<SetStateAction<boolean>>
  setDeleteMenu: Dispatch<SetStateAction<MenuType>>
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>
}> = (props) => (
  <>
    <MenuArrangeSection {...props} />
    <MedicalMenuManageButtons>
      <EditMedicalMenuButton
        disableBtnCss={props.disableBtnCss}
        record={props.record}
        setCurrentMenu={props.setCurrentMenu}
        setEditMode={props.setEditMode}
      />
      <DeleteMedicalMenuButton
        bulkDeleteArr={props.bulkDeleteArr}
        deleteBtnCss={props.deleteBtnCss}
        disableBtnCss={props.disableBtnCss}
        index={props.index}
        isCheck={props.isCheck}
        record={props.record}
        setDeleteMenu={props.setDeleteMenu}
        setOpenDeleteDialog={props.setOpenDeleteDialog}
      />
    </MedicalMenuManageButtons>
  </>
);

const MainContent: React.FC<{
  record: MenuType;
  index: number;
  lastIndexNum: number;
  currentCategory: any;
  bulkDeleteArr: number[];
  isCheck: boolean;
  setIsCheck: Dispatch<SetStateAction<boolean>>;
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>;
  updateMenuIndexExecute: (isUp?: boolean) => void;
  disableBtnCss: string;
  deleteBtnCss: string;
  setCurrentMenu: Dispatch<SetStateAction<MenuType>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setDeleteMenu: Dispatch<SetStateAction<MenuType>>;
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>;
}> = (props) => {
  return (
    <>
      <MenuButtons {...props} />
      <MedicalMenuInfo record={props.record} currentCategory={props.currentCategory} />
    </>
  );
};

const DeleteMenuDialogWrapper: React.FC<{
  openDeleteDialog: boolean
  filteredLists: MenuType[]
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>
  record: MenuType
  setDeleteMenu: Dispatch<SetStateAction<MenuType>>
  DeleteMenuDialog: any
}> = (props) => props.openDeleteDialog ? (
  <props.DeleteMenuDialog
    filteredLists={props.filteredLists}
    setIsOpen={props.setOpenDeleteDialog}
    setBulkDeleteArr={props.setBulkDeleteArr}
    openDeleteDialog={props.openDeleteDialog}
    setOpenDeleteDialog={props.setOpenDeleteDialog}
    record={props.record}
    setDeleteMenu={props.setDeleteMenu}
    DeleteMenuDialog={props.DeleteMenuDialog}
  />
) : null;
