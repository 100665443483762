import React from 'react';
import type { Recall } from '@/_graphql/graphql-client';
import { RecallCardViews } from '../views/RecallCardViews';
import { RecallStatusBadge } from '../components/RecallStatusBadge';

interface RecallListPresenterProps {
  recalls: Recall[];
  renderPatientName: (recall: Recall) => React.ReactNode;
}

export const RecallListPresenter: React.FC<RecallListPresenterProps> = ({ 
  recalls,
  renderPatientName,
}) => {
  return (
    <ul className="flow-root w-full h-full divide-y divide-gray-200 dark:divide-gray-700 list-none">
      {recalls.map((recall) => (
        <RecallCardViews
          key={recall.id}
          recall={recall}
        />
      ))}
    </ul>
  );
}; 