import React from 'react';
import { useNavigate } from '@tanstack/react-location';
import { useGraphqlClient } from '@/App';
import { useGetAppointByIdV2Query } from '@/_graphql/graphql-client';
import type { Recall } from '@/_graphql/graphql-client';

interface RecallAppointButtonProps {
  recall: Recall;
}

export const RecallAppointButton: React.FC<RecallAppointButtonProps> = ({ recall }) => {
  const navigate = useNavigate();
  const graphqlClient = useGraphqlClient();
  const { nextDueDate, patientId } = recall;

  const { data: appointData } = useGetAppointByIdV2Query(
    graphqlClient,
    {
      clinic_id: recall.clinic_id,
      id: recall.patientId,
      startTime: recall.lastTreatmentDate,
    },
    {
      enabled: typeof recall.clinic_id === 'string' && 
               recall.clinic_id.length > 0 && 
               typeof recall.patientId === 'string' && 
               recall.patientId.length > 0 && 
               typeof recall.lastTreatmentDate === 'number',
    }
  );

  const handleAddAppointment = (): void => {
    navigate({
      to: '/main/appoints-list/appoint-info',
      search: {
        'calendar-date': nextDueDate,
        'start-time': recall.lastTreatmentDate,
        'end-time': recall.lastTreatmentDate + (appointData?.getAppointByIdV2?.endTime - appointData?.getAppointByIdV2?.startTime),
        'facility-id': appointData?.getAppointByIdV2?.facilityId ?? '',
        'patient-id': patientId,
        'appoint-id': recall.appointId,
        'copy': 'true',
        'operation': 'copy'
      },
    });
  };

  return (
    <button
      onClick={handleAddAppointment}
      className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 hover:bg-indigo-100"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
      前回の内容で予約
    </button>
  );
}; 