import type { PatientsType } from '@/components/Patient/components/util/common/type';

import React, { useContext, useState } from 'react';
import {
  unixTimeFromNowDate,
  oneDayTimestamp,
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon';
import { endOfDay, format, getUnixTime, startOfDay } from 'date-fns';
import {
  usePatientList_service,
} from '@/domain';
import { ClinicContext, useGraphqlClient } from '@/App';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { AppointmentListPresenter } from '../presenter/AppointmentListPresenter';
import { getPatientById } from '@/components/Appointment/utils';
import { Appoints } from '../utils/common/type';
import { useAppointForAppointListQuery_service } from '@/domain/Appointment/services/hooks/Query/useAppointList_service';
import { type AppointDetails } from '@/_graphql/graphql-client';


export const AppointmentListViews: React.FC = () => {
  const graphqlClient = useGraphqlClient();
  const clinic = useContext(ClinicContext);

  const { nowDate, setNowDate } = useContext(SetDateContext);
  const [appointFilter, setAppointFilter] = useState('全て');
  const [dateSort, setDateSort] = useState('ASC');
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isDaily, setIsDaily] = useState(false);

  const startDateTime: number = unixTimeFromNowDate(new Date(startDate));
  const endDateTime: number = getUnixTime(endOfDay(new Date(endDate)));

  const appoints = useAppointForAppointListQuery_service(graphqlClient, {
    clinic_id: clinic.clinic_id,
    date: {
      startTime: (startDate && endDate) ? startDateTime : getUnixTime(startOfDay(nowDate)),
      endTime: (startDate && endDate) ? endDateTime : getUnixTime(endOfDay(nowDate)),
    }
  });

  const allReservation = appoints.appointList || [] as AppointDetails[]

  const { patientsList } = usePatientList_service(graphqlClient, { clinic_id: clinic.clinic_id });

  // const dailyReservation: AppointDetails[] = [];
  // allReservation?.forEach(reservation => {
  //   if (
  //     reservation.startTime >= getUnixTime(startOfDay(nowDate)) &&
  //     getUnixTime(endOfDay(nowDate)) >= reservation.endTime
  //   ) {
  //     dailyReservation.push(reservation);
  //   }
  // });

  const reservationLists = allReservation as AppointDetails[];

  const appointFilteredReservationLists = reservationLists?.filter((list) => {
    if (appointFilter === '全て') {
      return list;
    } 
      return appointFilter === list.appoint;
    
  });

  // 確定日付か登録日付かは議論
  const dateSortedReservationLists: AppointDetails[] = appointFilteredReservationLists?.slice();
  if (dateSort === 'ASC') {
    dateSortedReservationLists?.sort((a, b) => a.startTime - b.startTime);
  } else {
    dateSortedReservationLists?.sort((a, b) => b.startTime - a.startTime);
  }

  const wordSearchedReservationLists = dateSortedReservationLists?.filter((list) => {
    const tmpKeyword = keyword.toLowerCase();
    // 小文字で比較して部分一致するものだけを残す
    const currentPatient: PatientsType = getPatientById(patientsList, list.id);
    const patientName: string = currentPatient.name.last + currentPatient.name.first;
    return new RegExp(tmpKeyword).test(patientName.toLowerCase());
  });

  const finalFilteredReservationLists = isDaily
    ? wordSearchedReservationLists?.slice()
    : wordSearchedReservationLists?.filter((list) => {
      if (startDate === '' || endDate === '') {
        // unixTimeFromNowDateは引数日付の0時丁度のタイムを返す
        const formatedNowDate = format(nowDate, 'yyyy-MM-dd');
        let startDateTime: number = unixTimeFromNowDate(new Date(formatedNowDate));
        let endDateTime: number = unixTimeFromNowDate(new Date(formatedNowDate));

        // return startDateTime <= list.startTime && list.endTime <= endDateTime;
        if (startDateTime < endDateTime) {
          endDateTime += oneDayTimestamp - 1;
          return startDateTime < list.startTime && list.endTime < endDateTime;
        } if (startDateTime > endDateTime) {
          startDateTime += oneDayTimestamp - 1;
          return endDateTime < list.startTime && list.startTime < startDateTime;
        } 
          endDateTime += oneDayTimestamp - 1;
          return startDateTime < list.startTime && list.endTime < endDateTime;
        
      } 
        // unixTimeFromNowDateは引数日付の0時丁度のタイムを返す
        const startDateTime: number = unixTimeFromNowDate(new Date(startDate));
        let endDateTime: number = unixTimeFromNowDate(new Date(endDate));

        if (startDateTime < endDateTime) {
          endDateTime += oneDayTimestamp - 1;
          return startDateTime < list.startTime && list.endTime < endDateTime;
        } if (startDateTime > endDateTime) {
          return endDateTime < list.startTime && list.startTime < startDateTime;
        } 
          endDateTime += oneDayTimestamp - 1;
          return startDateTime < list.startTime && list.endTime < endDateTime;
        
      
    });

  return (
    <AppointmentListPresenter
      appointFilter={appointFilter}
      setAppointFilter={setAppointFilter}
      setDateSort={setDateSort}
      dateSort={dateSort}
      keyword={keyword}
      setKeyword={setKeyword}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      setIsDaily={setIsDaily}
      finalFilteredReservationLists={finalFilteredReservationLists}
      nowDate={nowDate}
      setNowDate={setNowDate}
    />
  );
};
