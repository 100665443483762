import React from 'react'
import type { listManegerType, MenuType, CategoryType } from '@/feature/phr-28/components/common/type'
import { AddPatientModal } from '@/feature/phr-29/components/Patients/AddPatientModal'
import type { SearchType } from '@/components/Common/entities'
import { AppointFormViews } from './AppointFormViews'
import type { UseQueryResult } from 'react-query'
import type { AppointV2, GetAppointByIdV2Query } from '@/_graphql/graphql-client'
import type { FacilityType } from '@/components/Facility/entities'
import type { PatientsType } from '@/components/Patient/components/util/common/type'

interface AddAppointPresenterType {
    newPatient: PatientsType
    setNewPatient: React.Dispatch<React.SetStateAction<PatientsType>>
    nowDate: Date
    isBeforeDay: boolean
    appoint: AppointV2
    req: UseQueryResult<GetAppointByIdV2Query, unknown>
    operation: 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined

    openAddPatientModal: boolean
    setOpenAddPatientModal: React.Dispatch<React.SetStateAction<boolean>>
    openSearchPatientModal: boolean
    setOpenSearchPatientModal: React.Dispatch<React.SetStateAction<boolean>>
    search: SearchType
    patientId: string
    startTime: number
    endTime: number
    facilityId: string
    appointId: string
    facilityList: FacilityType[]
    menuList: MenuType[]
    menuListIncludeDelete: MenuType[]
    categoryList: CategoryType[]
    manegerLists: listManegerType[]
    manegerListsIncludeDelete: listManegerType[]

    minTreatmentTime: number;
    stepsMinutes: number;
}

const AppointFormContent: React.FC<AddAppointPresenterType> = (props) => (
  <AppointFormViews
    {...props}
    newPatient={props.newPatient}
    nowDate={props.nowDate}
    isBeforeDay={props.isBeforeDay}
    search={props.search}
    patientId={props.patientId}
    startTime={props.startTime}
    endTime={props.endTime}
    facilityId={props.facilityId}
    appointId={props.appointId}
    appoint={props.appoint}
    req={props.req}
    operation={props.operation}
  />
);

const PatientModal: React.FC<AddAppointPresenterType> = (props) => (
  props.openAddPatientModal ? (
    <AddPatientModal
      setIsOpen={props.setOpenAddPatientModal}
      modalOptions={{ 
        setOpenSelectPatientModal: props.setOpenSearchPatientModal, 
        setNewPatient: props.setNewPatient 
      }}
    />
  ) : null
);

const AppointContent: React.FC<AddAppointPresenterType> = (props) => (
  <>
    <AppointFormContent {...props} />
    <PatientModal {...props} />
  </>
);

export const AddAppointPresenter: React.FC<AddAppointPresenterType> = (props) => (
  <AppointContent {...props} />
);