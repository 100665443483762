import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { MedicalCareMenuAddRecord } from '@/feature/phr-28/components/template/medicalCareRegistration/MedicalCareMenuAddRecord';
import { SearchBox } from '@/feature/phr-28/components/parts/SearchBox';
import { BasicTabBar } from '@/feature/phr-28/components/views/BasicTabBar';
import { MedicalCategoryViews } from '@/components/MedicalCategory/components/views/MedicalCategoryViews';
import { type MedicalMenuPresenterType } from '../util/common/type';
import { MedicalMenuTableViews } from '../views/MedicalMenuTableViews';

const ActionButtons: React.FC<{
  bulkDeleteArr: number[];
  deleteBtnCss: string;
  disableBtnCss: string;
  show: boolean;
  setShow: (show: boolean) => void;
  setIsOpenDeleteDialog: (isOpen: boolean) => void;
}> = ({ bulkDeleteArr, deleteBtnCss, disableBtnCss, show, setShow, setIsOpenDeleteDialog }) => (
  <div className="flex gap-4">
    <div className="pt-2 flex justify-end">
      <button type="button" className={bulkDeleteArr.length > 0 ? deleteBtnCss : disableBtnCss}>
        {show ? (
          <p onClick={() => { setShow(false); }}>キャンセル</p>
        ) : (
          <p className={disableBtnCss} onClick={() => { setShow(true); }}>
            治療内容の追加
          </p>
        )}
      </button>
    </div>
    <div className="flex justify-end items-center">
      <button
        type="button"
        className={bulkDeleteArr.length > 0 ? deleteBtnCss : disableBtnCss}
        onClick={() => { setIsOpenDeleteDialog(true); }}
      >
        まとめて削除
      </button>
    </div>
  </div>
);

const MedicalMenuForm: React.FC<{
  setFilterKeyword: (keyword: string) => void;
  bulkDeleteArr: number[];
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>;
  deleteBtnCss: string;
  disableBtnCss: string;
  show: boolean;
  setShow: (show: boolean) => void;
  lastIndexNum: number;
  categoryList: any[];
  isOpenDeleteDialog: boolean;
  setIsOpenDeleteDialog: (isOpen: boolean) => void;
  DeleteMenusDialog: any;
  filteredLists: any[];
}> = (props) => (
  <form action="/medical_menu" method="get">
    <div className="flex justify-between p-2">
      <SearchBox setFilterKeyword={props.setFilterKeyword} />
      <ActionButtons
        bulkDeleteArr={props.bulkDeleteArr}
        deleteBtnCss={props.deleteBtnCss}
        disableBtnCss={props.disableBtnCss}
        show={props.show}
        setShow={props.setShow}
        setIsOpenDeleteDialog={props.setIsOpenDeleteDialog}
      />
    </div>
    {props.show && (
      <MedicalCareMenuAddRecord
        setShow={props.setShow}
        indexNum={props.lastIndexNum + 1}
        categoryList={props.categoryList}
      />
    )}
    {props.isOpenDeleteDialog && (
      <props.DeleteMenusDialog
        filteredLists={props.filteredLists}
        bulkDeleteArr={props.bulkDeleteArr}
        setBulkDeleteArr={props.setBulkDeleteArr}
        setIsOpen={props.setIsOpenDeleteDialog}
      />
    )}
  </form>
);

const MedicalMenuRegistration: React.FC<{
  setFilterKeyword: (keyword: string) => void;
  bulkDeleteArr: number[];
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>;
  deleteBtnCss: string;
  disableBtnCss: string;
  show: boolean;
  setShow: (show: boolean) => void;
  lastIndexNum: number;
  categoryList: any[];
  isOpenDeleteDialog: boolean;
  setIsOpenDeleteDialog: (isOpen: boolean) => void;
  DeleteMenusDialog: any;
  filteredLists: any[];
  setSortingFactorNumber: (num: number) => void;
  isSort: boolean;
  setIsSort: (isSort: boolean) => void;
  setOrder: (order: string) => void;
}> = (props) => (
  <>
    <MedicalMenuForm {...props} />
    <MedicalMenuTableViews
      filteredLists={props.filteredLists}
      categoryList={props.categoryList}
      setSortingFactorNumber={props.setSortingFactorNumber}
      isSort={props.isSort}
      setIsSort={props.setIsSort}
      setOrder={props.setOrder}
      setBulkDeleteArr={props.setBulkDeleteArr}
      bulkDeleteArr={props.bulkDeleteArr}
      lastIndexNum={props.lastIndexNum}
    />
  </>
);

const TabContent: React.FC<{
  tabName: string;
  props: Omit<MedicalMenuPresenterType, 'tabTitles' | 'isCenter' | 'tabName' | 'setTabName' | 'nowDate' | 'setNowDate' | 'matches'>;
}> = ({ tabName, props }) => {
  if (tabName === '診療メニュー登録') {
    return (
      <MedicalMenuRegistration
        setFilterKeyword={props.setFilterKeyword}
        bulkDeleteArr={props.bulkDeleteArr}
        setBulkDeleteArr={props.setBulkDeleteArr}
        deleteBtnCss={props.deleteBtnCss}
        disableBtnCss={props.disableBtnCss}
        show={props.show}
        setShow={props.setShow}
        lastIndexNum={props.lastIndexNum}
        categoryList={props.categoryList}
        isOpenDeleteDialog={props.isOpenDeleteDialog}
        setIsOpenDeleteDialog={props.setIsOpenDeleteDialog}
        DeleteMenusDialog={props.DeleteMenusDialog}
        filteredLists={props.filteredLists}
        setSortingFactorNumber={props.setSortingFactorNumber}
        isSort={props.isSort}
        setIsSort={props.setIsSort}
        setOrder={props.setOrder}
      />
    );
  }
  if (tabName === '診療カテゴリ登録') {
    return <MedicalCategoryViews categoryList={props.categoryList} />;
  }
  return null;
};

export const MedicalMenuPresenter: React.FC<MedicalMenuPresenterType> = (props): JSX.Element => {
  const { tabTitles, isCenter, tabName, setTabName } = props;
  return (
    <>
      <BasicTabBar
        tabTitles={tabTitles}
        arrangement={isCenter}
        setTabName={setTabName}
      />
      <TabContent tabName={tabName} props={props} />
    </>
  );
};
