import type { FacilityType } from "@/components/Facility/entities";
import { calculateCurrentSlotTimestamp, hourTimeStamp } from "@/components/Reservation/utils";
import { unixTimeFromNowDate } from "@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon";
import { QuarterSlot } from "../controller/QuarterSlot";
import { getEndTime } from "@/components/Common/utils";
import { addMinutes, differenceInMinutes, fromUnixTime, getUnixTime } from "date-fns";
import type { AddAppointSetValuesType, ReserveType } from "@/components/Reservation/entities";
import type { NavigateType } from "@/components/Common/entities";
import type { setAppointParamsType } from "@/domain/Appointment/useAppointParams";
import type { setBlockParamsType } from "@/domain/Block/useBlockParams";
import type { setCommonParamsType } from "@/domain/Common/useCommonParams";
import { emptyAppoint } from "@/feature/phr-28/components/common/emptyData";
import { useTimeSlotHeight } from '../../../../../contexts/TimeSlotHeightContext';

// 基本的なスロットの設定
interface SlotConfig {
    intervalMinute: number;
    shiftStartHours: number;
    shiftStartMinutes: number;
    shiftEndHours: number;
    shiftEndMinutes: number;
    currentHour: number;
    isShiftStart: boolean;
    isShiftEnd: boolean;
    shiftStartQuarterIndex: number;
    shiftEndQuarterIndex: number;
}

// 予約モードの設定
interface AppointmentConfig {
    operation: "add" | "reference" | "edit" | "copy" | "update" | undefined;
    nowDate: Date;
    facility: FacilityType;
    blockId: string;
    startTime: number;
    endTime: number;
    appointId: string;
}

// UIの設定
interface UIConfig {
    isHoliday: boolean;
    isBeforeDay: boolean;
    openPane: (ref: any, position: "top" | "bottom" | "left", height: number, width: number) => void;
    setIsPaneOpen: React.Dispatch<React.SetStateAction<boolean>> | null;
    subPanesRef: any;
    viewSizes: { width: number; height: number };
}

// フォームとナビゲーションの設定
interface FormConfig {
    watch: any;
    setValue: any;
    navigate: NavigateType;
    setCommonParams: setCommonParamsType;
    setBlockParams: setBlockParamsType;
    setAppointParams: setAppointParamsType;
    resetAppointId: () => void;
    resetBlockId: () => void;
}

export interface HourSlotComponentProps extends SlotConfig, AppointmentConfig, UIConfig, FormConfig {}

const style = 'w-full flex flex-col justify-between';

// スロット生成のロジック
const useSlotGeneration = (intervalMinute: number): { slots: any[], height: { height: string } } => {
    const { timeSlotHeight } = useTimeSlotHeight();
    const slots = Array.from({ length: 60 / intervalMinute });
    const height = { height: `${timeSlotHeight * 60 / intervalMinute}px` };
    return { slots, height };
};

// イベントハンドラー
const useSlotClickHandler = (props: HourSlotComponentProps): ((timestamp: number) => void) => {
    return function handleClick(currentSlotTimestamp: number): void {
        if (props.operation === 'copy' || props.operation === 'edit') {
            handleEditOrCopyOperation(props, currentSlotTimestamp);
            return;
        }
        handleAddOperation(props, currentSlotTimestamp);
    };
};

// 編集/コピー操作の処理
const handleEditOrCopyOperation = (props: HourSlotComponentProps, currentSlotTimestamp: number): void => {
    const interval = props.endTime - props.startTime;
    const intervalMinute = interval / 60;

    if (props.blockId !== '') {
        handleBlockUpdate(props, currentSlotTimestamp, intervalMinute);
        return;
    }

    handleAppointmentUpdate(props, currentSlotTimestamp, intervalMinute);
};

// 新規追加操作の処理
const handleAddOperation = (props: HourSlotComponentProps, currentSlotTimestamp: number): void => {
    if (props.setIsPaneOpen !== null) {
        props.setIsPaneOpen(true);
        props.openPane(props.subPanesRef, 'top', props.viewSizes.height, props.viewSizes.width);
    }
    
    props.navigate({ to: `/main/add-appoint` });
    props.resetAppointId();
    props.resetBlockId();
    
    props.setCommonParams(
        getUnixTime(props.nowDate),
        currentSlotTimestamp,
        getEndTime(currentSlotTimestamp, props.intervalMinute),
        props.facility.id
    );
    
    props.setValue('appointData', { ...emptyAppoint });
    props.setValue('appointData.id', '0');
    props.setValue('appointData.startTime', currentSlotTimestamp);
    props.setValue('appointData.endTime', getEndTime(currentSlotTimestamp, props.intervalMinute));
    props.setValue('appointData.facilityId', props.facility.id);
};

interface ShiftTimeConfig {
    isShiftStart: boolean;
    isShiftEnd: boolean;
    currentQuarterIndex: number;
    shiftStartQuarterIndex: number;
    shiftEndQuarterIndex: number;
}

// スロットの無効化チェック
function checkIsDisabled(config: ShiftTimeConfig): boolean {
    const isBeforeShiftStart = config.isShiftStart && (config.currentQuarterIndex < config.shiftStartQuarterIndex);
    const isAfterShiftEnd = config.isShiftEnd && ((config.currentQuarterIndex + 1) > config.shiftEndQuarterIndex);
    return isBeforeShiftStart || isAfterShiftEnd;
}

// 個別のスロットコンポーネント
const SlotItem: React.FC<{
    currentQuarterIndex: number;
    props: HourSlotComponentProps;
    handleSlotClick: (timestamp: number) => void;
}> = ({ currentQuarterIndex, props, handleSlotClick }) => {
    const currentSlotTimestamp = calculateCurrentSlotTimestamp(
        props.currentHour,
        currentQuarterIndex,
        props.shiftStartHours,
        unixTimeFromNowDate(props.nowDate),
        hourTimeStamp,
        60 / props.intervalMinute,
        props.intervalMinute
    );

    const isDisabled = checkIsDisabled({
        isShiftStart: props.isShiftStart,
        currentQuarterIndex,
        shiftStartQuarterIndex: props.shiftStartQuarterIndex,
        isShiftEnd: props.isShiftEnd,
        shiftEndQuarterIndex: props.shiftEndQuarterIndex
    });

    return (
        <QuarterSlot
            key={currentQuarterIndex}
            isHourTime={currentQuarterIndex === 0}
            isHalfTime={currentQuarterIndex === Math.floor(60 / (props.intervalMinute * 2))}
            isHoliday={props.isHoliday}
            isDisabled={isDisabled}
            isBeforeDay={props.isBeforeDay}
            onClickHandler={() => {
                handleSlotClick(currentSlotTimestamp);
            }}
        />
    );
};

// メインコンポーネント
export const OneHourSlots: React.FC<HourSlotComponentProps> = (props) => {
    const { slots, height } = useSlotGeneration(props.intervalMinute);
    const handleSlotClick = useSlotClickHandler(props);

    return (
        <div className={style} style={height}>
            {slots.map((_, currentQuarterIndex) => (
                <SlotItem
                    key={currentQuarterIndex}
                    currentQuarterIndex={currentQuarterIndex}
                    props={props}
                    handleSlotClick={handleSlotClick}
                />
            ))}
        </div>
    );
};

// ブロック更新の処理
function handleBlockUpdate(
    props: HourSlotComponentProps,
    currentSlotTimestamp: number,
    intervalMinute: number
): void {
    props.navigate({ to: 'update', fromCurrent: true });
    props.setBlockParams(
        getUnixTime(props.nowDate),
        props.blockId,
        currentSlotTimestamp,
        getEndTime(currentSlotTimestamp, intervalMinute),
        props.facility.id
    );
}

// 予約更新の処理
function handleAppointmentUpdate(
    props: HourSlotComponentProps,
    currentSlotTimestamp: number,
    intervalMinute: number
): void {
    const navigateOption = props.operation === 'copy'
        ? { to: 'add-appoint', fromCurrent: false }
        : { to: 'update', fromCurrent: true };

    const appoint = props.watch('appointData');
    
    if (props.setIsPaneOpen !== null) {
        props.setIsPaneOpen(true);
        props.openPane(props.subPanesRef, 'top', props.viewSizes.height, props.viewSizes.width);
    }

    props.navigate(navigateOption);
    props.setAppointParams(
        getUnixTime(props.nowDate),
        appoint.id,
        currentSlotTimestamp,
        getEndTime(currentSlotTimestamp, intervalMinute),
        props.facility.id,
        props.appointId
    );

    props.setValue('appointData.startTime', currentSlotTimestamp);
    props.setValue('appointData.endTime', getEndTime(currentSlotTimestamp, intervalMinute));
    props.setValue('appointData.facilityId', props.facility.id);
}

// ブロック編集
// 予約情報の初期値を計算するヘルパー関数
export function calculateInitialValue(
    baseAppointValue: ReserveType,
    startTime: number,
    facilityId: string
): ReserveType {
    const treatmentTime = differenceInMinutes(
        fromUnixTime(baseAppointValue.endTime),
        fromUnixTime(baseAppointValue.startTime)
    );
    const endDate = addMinutes(fromUnixTime(startTime), treatmentTime);
    const endTime = getUnixTime(endDate);

    return {
        ...baseAppointValue,
        facilityId,
        startTime,
        endTime
    };
}

function setInitialAppointValue(
    baseAppointValue: ReserveType,
    startTime: number,
    facilityId: string,
    setValue: AddAppointSetValuesType
): void {
    // 予約情報の初期値を計算
    const initialValue = calculateInitialValue(baseAppointValue, startTime, facilityId);
    // 計算された初期値をセット
    setValue('appointData', initialValue);
    // 参照元予約のプライマリキーをセット
    setValue('ReferenceAppointKey.baseId', baseAppointValue.id);
    setValue('ReferenceAppointKey.baseStartTime', baseAppointValue.startTime);
}

// URLパラメータを設定するヘルパー関数
export function setURLParams(
    baseAppointValue: any,
    startTime: number,
    facilityId: string
): string {
    const initialValue = calculateInitialValue(baseAppointValue, startTime, facilityId);
    const params = new URLSearchParams(location.search);
    params.set('startTime', String(initialValue.startTime));
    params.set('endTime', String(initialValue.endTime));
    params.set('facilityId', facilityId);
    return `${location.pathname}/update?${params.toString()}`;
}

interface EditCopyConfig {
    appoint: ReserveType;
    setValue: AddAppointSetValuesType;
    facilityId: string;
    startTime: number;
    navigate: NavigateType;
}

// 予約編集関数
export function handleEditOrCopy(config: EditCopyConfig): void {
    // 参照元予約の取得
    const baseAppointValue = { ...config.appoint };

    // 予約情報の初期値を計算:セット
    setInitialAppointValue(baseAppointValue, config.startTime, config.facilityId, config.setValue);
    // URLパラメータを設定
    const updatedURL = setURLParams(baseAppointValue, config.startTime, config.facilityId);

    // urlを更新
    config.navigate({ to: updatedURL, fromCurrent: true });
}
