import { useMemo } from 'react';
import type { Recall, RecallStatus } from '@/_graphql/graphql-client';

export const useRecallFiltering = (
  recalls: Recall[],
  selectedStatuses: Set<RecallStatus>,
  nameFilter: string,
  snumberFilter: string
) => {
  return useMemo(() => {
    console.log('フィルタリング前データ:', recalls);
    const filtered = recalls
      .filter(recall => {
        console.log(`ステータスチェック: ${recall.status}`, selectedStatuses.has(recall.status));
        return selectedStatuses.has(recall.status);
      })
      .filter(recall => {
        const fullName = `${recall.patient?.name?.last ?? ''}${recall.patient?.name?.first ?? ''}`;
        return nameFilter ? fullName.toLowerCase().includes(nameFilter.toLowerCase()) : true;
      })
      .filter(recall => {
        const sNumber = recall.patient?.SNumber?.toString() ?? '';
        return snumberFilter ? sNumber.includes(snumberFilter) : true;
      });
    console.log('フィルタリング後データ:', filtered);
    return filtered;
  }, [recalls, selectedStatuses, nameFilter, snumberFilter]);
}; 