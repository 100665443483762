import React from 'react';
import { RecallStatusButton } from './RecallStatusButton';
import type { Recall } from '@/_graphql/graphql-client';
import { RecallStatus } from '@/_graphql/graphql-client';
import { RecallAppointButton } from './RecallAppointButton';

interface RecallActionsProps {
  recall: Recall;
}

export const RecallActions: React.FC<RecallActionsProps> = ({ recall }) => {
  return (
    <div className="flex gap-2">
      <RecallAppointButton recall={recall} />
      {/* {recall.status === 'PENDING' && (
        <RecallStatusButton 
          recall={recall}
          newStatus={RecallStatus.Scheduled}
          label="予約済みに更新"
        />
      )} */}
      {recall.status !== RecallStatus.Completed && (
        <RecallStatusButton 
          recall={recall}
          newStatus={RecallStatus.Completed}
          label="定期診療の終了"
        />
      )}
      <RecallStatusButton 
        recall={recall}
        newStatus={RecallStatus.Cancelled}
        label="リコールを無視"
      />
    </div>
  );
}; 