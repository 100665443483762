import React, { useContext } from 'react'
import { getUnixTime, startOfDay } from 'date-fns'
import { ClinicContext, useGraphqlClient } from '@/App'
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client'
import type { CategoryType } from '../../common/type'
import { getTreatmentMinutes } from '@/components/Common/utils/common/addData'
import { FormInputs } from './components/FormInputs'
import { useMedicalCareMenuForm } from './hooks/useMedicalCareMenuForm'

interface MedicalCareMenuAddRecordType {
  setShow: (show: boolean) => void
  indexNum: number
  categoryList: CategoryType[]
}

const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
  return config?.__typename === "CardWidthConfig"
}

export const MedicalCareMenuAddRecord: React.FC<MedicalCareMenuAddRecordType> = ({
  setShow,
  indexNum,
  categoryList
}): JSX.Element => {
  const graphqlClient = useGraphqlClient()
  const clinicId = useContext(ClinicContext).clinic_id

  const { data: cardWidthList } = useGetLatestClinicConfigQuery(graphqlClient, {
    clinic_id: clinicId,
    current_date: getUnixTime(startOfDay(new Date())),
    config_type: 'card_width'
  })

  const configData = cardWidthList?.getLatestClinicConfig?.config ?? null
  const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 15
  const maxTreatmentMinutes = 180
  const minTreatmentTime = cardWidth
  const treatmentTimes = getTreatmentMinutes(maxTreatmentMinutes, minTreatmentTime, cardWidth)

  const formProps = useMedicalCareMenuForm({
    indexNum,
    categoryList,
    treatmentTimes,
    setShow
  })

  return (
    <form action="#">
      <table>
        <tbody>
          <FormInputs
            {...formProps}
            treatmentTimes={treatmentTimes}
          />
        </tbody>
      </table>
    </form>
  )
}
