import React from 'react';
import type { Recall } from '@/_graphql/graphql-client';
import { RecallPatientInfo } from '../components/RecallPatientInfo';
import { RecallActions } from '../components/RecallActions';
import { RecallStatusBadge } from '../components/RecallStatusBadge';

interface RecallCardViewsProps {
  recall: Recall;
}

export const RecallCardViews: React.FC<RecallCardViewsProps> = ({ recall }) => {
  return (
    <li className="py-4 hover:bg-gray-50 list-none">
      <div className="flex items-center justify-between px-4">
        <div className="flex items-center min-w-0 gap-x-4">
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <span>{`${recall.patient?.name?.last}${recall.patient?.name?.first}`}</span>
              <RecallStatusBadge status={recall.status} />
            </div>
            <RecallPatientInfo recall={recall} />
          </div>
        </div>
        <RecallActions recall={recall} />
      </div>
    </li>
  );
}; 