import { Dialog, DialogContent } from '@mui/material'
import type { CategoryType, MenuType } from '@/feature/phr-28/components/common/type'
import React, { useState } from 'react'
import { emptyMenu } from '../../../common/emptyData'
import { MedicalMenuModal } from './MedicalMenuModal'

interface SelectCategoryModalType {
    currentShiftTimestamp: number
    categoryList: CategoryType[]
    MenuList: MenuType[]
    setOpenMedicalCategoryModal: React.Dispatch<React.SetStateAction<boolean>>,
}

function createHandleClose(
    setOpenMedicalCategoryModal: React.Dispatch<React.SetStateAction<boolean>>,
    setCategory: React.Dispatch<React.SetStateAction<'medical' | 'dental'>>
) {
    return function handleClose() {
        setCategory('medical')
        setOpenMedicalCategoryModal(false)
    }
}

function clearState(
    setOpenMedicalCategoryModal: React.Dispatch<React.SetStateAction<boolean>>,
    setCategory: React.Dispatch<React.SetStateAction<'medical' | 'dental'>>
): void {
    setCategory('medical')
    setOpenMedicalCategoryModal(false)
}

function openMenu(
    categoryId: string,
    categoryName: string,
    menuList: MenuType[],
    setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>,
    setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>
): void {
    const currentCategoryMenuList = getCurrentCategoryMenuList(categoryId, menuList)
    setCurrentMenuList(currentCategoryMenuList)
    setCurrentCategoryName(categoryName)
    setOpenMedicalMenuModal(true)
}

function getCurrentCategoryMenuList(categoryId: string, menuList: MenuType[]): MenuType[] {
    const currentCategoryMenuList = menuList.filter(menu => menu.subCategoryId === categoryId)
    return currentCategoryMenuList
}

function getFilteredCategoryList(
    category: 'dental' | 'medical',
    tmpCategoryList: CategoryType[]
): CategoryType[] {
    let categoryList: CategoryType[] = [];
    switch (category) {
        case 'dental':
            categoryList = tmpCategoryList.filter(category => category.mainCategory === '歯科')
            return categoryList;
        case 'medical':
            categoryList = tmpCategoryList.filter(category => category.mainCategory === '医科')
            return categoryList;
    }
}

const Header: React.FC<{
    category: 'medical' | 'dental'
    setCategory: React.Dispatch<React.SetStateAction<'medical' | 'dental'>>
    setOpenMedicalCategoryModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ category, setCategory, setOpenMedicalCategoryModal }) => (
    <header className="sticky top-0 pt-8 bg-white bg-opacity-90">
        <div className="flex justify-between mb-8 gap-1">
            <div>
                <h2 onClick={() => { setCategory('dental') }} className={`${category === 'dental' ? 'bg-red-700' : 'bg-gray-400'} w-64 text-white inline-flex justify-center items-center text-xl h-12 rounded-md rounded-r-none`}>歯科</h2>
                <h2 onClick={() => { setCategory('medical') }} className={`${category === 'medical' ? 'bg-red-700' : 'bg-gray-400'} w-64 h-12 text-white text-xl h-12 rounded-md inline-flex justify-center items-center rounded-l-none`}>医科</h2>
            </div>
            <button
                type="button"
                className="text-white w-48 bg-red-700 font-medium rounded-md inline-flex justify-center items-center text-xl h-12 mr-6"
                onClick={() => { clearState(setOpenMedicalCategoryModal, setCategory) }}
            >
                閉じる
            </button>
        </div>
        <p className="text-xl spacing-ls tracking-wider border-b border-gray-600 pb-2 mb-8">
            <span className="text-red-500">診療カテゴリ</span>を選択してください。
        </p>
    </header>
);

const CategoryGrid: React.FC<{
    filteredCategoryList: CategoryType[]
    MenuList: MenuType[]
    setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>
    setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>
    setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>
}> = ({ filteredCategoryList, MenuList, setOpenMedicalMenuModal, setCurrentCategoryName, setCurrentMenuList }) => (
    <div className="grid grid-cols-3 gap-x-2 gap-y-4">
        {filteredCategoryList.map(category => (
            <div
                key={category.id}
                className="relative"
                onClick={() => {
                    openMenu(category.id, category.name, MenuList, setOpenMedicalMenuModal, setCurrentCategoryName, setCurrentMenuList)
                }}
            >
                <label className="bg-white w-full h-12 inline-flex justify-center tracking-wider text-xl relative flex items-center rounded border border-gray-300 bg-white shadow">
                    <div className="w-full h-full flex items-center">
                        <p className="w-full px-4 text-center truncate">{category.name}</p>
                    </div>
                </label>
            </div>
        ))}
    </div>
);

const ModalContent: React.FC<{
    category: 'medical' | 'dental'
    setCategory: React.Dispatch<React.SetStateAction<'medical' | 'dental'>>
    setOpenMedicalCategoryModal: React.Dispatch<React.SetStateAction<boolean>>
    filteredCategoryList: CategoryType[]
    MenuList: MenuType[]
    currentShiftTimestamp: number
    currentMenuList: MenuType[]
    setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>
    currentCategoryName: string
    setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>
    openMedicalMenuModal: boolean
    setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) => (
    <DialogContent
        dividers
        style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '90vh', position: 'relative' }}
    >
        <div className="wrapper">
            <div className="pb-8 px-8">
                <Header
                    category={props.category}
                    setCategory={props.setCategory}
                    setOpenMedicalCategoryModal={props.setOpenMedicalCategoryModal}
                />
                <div className="categorys">
                    <CategoryGrid
                        filteredCategoryList={props.filteredCategoryList}
                        MenuList={props.MenuList}
                        setOpenMedicalMenuModal={props.setOpenMedicalMenuModal}
                        setCurrentCategoryName={props.setCurrentCategoryName}
                        setCurrentMenuList={props.setCurrentMenuList}
                    />
                </div>
                {props.openMedicalMenuModal && (
                    <MedicalMenuModal
                        currentShiftTimestamp={props.currentShiftTimestamp}
                        currentMenuList={props.currentMenuList}
                        setCurrentMenuList={props.setCurrentMenuList}
                        currentCategoryName={props.currentCategoryName}
                        setCurrentCategoryName={props.setCurrentCategoryName}
                        setOpenMedicalCategory={props.setOpenMedicalCategoryModal}
                        setOpenMedicalMenuModal={props.setOpenMedicalMenuModal}
                    />
                )}
            </div>
        </div>
    </DialogContent>
);

export const MedicalCategoryModal: React.FC<SelectCategoryModalType> = ({
    currentShiftTimestamp,
    categoryList,
    MenuList,
    setOpenMedicalCategoryModal
}) => {
    const [category, setCategory] = useState<'medical' | 'dental'>('dental');
    const [currentMenuList, setCurrentMenuList] = useState<MenuType[]>([emptyMenu])
    const [currentCategoryName, setCurrentCategoryName] = useState('')
    const [openMedicalMenuModal, setOpenMedicalMenuModal] = useState(false)
    const filteredCategoryList = getFilteredCategoryList(category, categoryList);

    return (
        <Dialog
            transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
            onClose={createHandleClose(setOpenMedicalCategoryModal, setCategory)}
            open={true}
            PaperProps={{
                style: {
                    maxWidth: 'none',
                    width: '80%',
                    transition: 'all 0',
                    zIndex: 9999
                }
            }}
        >
            <ModalContent
                category={category}
                setCategory={setCategory}
                setOpenMedicalCategoryModal={setOpenMedicalCategoryModal}
                filteredCategoryList={filteredCategoryList}
                MenuList={MenuList}
                currentShiftTimestamp={currentShiftTimestamp}
                currentMenuList={currentMenuList}
                setCurrentMenuList={setCurrentMenuList}
                currentCategoryName={currentCategoryName}
                setCurrentCategoryName={setCurrentCategoryName}
                openMedicalMenuModal={openMedicalMenuModal}
                setOpenMedicalMenuModal={setOpenMedicalMenuModal}
            />
        </Dialog>
    );
};