import { type MedicalCategoryPresenterType } from '../util/common/type';
import {
  checkIsInDeletable,
  onClickOpenDeleteDialog,
  setCategoryIdInDeletables,
} from '../util/common/util';
import {
  type DeleteMedicalCategoryButtonType,
  type EditMedicalCategoryButtonType,
  type MedicalCategoryInfoType,
  type MedicalCategoryTableRecordType,
  type OpenAddCategoryButtonType,
  type OpenDeleteDialogButtonType,
  type SwitchMedicalCategoryButtonType,
} from '../util/components/parts';

export const MedicalCategoryPresenter: React.FC<MedicalCategoryPresenterType> = ({
  openDeleteDialog,
  DeleteCategoryDialog,
  categoryList,
  setOpenDeleteDialog,
  setMainCategory,
  mainCategory,
  deletable,
  setDeletable,
  setCurrentCategorys,
  selectedCategoryList,
  setOpenAddCategory,
  setCurrentCategory,
  AddCategoryModal,
  mutate,
}) => {
  return (
    <Outline>
      <CategoryHeader
        setMainCategory={setMainCategory}
        mainCategory={mainCategory}
        deletable={deletable}
        setCurrentCategorys={setCurrentCategorys}
        setOpenDeleteDialog={setOpenDeleteDialog}
        setOpenAddCategory={setOpenAddCategory}
      />
      <CategoryTable
        selectedCategoryList={selectedCategoryList}
        deletable={deletable}
        setDeletable={setDeletable}
        setCurrentCategory={setCurrentCategory}
        setOpenAddCategory={setOpenAddCategory}
        mutate={mutate}
      />
      {openDeleteDialog && (
        <DeleteCategoryDialog categoryList={categoryList} setIsOpen={setOpenDeleteDialog} />
      )}
      <AddCategoryModal />
    </Outline>
  );
};

// NOTE 230602 Commonからインポートする（まだmainブランチに入ってない）
export interface OutlineType {
  children?: React.ReactNode;
}

const Outline: React.FC<OutlineType> = ({ children }) => {
  return <div className="px-4">{children}</div>;
};

const MedicalMenuHeader: React.FC<OutlineType> = ({ children }) => {
  const headerStyle = "sticky top-0 left-0 w-full bg-white flex items-center justify-between py-8 text-sm"
  return (
    <header className={headerStyle}>
      {children}
    </header>
  );
};

const SwitchMedicalCategoryButtons: React.FC<OutlineType> = ({ children }) => {
  return <div className="flex gap-1">{children}</div>;
};

const SelectAllCategoryButton: React.FC<SwitchMedicalCategoryButtonType> = ({
  setMainCategory,
  mainCategory,
}) => {
  const buttonStyle = 'inline-flex items-center justify-center rounded-md border border-transparent rounded-r-none h-10 w-40 font-medium text-white shadow-sm'
  return (
    <button
      onClick={() => {
        setMainCategory('all');
      }}
      type="button"
      className={`${
        mainCategory === 'all' ? 'bg-indigo-600' : 'bg-gray-400'
      } ${buttonStyle}`}
    >
      全件
    </button>
  );
};

const SelectDentalCategoryButton: React.FC<SwitchMedicalCategoryButtonType> = ({
  setMainCategory,
  mainCategory,
}) => {
  const buttonStyle = 'inline-flex items-center justify-center border border-transparent h-10 w-40 font-medium text-white shadow-sm'
  return (
    <button
      onClick={() => {
        setMainCategory('dental');
      }}
      type="button"
      className={`${
        mainCategory === 'dental' ? 'bg-indigo-600' : 'bg-gray-400'
      } ${buttonStyle}`}
    >
      歯科
    </button>
  );
};

const SelectMedicalCategoryButton: React.FC<SwitchMedicalCategoryButtonType> = ({
  setMainCategory,
  mainCategory,
}) => {
  const buttonStyle = 'inline-flex items-center justify-center rounded-md border border-transparent rounded-l-none border-l-none h-10 w-40 font-medium text-white shadow-sm'
  return (
    <button
      onClick={() => {
        setMainCategory('medical');
      }}
      type="button"
      className={`${
        mainCategory === 'medical' ? 'bg-indigo-600' : 'bg-gray-400'
      } ${buttonStyle}`}
    >
      医科
    </button>
  );
};

const HandleMedicalCategoryModalButtons: React.FC<OutlineType> = ({ children }) => {
  return <div className="mt-4 flex gap-4">{children}</div>;
};

const OpenDeleteDialogButton: React.FC<OpenDeleteDialogButtonType> = ({
  deletable,
  setCurrentCategorys,
  setOpenDeleteDialog,
}) => {
  return (
    <button
      onClick={() => {
        onClickOpenDeleteDialog(deletable, setCurrentCategorys, setOpenDeleteDialog);
      }}
      type="button"
      className={`${
        deletable[1]?.length === 0 ? 'pointer-events-none bg-gray-400' : 'bg-indigo-600'
      } inline-flex items-center justify-center rounded-md border border-transparent h-10 w-32 font-medium text-white shadow-sm`}
    >
      一括削除
    </button>
  );
};

const OpenAddCategoryButton: React.FC<OpenAddCategoryButtonType> = ({ setOpenAddCategory }) => {
  const buttonStyle = "inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 h-10 w-32 font-medium text-white shadow-sm"
  return (
    <button
      onClick={() => {
        setOpenAddCategory(true);
      }}
      type="button"
      className={buttonStyle}
    >
      カテゴリ追加
    </button>
  );
};

const MedicalCategoryTableOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "flex flex-col -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 inline-block min-w-full py-2 align-middle overflow-y-scroll shadow-sm ring-1 ring-black ring-opacity-5 rounded"
  return (
    <div className={outlineStyle}>
      {children}
    </div>
  );
};

const MedicalCategoryTable: React.FC<OutlineType> = ({ children }) => {
  const tableStyle = "min-w-full divide-y divide-gray-300"
  return <table className={tableStyle}>{children}</table>;
};

const MedicalCategoryTableHeader: React.FC = () => {
  const theadStyle = "bg-gray-50 border-b-none w-full"
  const categoryNameTheadStyle = "w-1/3 py-3.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-6 lg:pl-8"
  const categoryTypeTheadStyle = "w-1/3 px-3 py-3.5 text-left font-semibold text-gray-900"
  const categoryManageButtonsTheadStyle = "w-1/3 px-3 py-3.5 text-left font-semibold text-gray-900"
  return (
    <thead className={theadStyle}>
      <tr>
        <th
          scope="col"
          className={categoryNameTheadStyle}
        >
          カテゴリ名
        </th>
        <th scope="col" className={categoryTypeTheadStyle}>
          タイプ
        </th>
        <th scope="col" className={categoryManageButtonsTheadStyle}></th>
      </tr>
    </thead>
  );
};

const MedicalCategoryTableBody: React.FC<OutlineType> = ({ children }) => {
  const tbodyStyle = "overflow-y-scroll divide-y divide-gray-200 bg-white w-full"
  return (
    <tbody className={tbodyStyle}>{children}</tbody>
  );
};

const MedicalCategoryTableRecord: React.FC<MedicalCategoryTableRecordType> = ({
  category,
  children,
}) => {
  return (
    <tr className="text-lg" key={category.id}>
      {children}
    </tr>
  );
};

const MedicalCategoryInfo: React.FC<MedicalCategoryInfoType> = ({
  category,
  deletable,
  setDeletable,
}) => {
  const categoryNameTdStyle = "flex items-center gap-6 whitespace-nowrap text-gray-900 sm:pl-6 lg:pl-8"
  const categoryNameLabelStyle = "w-full flex h-24 items-center"
  return (
    <>
      <td className={categoryNameTdStyle}>
        <label className={categoryNameLabelStyle}>
          <input
            checked={checkIsInDeletable(category.id, deletable)}
            onChange={() => {
              setCategoryIdInDeletables(category.id, deletable, checkIsInDeletable, setDeletable);
            }}
            type="checkbox"
            className="mr-4"
          />
          {category.name}
        </label>
      </td>
      <td className="px-3">{category.mainCategory}</td>
    </>
  );
};

const MedicalCategoryManageButtons: React.FC<OutlineType> = ({ children }) => {
  const tdStyle = "whitespace-nowrap px-3 py-2"
  const containerStyle = "border-l border-gray-400 flex gap-4 items-center justify-center"
  return (
    <td className={tdStyle}>
      <div className={containerStyle}>
        {children}
      </div>
    </td>
  );
};

const EditMedicalCategoryButton: React.FC<EditMedicalCategoryButtonType> = ({
  category,
  setCurrentCategory,
  setOpenAddCategory,
}) => {
  const buttonStyle = "inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 h-10 w-28 font-medium text-white shadow-sm"
  return (
    <button
      type="button"
      className={buttonStyle}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentCategory(category);
        setOpenAddCategory(true);
      }}
    >
      編集
    </button>
  );
};

const DeleteMedicalCategoryButton: React.FC<DeleteMedicalCategoryButtonType> = ({
  category,
  deletable,
  mutate,
}) => {
  const buttonStyle = 'inline-flex items-center justify-center rounded-md border border-transparent h-10 w-28 font-medium text-white shadow-sm'
  const isInDeletable = category.id != null && deletable.some(deletableId => deletableId === category.id)
  
  return (
    <button
      type="button"
      className={`${isInDeletable ? 'bg-indigo-600' : 'bg-gray-200'} ${buttonStyle}`}
      onClick={(e) => {
        e.stopPropagation();
        mutate({ id: category.id });
      }}
    >
      削除
    </button>
  );
};

const CategoryHeader: React.FC<{
  setMainCategory: React.Dispatch<React.SetStateAction<'all' | 'dental' | 'medical'>>
  mainCategory: 'all' | 'dental' | 'medical'
  deletable: any[]
  setCurrentCategorys: (categories: any) => void
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  setOpenAddCategory: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setMainCategory, mainCategory, deletable, setCurrentCategorys, setOpenDeleteDialog, setOpenAddCategory }) => (
  <MedicalMenuHeader>
    <SwitchMedicalCategoryButtons>
      <SelectAllCategoryButton setMainCategory={setMainCategory} mainCategory={mainCategory} />
      <SelectDentalCategoryButton setMainCategory={setMainCategory} mainCategory={mainCategory} />
      <SelectMedicalCategoryButton setMainCategory={setMainCategory} mainCategory={mainCategory} />
    </SwitchMedicalCategoryButtons>
    <HandleMedicalCategoryModalButtons>
      <OpenDeleteDialogButton deletable={deletable} setCurrentCategorys={setCurrentCategorys} setOpenDeleteDialog={setOpenDeleteDialog} />
      <OpenAddCategoryButton setOpenAddCategory={setOpenAddCategory} />
    </HandleMedicalCategoryModalButtons>
  </MedicalMenuHeader>
)

const CategoryTable: React.FC<{
  selectedCategoryList: any[]
  deletable: any[]
  setDeletable: (deletable: any) => void
  setCurrentCategory: (category: any) => void
  setOpenAddCategory: React.Dispatch<React.SetStateAction<boolean>>
  mutate: any
}> = ({ selectedCategoryList, deletable, setDeletable, setCurrentCategory, setOpenAddCategory, mutate }) => (
  <MedicalCategoryTableOutline>
    <MedicalCategoryTable>
      <MedicalCategoryTableHeader />
      <MedicalCategoryTableBody>
        {selectedCategoryList.map((category) => (
          <MedicalCategoryTableRecord key={category.id} category={category}>
            <MedicalCategoryInfo category={category} deletable={deletable} setDeletable={setDeletable} />
            <MedicalCategoryManageButtons>
              <EditMedicalCategoryButton category={category} setCurrentCategory={setCurrentCategory} setOpenAddCategory={setOpenAddCategory} />
              <DeleteMedicalCategoryButton category={category} deletable={deletable} mutate={mutate} />
            </MedicalCategoryManageButtons>
          </MedicalCategoryTableRecord>
        ))}
      </MedicalCategoryTableBody>
    </MedicalCategoryTable>
  </MedicalCategoryTableOutline>
)
