import { useState, useEffect, useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useGraphqlClient } from '@/App'
import { useAddMenuMutation } from '@/_graphql/graphql-client'
import type { MedicalCheckupFrequency } from '@/_graphql/graphql-client'
import type { CategoryType } from '../../../common/type'
import { ClinicContext } from '@/App'
import { medicalRole, medicalStatus, medicalSubjects } from '../../../common/types'
import type { MedicalRole, MedicalStatus, MedicalSubject } from '../../../common/types'
import { emptyCategory } from '../../../common/emptyData'

interface UseMedicalCareMenuFormProps {
  indexNum: number
  categoryList: CategoryType[]
  treatmentTimes: number[]
  setShow: (show: boolean) => void
}

export const useMedicalCareMenuForm = ({
  indexNum,
  categoryList,
  treatmentTimes,
  setShow
}: UseMedicalCareMenuFormProps) => {
  const clinicId = useContext(ClinicContext).clinic_id
  const firstElement = 0
  const [selectableCategoryList, setSelectableCategoryList] = useState([emptyCategory])
  const [treatmentName, setTreatmentName] = useState('')
  const [abbreviation, setAbbreviation] = useState('')
  const [amount] = useState(0)
  const [subCategoryId, setSubCategoryId] = useState('')
  const [treatmentTime, setTreatmentTime] = useState(treatmentTimes[firstElement])
  const [subject, setSubject] = useState<MedicalSubject>(medicalSubjects[firstElement])
  const [role, setRole] = useState<MedicalRole>(medicalRole[firstElement])
  const [menuStatus, setMenuStatus] = useState<MedicalStatus>(medicalStatus[firstElement])
  const [isPeriodicCheckup, setIsPeriodicCheckup] = useState(false)
  const [checkupFrequency, setCheckupFrequency] = useState<MedicalCheckupFrequency | null>(null)
  const [checkupIntervalDays, setCheckupIntervalDays] = useState<number | null>(null)

  const graphqlClient = useGraphqlClient()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (subject.length !== 0) {
      const _selectableCategoryList = getSelectableCategorys(categoryList, subject)
      setSubCategoryId(_selectableCategoryList[0]?.id)
      setSelectableCategoryList(_selectableCategoryList)
    }
  }, [subject, categoryList])

  const addMenu = useAddMenuMutation(graphqlClient, {
    onSettled: () => {
      void queryClient.invalidateQueries('listMenu')
    },
    onSuccess: res => {
      console.log(res)
    },
    onError: error => {
      throw error
    },
    retry: 3
  })

  const addMenuData = {
    amount,
    role,
    name: treatmentName,
    abbreviation,
    subCategoryId,
    parts: '',
    status: menuStatus,
    type: subject,
    time: treatmentTime,
    isDelete: false,
    index: indexNum,
    clinic_id: clinicId,
    isPeriodicCheckup: isPeriodicCheckup,
    checkupFrequency: checkupFrequency,
    checkupIntervalDays: checkupIntervalDays
  }

  const addMenuExecute = (): void => {
    addMenu.mutate(addMenuData)
    setShow(false)
  }

  return {
    treatmentName,
    setTreatmentName,
    abbreviation,
    setAbbreviation,
    treatmentTime,
    setTreatmentTime,
    subject,
    setSubject,
    subCategoryId,
    setSubCategoryId,
    role,
    setRole,
    menuStatus,
    setMenuStatus,
    isPeriodicCheckup,
    setIsPeriodicCheckup,
    checkupFrequency,
    setCheckupFrequency,
    checkupIntervalDays,
    setCheckupIntervalDays,
    selectableCategoryList,
    addMenuExecute
  }
}

function getSelectableCategorys(
  categoryList: CategoryType[],
  subject: string
): CategoryType[] {
  return categoryList.filter(category => category.mainCategory === subject)
} 