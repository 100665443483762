import { RefreshIcon } from '@heroicons/react/outline'
import { Badge } from '@mantine/core';
import type { RefreshButtonProps } from '../../../../entities';

const defaultStyle = 'delay-1000 opacity-100';
const isRefreshStyle = 'opacity-30 pointer-events-none';

export const RefreshButton: React.FC<RefreshButtonProps> = ({
    isRefresh,
    onRefresh
}) => {
    return (
        <div className={`${isRefresh ? isRefreshStyle : defaultStyle}`}>
            <Badge
                onClick={onRefresh}
                className='flex items-center font-bold mr-4 cursor-pointer'
                variant="filled"
            >
                <RefreshIcon className="h-3 w-3 mr-1 inline-block" />
                <span>カレンダー更新</span>
            </Badge>
        </div>
    )
}
