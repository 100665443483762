import { type MenuType } from '@/feature/phr-28/components/common/type'

export const medicalRole: string[] = ['ドクター(歯科)', 'ドクター(医科)', '歯科衛生士', '歯科助手', '技工士', 'ナース', 'コンシェルジュ']
export const medicalParts: string[] = ['指定無し', '口', '関節', '背中']
export const medicalSubjects: string[] = ['歯科', '医科']
export const medicalStatus: string[] = ['有効', '無効']
// FIXME: treatmentTimesをCardWidthから生成してください

export function getMenuRole (menuList: MenuType[], id: string): string {
  let menuRole: string = '未登録'
  menuList.forEach((menu: MenuType) => {
    if (menu.SDK_ID === id) {
      menuRole = menu.role
    }
  })
  return menuRole
}
