import { SelectViews } from '@/components/Common/components/parts/Select/views/SelectTimeForBlockViews';
import { japanTimeFormatDate } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon';
import { MedicalCategoryModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/MedicalCategoryModal';
import React, { useContext, useState } from 'react';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import {
  fromUnixTime,
  getUnixTime,
  startOfDay,
} from 'date-fns';
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData';

import {
  $FacilityName,
  $AppointDetailLabel,
  $ConsultationStartTime,
  SelectMenuLabel,
  InputRemarks,
  StaffSelector,
  SubmitButton,
} from '../../parts/AppointDetail';
import {
  _styledDivTop,
  _styledDivMiddle,
  _styledDivBottom,
  _styledDivLeftBottom,
} from '@/components/Reservation/components/parts/AppointDetail';
import { sortAndExtractIds } from '../utils/util';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { useUpdateEndTimeParam } from '../../parts/AppointDetail/hooks/useUpdateEndTimeParam';
import { isWithoutEndTime } from '../utils/AddAppoint';

import { SelectDentalFormulaLabel } from '../../parts/AppointDetail/common/SelectDentalFormulaLabel';
import { DentalFormulaModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/DentalFormulaModal';

import type { AddNewAppointType } from './schema';
import { SelectPatient } from './SelectPatient';

const isClinicTimeConfig = (config: any): config is { __typename: "ClinicTimeConfig", clinicTime?: { endTime: any, startTime: any } | null } => {
  return config?.__typename === "ClinicTimeConfig";
};

const TopSection: React.FC<{
  facilityList: any[];
  watch: any;
}> = ({ facilityList, watch }) => (
  <_styledDivTop>
    <$FacilityName facilityList={facilityList} facilityId={watch('appointData.facilityId')} />
    <$AppointDetailLabel label="新規追加" />
  </_styledDivTop>
);

const MiddleSection: React.FC<{
  watch: any;
  setValue: any;
  register: any;
  formData: any;
  setEndTime: any;
  minTreatmentTime: number;
  stepsMinutes: number;
}> = ({ watch, setValue, register, formData, setEndTime, minTreatmentTime, stepsMinutes }) => (
  <_styledDivMiddle>
    <$ConsultationStartTime appointStartTime={japanTimeFormatDate(fromUnixTime(watch('appointData.startTime')))} />
    <div className="flex text-xl">
      <div className="flex items-end">
        <SelectViews
          formType="appoint"
          setValue={setValue}
          register={register}
          formData={formData}
          setEndTime={setEndTime}
          minTreatmentTime={minTreatmentTime}
          stepsMinutes={stepsMinutes}
        />
      </div>
    </div>
  </_styledDivMiddle>
);

const BottomLeftSection: React.FC<{
  displayPatientName: string;
  watch: any;
  setOpenSearchPatientModal: any;
  openSearchPatientModal: boolean;
  setOpenAddPatientModal: any;
  menuListIncludeDelete: any[];
  openMedicalCategoryModal: boolean;
  setOpenMedicalCategoryModal: any;
  formData: any;
  categoryList: any[];
  menuList: any[];
  register: any;
  setValue: any;
}> = (props) => {
  const [openDentalFormulaModal, setOpenDentalFormulaModal] = useState(false);
  
  return (
    <_styledDivLeftBottom>
      <SelectPatient
        disabled={false}
        displayPatientName={props.displayPatientName}
        watch={props.watch}
        setOpenSearchPatientModal={props.setOpenSearchPatientModal}
        openSearchPatientModal={props.openSearchPatientModal}
        setOpenAddPatientModal={props.setOpenAddPatientModal}
      />
      <SelectMenuLabel
        allMenuList={props.menuListIncludeDelete}
        menuId={props.watch('appointData.menuId')}
        openSearchMenuModal={() => props.setOpenMedicalCategoryModal(true)}
      />
      {props.openMedicalCategoryModal && (
        <MedicalCategoryModal
          currentShiftTimestamp={props.formData.startTime}
          categoryList={props.categoryList}
          MenuList={props.menuList}
          setOpenMedicalCategoryModal={props.setOpenMedicalCategoryModal}
        />
      )}
      <SelectDentalFormulaLabel
        openSearchMenuModal={() => setOpenDentalFormulaModal(true)}
        dentalFormula={props.formData.dentalFormula}
      />
      {openDentalFormulaModal && (
        <DentalFormulaModal
          dentalFormula={props.formData.dentalFormula}
          setOpenDentalFormulaModal={setOpenDentalFormulaModal}
          setValue={props.setValue}
          setDentalFormula={() => {}}
        />
      )}
      <InputRemarks registerProps={props.register('appointData.remarks')} />
    </_styledDivLeftBottom>
  );
};

const BottomRightSection: React.FC<{
  register: any;
  manegerListsIncludeDelete: any[];
  watch: any;
  withoutEndTime: boolean;
}> = ({ register, manegerListsIncludeDelete, watch, withoutEndTime }) => (
  <_styledDivLeftBottom>
    <StaffSelector
      register={register}
      manegerListIncludeDelete={manegerListsIncludeDelete}
      selectedStaffIds={sortAndExtractIds(watch('appointData.staffIds'))}
    />
    <SubmitButton disabled={withoutEndTime} label="登録" />
  </_styledDivLeftBottom>
);

export const AddNewAppoint: React.FC<AddNewAppointType> = (props) => {
  const { setValue } = props.methods;
  const { nowDate } = useContext(SetDateContext);
  const { setEndTime } = useCommonParams();
  const clinic = useContext(ClinicContext);
  const graphqlClient = useGraphqlClient();

  const activeClinicTimeConfig = useGetLatestClinicConfigQuery(graphqlClient, {
    clinic_id: clinic.clinic_id,
    current_date: getUnixTime(startOfDay(nowDate)),
    config_type: 'clinic_time',
  });

  const configData = activeClinicTimeConfig.data?.getLatestClinicConfig?.config ?? null;
  const activeClinicTime = configData !== null && isClinicTimeConfig(configData) ? configData.clinicTime : null;
  const clinicTime = activeClinicTime ?? emptyClinicTimeConfig.config.clinicTime;
  const withoutEndTime = isWithoutEndTime(clinicTime, props.endTime, getUnixTime(nowDate));

  useUpdateEndTimeParam(setEndTime, 'appointData', props.methods);

  return (
    <div className="flex flex-col h-full min-h-0">
      <TopSection facilityList={props.facilityList} watch={props.watch} />
      <MiddleSection
        watch={props.watch}
        setValue={setValue}
        register={props.register}
        formData={props.formData}
        setEndTime={setEndTime}
        minTreatmentTime={props.minTreatmentTime}
        stepsMinutes={props.stepsMinutes}
      />
      <_styledDivBottom>
        <BottomLeftSection {...props} setValue={setValue} />
        <BottomRightSection
          register={props.register}
          manegerListsIncludeDelete={props.manegerListsIncludeDelete}
          watch={props.watch}
          withoutEndTime={withoutEndTime}
        />
      </_styledDivBottom>
    </div>
  );
};