/**
 * AppointmentListByPatientsPresenterコンポーネント内の予約ステータス部分のコンポーネント
 */

import { useContext, useState } from "react";
import { Badge } from '@mantine/core';
import { type AppointStatusBudgePresenterPropsType } from "@/components/Appointment/entities"
import { MutateContext } from "@/provider/common/MutateProvider";
import { CancelReasonSelectModal } from "./CancelReasonSelectModal";

export const AppointStatusBudgePresenter: React.FC<AppointStatusBudgePresenterPropsType> = ({
    selectedCancelReason,
    setSelectedCancelReason,
    appoint,
    id,
    startTime,
    isLate
}) => {
    const [isOpenCancelReasonModal, setIsOpenCancelReasonModal] = useState(false)
    const inActiveStyle = "bg-gray-300 hover:bg-grey-300 text-black cursor-default"
    const { changeAppointStatus } = useContext(MutateContext)

    const appointStatusData = [
        {
            status: '予約済み',
            statusName: '予約',
            styles: 'bg-green-200 text-green-900 hover:bg-green-300'
        },
        {
            status: '受付済み',
            statusName: '受付',
            styles: 'bg-blue-300 text-blue-900 hover:bg-blue-400'
        },
        {
            status: '診療完了',
            statusName: '完了',
            styles: 'bg-yellow-200 text-yellow-900 hover:bg-yellow-300'
        },
        {
            status: 'キャンセル',
            statusName: 'キャンセル',
            styles: 'bg-red-300 text-red-900 hover:bg-red-400'
        }
    ]

    const cancelReasonNames = ['患者都合', '医院都合', '無断キャンセル']

    return (
        <>
            <div className="flex flex-1 gap-2 justify-end">
                {appointStatusData?.map((data) => {
                    return (
                        // FIXME: eオブジェクトのany型を正しく定義する
                        <Badge
                            className={`${appoint !== data.status ? inActiveStyle : data.styles} font-bold cursor-pointer`}
                            variant="filled"
                            onClick={(e: any) => {
                                e.preventDefault();
                                if (data.status === 'キャンセル') {
                                    setIsOpenCancelReasonModal(true)
                                    
                                } else {
                                    changeAppointStatus.mutate({
                                        id,
                                        startTime,
                                        appoint: data.status,
                                        cancel: {
                                            isCancel: data.status === 'キャンセル',
                                            cancelReason: cancelReasonNames[selectedCancelReason],
                                            radioCancelReason: selectedCancelReason
                                        },
                                        isLate: (data.status === '予約済み' || data.status === 'キャンセル') ? false : isLate
                                    });
                                }
                            }
                            }>
                            {`${data.statusName}`}
                        </Badge>
                    )
                })}
            </div >

            {isOpenCancelReasonModal &&
                <CancelReasonSelectModal
                    onClose={() => { setIsOpenCancelReasonModal(false); }}
                    changeAppointStatusMutate={() => {
                        changeAppointStatus.mutate({
                            id,
                            startTime,
                            appoint: 'キャンセル',
                            cancel: {
                                isCancel: true,
                                cancelReason: cancelReasonNames[selectedCancelReason],
                                radioCancelReason: selectedCancelReason
                            },
                            isLate: false

                        })
                    }}
                    selectedCancelReason={selectedCancelReason}
                    setSelectedCancelReason={setSelectedCancelReason}
                />
            }

        </>
    )
}

export function updateAppointStatus () {

}