import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

interface $DateHeaderProps {
    date: Date;
    facilityFrameWidth: string;
}

export const $DateHeader: React.FC<$DateHeaderProps> = ({
    date,
    facilityFrameWidth,
}) => {
    const headerClassName = 'flex flex-row bg-gray-200 border-b-2 border-gray-200';
    const dateClassName = 'flex justify-center items-center h-10 text-sm font-bold';
    const headerStyle = { width: '100%' };
    const dateStyle = { width: facilityFrameWidth };

    return (
        <div className={headerClassName} style={headerStyle}>
            <div className={dateClassName} style={dateStyle}>
                {format(date, 'M/d (E)', { locale: ja })}
            </div>
        </div>
    );
}; 