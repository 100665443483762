import { Dialog, DialogContent } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { emptyCategory, emptyMenu } from '../../common/emptyData';
import type { CategoryType, MenuType } from '../../common/type';
import { medicalRole, medicalStatus } from '../../common/medicalMenu';
import { ClinicContext, useGraphqlClient } from '@/App';
import { MedicalCheckupFrequency, useGetLatestClinicConfigQuery, useUpDateMenuMutation } from '@/_graphql/graphql-client';
import { getUnixTime, startOfDay } from 'date-fns';
import { getTreatmentMinutes } from '@/components/Common/utils/common/addData';

interface EditMenuModalType {
    categoryList: CategoryType[],
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

function clearState (
    setCurrentMenu: React.Dispatch<React.SetStateAction<MenuType>>,
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
): void {
    setCurrentMenu(emptyMenu);
    setEditMode(false);
}

const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
    return config?.__typename === "CardWidthConfig";
};

const FormField: React.FC<{
    label: string;
    children: React.ReactNode;
}> = ({ label, children }): JSX.Element => (
    <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
        <label className="block text-xs font-medium text-gray-900">
            {label}
            <div className="text-xl border border-gray-300 rounded">
                {children}
            </div>
        </label>
    </div>
);

const BasicFields: React.FC<{
    register: any;
    watch: any;
}> = ({ register, watch }): JSX.Element => (
    <>
        <FormField label="メニュー名">
            <input
                type="text"
                {...register('name', { required: true })}
                className="px-4 h-10 inline-flex items-center block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                placeholder="診療カテゴリ名"
            />
        </FormField>
        <FormField label="略称">
            <input
                type="text"
                {...register('abbreviation')}
                className="px-4 h-10 inline-flex items-center block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                placeholder="略称"
            />
        </FormField>
        <FormField label="タイプ">
            <select
                {...register('type', { required: true })}
                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
            >
                <option value="医科" selected={watch('type') === "医科"}>医科</option>
                <option value="歯科" selected={watch('type') === "歯科"}>歯科</option>
            </select>
        </FormField>
    </>
);

const CategoryAndTimeFields: React.FC<{
    register: any;
    watch: any;
    categoryOptions: CategoryType[];
    treatmentTimes: number[];
}> = ({ register, watch, categoryOptions, treatmentTimes }): JSX.Element => (
    <>
        <FormField label="サブカテゴリ">
            <select
                {...register('subCategoryId', { required: true })}
                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
            >
                {categoryOptions.map(category => (
                    <option value={category.id} key={category.id} selected={watch('subCategoryId') === category.id}>
                        {category.name}
                    </option>
                ))}
            </select>
        </FormField>
        <FormField label="診療時間">
            <select
                {...register('time', { required: true })}
                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
            >
                {treatmentTimes.map((time: number, index: number) => (
                    <option value={time} key={index}>{time}</option>
                ))}
            </select>
        </FormField>
    </>
);

const RoleAndStatusFields: React.FC<{
    register: any;
}> = ({ register }): JSX.Element => (
    <>
        <FormField label="役割">
            <select
                {...register('role')}
                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
            >
                {medicalRole.map((role: string, index: number) => (
                    <option value={role} key={index}>{role}</option>
                ))}
            </select>
        </FormField>
        <FormField label="状態">
            <select
                {...register('status')}
                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
            >
                {medicalStatus.map((state: string, index: number) => (
                    <option value={state} key={index}>{state}</option>
                ))}
            </select>
        </FormField>
    </>
);

const CheckupFrequencyField: React.FC<{
    register: any;
}> = ({ register }): JSX.Element => (
    <FormField label="検診頻度">
        <select
            {...register('checkupFrequency')}
            className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
        >
            <option value="WEEKLY">毎週</option>
            <option value="BIWEEKLY">隔週</option>
            <option value="MONTHLY">毎月</option>
            <option value="BIMONTHLY">隔月</option>
            <option value="QUARTERLY">3ヶ月ごと</option>
            <option value="HALFYEARLY">半年ごと</option>
            <option value="CUSTOM">カスタム</option>
        </select>
    </FormField>
);

const CustomIntervalField: React.FC<{
    register: any;
}> = ({ register }): JSX.Element => (
    <FormField label="カスタム間隔（日数）">
        <input
            type="number"
            min="1"
            {...register('checkupIntervalDays')}
            className="px-4 h-10 inline-flex items-center block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
            placeholder="日数を入力"
        />
    </FormField>
);

const PeriodicCheckupFields: React.FC<{
    register: any;
    watch: any;
    showCheckupFields: boolean;
    setShowCheckupFields: (value: boolean) => void;
}> = ({ register, watch, showCheckupFields, setShowCheckupFields }): JSX.Element => (
    <div className="w-full">
        <div className="w-full px-3 py-2">
            <label className="flex items-center gap-2">
                <input
                    type="checkbox"
                    checked={showCheckupFields}
                    onChange={(e): void => {
                        setShowCheckupFields(e.target.checked);
                    }}
                    className="w-4 h-4"
                />
                <span className="text-sm font-medium text-gray-900">定期診療を設定する</span>
            </label>
        </div>

        {showCheckupFields && (
            <div className="w-full flex flex-wrap gap-4 px-3">
                <div className="w-full md:w-1/2">
                    <CheckupFrequencyField register={register} />
                </div>
                {watch('checkupFrequency') === 'CUSTOM' && (
                    <div className="w-full md:w-1/2">
                        <CustomIntervalField register={register} />
                    </div>
                )}
            </div>
        )}
    </div>
);

const MenuFormFields: React.FC<{
    register: any;
    watch: any;
    categoryOptions: CategoryType[];
    treatmentTimes: number[];
    showCheckupFields: boolean;
    setShowCheckupFields: (value: boolean) => void;
}> = ({ register, watch, categoryOptions, treatmentTimes, showCheckupFields, setShowCheckupFields }): JSX.Element => (
    <div className="flex flex-wrap gap-y-2 justify-start items-center">
        <BasicFields register={register} watch={watch} />
        <CategoryAndTimeFields
            register={register}
            watch={watch}
            categoryOptions={categoryOptions}
            treatmentTimes={treatmentTimes}
        />
        <RoleAndStatusFields register={register} />
        <PeriodicCheckupFields
            register={register}
            watch={watch}
            showCheckupFields={showCheckupFields}
            setShowCheckupFields={setShowCheckupFields}
        />
    </div>
);

const EditMenuForm: React.FC<{
  methods: any;
  categoryOptions: CategoryType[];
  treatmentTimes: number[];
  showCheckupFields: boolean;
  setShowCheckupFields: (value: boolean) => void;
  onSubmit: (data: MenuType) => void;
  setCurrentMenu: React.Dispatch<React.SetStateAction<MenuType>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ methods, categoryOptions, treatmentTimes, showCheckupFields, setShowCheckupFields, onSubmit, setCurrentMenu, setEditMode }): JSX.Element => {
  const { register, watch } = methods;
  
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e): void => {
          void methods.handleSubmit(onSubmit)(e);
        }}
        className="flex flex-col items-center justify-center h-full gap-8"
      >
        <p className="w-full inline-flex justify-center items-center py-3 text-xl tracking-wide">
          <span className="text-red-500">診療メニュー</span>を編集してください。
        </p>
        <MenuFormFields
          register={register}
          watch={watch}
          categoryOptions={categoryOptions}
          treatmentTimes={treatmentTimes}
          showCheckupFields={showCheckupFields}
          setShowCheckupFields={setShowCheckupFields}
        />

        <div className="flex justify-end gap-4">
          <input type="submit" value="登録" className="w-36 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 py-2 text-sm font-medium text-white shadow-sm" />
          <p onClick={(): void => { clearState(setCurrentMenu, setEditMode); }} className="w-36 inline-flex justify-center items-center rounded-md border border-transparent bg-red-400 py-2 text-sm font-medium text-white shadow-sm">キャンセル</p>
        </div>
      </form>
    </FormProvider>
  );
};

interface FormValues {
    SDK_ID: string;
    amount: number;
    courponId: string;
    role: string;
    name: string;
    abbreviation: string;
    subCategoryId: string;
    parts: string;
    status: string;
    time: number;
    type: string;
    isDelete: boolean;
    index: number;
    isPeriodicCheckup: boolean;
    checkupFrequency: 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY' | 'BIMONTHLY' | 'QUARTERLY' | 'HALFYEARLY' | 'CUSTOM' | undefined;
    checkupIntervalDays: number;
}

interface EditMenuLogicResult {
    methods: ReturnType<typeof useForm<FormValues>>;
    categoryOptions: CategoryType[];
    showCheckupFields: boolean;
    setShowCheckupFields: (value: boolean) => void;
    onSubmit: (data: MenuType) => void;
}

const useMenuForm = (
    currentMenu: MenuType,
    treatmentTimes: number[]
): ReturnType<typeof useForm<FormValues>> => {
    return useForm<FormValues>({
        defaultValues: {
            SDK_ID: currentMenu.SDK_ID,
            amount: currentMenu.amount,
            courponId: currentMenu.courponId,
            role: currentMenu.role,
            name: currentMenu.name,
            abbreviation: currentMenu.abbreviation,
            subCategoryId: currentMenu.subCategoryId,
            parts: currentMenu.parts,
            status: currentMenu.status,
            time: currentMenu.time === 0 ? treatmentTimes[0] : currentMenu.time,
            type: currentMenu.type,
            isDelete: currentMenu.isDelete,
            index: currentMenu.index,
            isPeriodicCheckup: currentMenu.isPeriodicCheckup ?? false,
            checkupFrequency: currentMenu.checkupFrequency ?? 'MONTHLY',
            checkupIntervalDays: currentMenu.checkupIntervalDays ?? 30
        }
    });
};

const convertCheckupFrequency = (frequency: string | null): MedicalCheckupFrequency | null => {
    if (!frequency || frequency.trim().length === 0) return null;
    switch (frequency) {
        case 'WEEKLY':
            return MedicalCheckupFrequency.Weekly;
        case 'BIWEEKLY':
            return MedicalCheckupFrequency.Biweekly;
        case 'MONTHLY':
            return MedicalCheckupFrequency.Monthly;
        case 'BIMONTHLY':
            return MedicalCheckupFrequency.Bimonthly;
        case 'QUARTERLY':
            return MedicalCheckupFrequency.Quarterly;
        case 'HALFYEARLY':
            return MedicalCheckupFrequency.Halfyearly;
        case 'CUSTOM':
            return MedicalCheckupFrequency.Custom;
        default:
            return null;
    }
};

const useMenuMutation = (
    graphqlClient: any,
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>,
    clinicId: string,
    showCheckupFields: boolean
): ((data: MenuType) => void) => {
    const queryClient = useQueryClient();
    const updateMenu = useUpDateMenuMutation(graphqlClient, {
        onSettled: (): void => {
            void queryClient.invalidateQueries('listMenu').catch(() => {
                console.log('on Settled');
            });
        },
        onSuccess: (): void => {
            setEditMode(false);
        },
        onError: (error): void => {
            console.error('メニューの更新に失敗しました:', error);
            alert('メニューの更新に失敗しました');
            setEditMode(false);
        },
        retry: 3
    });

    const submitMenu = (data: MenuType): void => {
        const time = Number(data.time);
        if (isNaN(time) || time <= 0) {
            alert('診療時間は有効な数値を入力してください');
            return;
        }
        
        const submissionData = {
            ...data,
            time,
            clinic_id: clinicId,
            isPeriodicCheckup: showCheckupFields,
            checkupFrequency: showCheckupFields ? convertCheckupFrequency(data.checkupFrequency) : null,
            checkupIntervalDays: showCheckupFields && data.checkupFrequency === 'CUSTOM' ? Number(data.checkupIntervalDays) : null
        };
        
        updateMenu.mutate(submissionData);
    };

    return submitMenu;
};

const useEditMenuLogic = (
    categoryList: CategoryType[],
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>,
    currentMenu: MenuType,
    setCurrentMenu: React.Dispatch<React.SetStateAction<MenuType>>,
    clinicId: string,
    treatmentTimes: number[]
): EditMenuLogicResult => {
    const graphqlClient = useGraphqlClient();
    const methods = useMenuForm(currentMenu, treatmentTimes);

    const [categoryOptions, setCategoryOptions] = useState([emptyCategory]);
    const [showCheckupFields, setShowCheckupFields] = useState(currentMenu.isPeriodicCheckup ?? false);

    useEffect(() => {
        const type = methods.watch('type');
        if (typeof type === 'string' && type.length > 0) {
            const _categoryOptions = categoryList.filter(category => {
                return category.mainCategory === type;
            });
            setCategoryOptions(_categoryOptions);
        }
    }, [methods.watch('type'), categoryList]);

    const onSubmit = useMenuMutation(graphqlClient, setEditMode, clinicId, showCheckupFields);

    return {
        methods,
        categoryOptions,
        showCheckupFields,
        setShowCheckupFields,
        onSubmit
    };
};

interface ClinicSettings {
    clinicId: string;
    treatmentTimes: number[];
}

const useClinicSettings = (): ClinicSettings => {
    const graphqlClient = useGraphqlClient();
    const clinicId = useContext(ClinicContext).clinic_id;
   
    const { data: cardWidthList } = useGetLatestClinicConfigQuery(graphqlClient, {
        clinic_id: clinicId,
        current_date: getUnixTime(startOfDay(new Date())),
        config_type: 'card_width',
    });
   
    const configData = cardWidthList?.getLatestClinicConfig?.config ?? null;
    const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 30;
    const maxTreatmentMinutes = 180;
    const minTreatmentTime = cardWidth;
   
    const treatmentTimes = getTreatmentMinutes(maxTreatmentMinutes, minTreatmentTime, cardWidth);

    return {
        clinicId,
        treatmentTimes
    };
};

const ModalContent: React.FC<{
    methods: ReturnType<typeof useForm<FormValues>>;
    categoryOptions: CategoryType[];
    treatmentTimes: number[];
    showCheckupFields: boolean;
    setShowCheckupFields: (value: boolean) => void;
    onSubmit: (data: MenuType) => void;
    setCurrentMenu: React.Dispatch<React.SetStateAction<MenuType>>;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    methods,
    categoryOptions,
    treatmentTimes,
    showCheckupFields,
    setShowCheckupFields,
    onSubmit,
    setCurrentMenu,
    setEditMode
}): JSX.Element => (
    <Dialog
        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
        onClose={(): void => { clearState(setCurrentMenu, setEditMode); }}
        open={true}
        PaperProps={{
            style: {
                maxWidth: 'none',
                width: '64%',
                transition: 'all 0',
                zIndex: 9999
            }
        }}
    >
        <DialogContent
            dividers
            style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '86vh', position: 'relative' }}
        >
            <EditMenuForm
                methods={methods}
                categoryOptions={categoryOptions}
                treatmentTimes={treatmentTimes}
                showCheckupFields={showCheckupFields}
                setShowCheckupFields={setShowCheckupFields}
                onSubmit={onSubmit}
                setCurrentMenu={setCurrentMenu}
                setEditMode={setEditMode}
            />
        </DialogContent>
    </Dialog>
);

const EditMenuModalComponent: React.FC<EditMenuModalType & {
    currentMenu: MenuType;
    setCurrentMenu: React.Dispatch<React.SetStateAction<MenuType>>;
    clinicId: string;
    treatmentTimes: number[];
}> = ({
    categoryList,
    setEditMode,
    currentMenu,
    setCurrentMenu,
    clinicId,
    treatmentTimes
}): JSX.Element => {
    const {
        methods,
        categoryOptions,
        showCheckupFields,
        setShowCheckupFields,
        onSubmit
    } = useEditMenuLogic(
        categoryList,
        setEditMode,
        currentMenu,
        setCurrentMenu,
        clinicId,
        treatmentTimes
    );

    return (
        <ModalContent
            methods={methods}
            categoryOptions={categoryOptions}
            treatmentTimes={treatmentTimes}
            showCheckupFields={showCheckupFields}
            setShowCheckupFields={setShowCheckupFields}
            onSubmit={onSubmit}
            setCurrentMenu={setCurrentMenu}
            setEditMode={setEditMode}
        />
    );
};

export const useEditMenuModal = (): {
    EditMenuModal: React.FC<EditMenuModalType>;
    setCurrentMenu: React.Dispatch<React.SetStateAction<MenuType>>;
} => {
    const { clinicId, treatmentTimes } = useClinicSettings();
    const [currentMenu, setCurrentMenu] = useState<MenuType>(emptyMenu);

    const EditMenuModal: React.FC<EditMenuModalType> = (props): JSX.Element => (
        <EditMenuModalComponent
            {...props}
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            clinicId={clinicId}
            treatmentTimes={treatmentTimes}
        />
    );

    return {
        EditMenuModal,
        setCurrentMenu
    };
};

// const getCheckupFrequencyText = (frequency: MenuType['checkupFrequency'], checkupIntervalDays?: number | null): string => {
//   const frequencyMap: Record<NonNullable<MenuType['checkupFrequency']>, string> = {
//     'WEEKLY': '毎週',
//     'BIWEEKLY': '隔週',
//     'MONTHLY': '毎月',
//     'BIMONTHLY': '隔月',
//     'QUARTERLY': '3ヶ月ごと',
//     'HALFYEARLY': '半年ごと',
//     'CUSTOM': 'カスタム',
//   };
//   return frequencyMap[frequency] ?? '-';
// };
