import { Flex } from '@mantine/core';
import { ErrorBoundary } from '@/components/Common/components';

import { _AppointShiftWrapper } from '../baseStyled/_AppointShiftWrapperForDaily';
import { $FacilitiesLabel } from '../extendedStyled/$FacilitiesLabelForDaily';
import { $TimeBar } from '../extendedStyled/$TimeBar';
import { $FacilityFrame } from '../extendedStyled/$FacilityFrameForDaily';
import { OneHourSlots } from './OneHourSlots';

import { AppointCardPresenter } from '@/components/Reservation/components/presenter/AppointCardPresenter';
import { BlockCard, InteractionCard } from '@/components/Reservation/components/parts/AppointSchedule/AppointShift';
import { startOfTodayTimestamp } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon';
import { getUnixTime } from 'date-fns';
import type { FacilityType } from '@/components/Facility/entities';
import type { BlockType } from '@/components/Block/entities';
import type { AppointDetails } from '@/_graphql/graphql-client';
import type { NavigateType, viewSizesType } from '@/components/Common/entities';
import type { Dispatch, SetStateAction } from 'react';

interface DailyShiftPrintViewProps {
    printRef: React.RefObject<HTMLDivElement>;
    facilityList: FacilityType[];
    facilityFrameWidth: number;
    shiftFrameStart: number;
    shiftFrameEnd: number;
    shiftEndMinutes: number;
    shiftStartHours: number;
    shiftStartMinutes: number;
    shiftEndHours: number;
    nowDate: Date;
    operation: "add" | "reference" | "edit" | "copy" | "update" | undefined;
    appointId: string;
    blockId: string;
    facilityId: string;
    startTime?: number;
    endTime?: number;
    AppointMapByFacilityId: Record<string, AppointDetails[]>;
    blockMapByFacilityId: Record<string, BlockType[]>;
    cardWidth: number;
    hourTimeStamp: number;
    quarterTimeStamp: number;
    isHoliday: boolean;
    isBeforeDay: boolean;
    matches: boolean;
    setAppointParams: (date: number, id: string, startTime: number, endTime: number, facilityId: string, appointId: string) => void;
    setBlockParams: (date: number, id: string, startTime: number, endTime: number, facilityId: string) => void;
    setOpenAppointInfoForSP: (open: boolean) => void;
    setOpenBlockInfoForSP: (open: boolean) => void;
    setIsPaneOpen: Dispatch<SetStateAction<boolean>> | null;
    navigate: NavigateType;
    reset: () => void;
    setValue: any;
    watch: any;
    subPanesRef: any;
    viewSizes: viewSizesType;
    setCommonParams: any;
    resetAppointId: () => void;
    resetBlockId: () => void;
    openPane: (ref: any, position: "top" | "bottom" | "left", height: number, width: number) => void;
}

export const DailyShiftPrintView: React.FC<DailyShiftPrintViewProps> = ({
    printRef,
    facilityList,
    facilityFrameWidth,
    shiftFrameStart,
    shiftFrameEnd,
    shiftEndMinutes,
    shiftStartHours,
    shiftStartMinutes,
    shiftEndHours,
    nowDate,
    operation,
    appointId,
    blockId,
    facilityId,
    startTime,
    endTime,
    AppointMapByFacilityId,
    blockMapByFacilityId,
    cardWidth,
    hourTimeStamp,
    quarterTimeStamp,
    isHoliday,
    isBeforeDay,
    matches,
    setAppointParams,
    setBlockParams,
    setOpenAppointInfoForSP,
    setOpenBlockInfoForSP,
    setIsPaneOpen,
    navigate,
    reset,
    setValue,
    watch,
    subPanesRef,
    viewSizes,
    setCommonParams,
    resetAppointId,
    resetBlockId,
    openPane,
}) => {
    console.log('Debug - AppointMapByFacilityId:', AppointMapByFacilityId);
    console.log('Debug - blockMapByFacilityId:', blockMapByFacilityId);
    console.log('Debug - facilityList:', facilityList);

    const handleAppointClick = (
        appoint: AppointDetails,
        setAppointParams: (date: number, id: string, startTime: number, endTime: number, facilityId: string, appointId: string) => void,
        setOpenAppointInfoForSP: (open: boolean) => void,
        resetAppointId: () => void
    ): void => {
        setAppointParams(getUnixTime(nowDate), appoint.id, appoint.startTime, appoint.endTime, appoint.facilityId, appoint.appointId);
        setOpenAppointInfoForSP(true);
        resetAppointId();
    };

    const handleBlockClick = (
        block: BlockType,
        setBlockParams: (date: number, id: string, startTime: number, endTime: number, facilityId: string) => void,
        setOpenBlockInfoForSP: (open: boolean) => void,
        resetBlockId: () => void
    ): void => {
        setOpenBlockInfoForSP(true);
        setBlockParams(getUnixTime(nowDate), block.id, block.startTime, block.endTime, block.facilityId);
        resetBlockId();
    };

    const handleResetAndOpenPane = (
        subPanesRef: any,
        viewSizes: viewSizesType,
        reset: () => void,
        setIsPaneOpen: Dispatch<SetStateAction<boolean>> | null
    ): void => {
        reset();
        if (setIsPaneOpen !== null) {
            setIsPaneOpen(true);
        }
        openPane(subPanesRef, 'top', viewSizes.height, viewSizes.width);
    };

    console.log("facilityFrameWidth", facilityFrameWidth);

    return (
        <_AppointShiftWrapper printRef={printRef}>
            <$FacilitiesLabel
                facilityList={facilityList}
                facilityFrameWidth={facilityFrameWidth}
            />

            <Flex direction='row' justify='around' className='box-border p-1'>
                <$TimeBar
                    shiftStartTime={shiftFrameStart}
                    shiftEndTime={shiftFrameEnd}
                    shiftEndMinutes={shiftEndMinutes}
                />

                {facilityList.map((facility: FacilityType) => {
                    const firstCardTime: number = startOfTodayTimestamp(nowDate) + hourTimeStamp * shiftStartHours;

                    const relatedAppoints = AppointMapByFacilityId?.[facility.id] ?? [];
                    const appointCards = relatedAppoints.map((appoint) => {
                        // カードの高さをcardWidthに基づいて計算
                        const durationMinutes = (appoint.endTime - appoint.startTime) / 60;
                        const cardBoxNum = Math.ceil(durationMinutes / (cardWidth ?? 15));
                        const appointCardHigh = cardBoxNum * 100 - 10;

                        // カードの位置をcardWidthに基づいて計算
                        const startMinutes = (appoint.startTime - firstCardTime) / 60;
                        const startPosition = (startMinutes / (cardWidth ?? 15)) * 100;

                        return (
                            <ErrorBoundary key={appoint.appointId + appoint.id}>
                                <AppointCardPresenter
                                    oparation={operation}
                                    appointId={appointId}
                                    reservation={appoint}
                                    reservationCardHigh={appointCardHigh}
                                    startTime={startPosition}
                                    endTime={endTime ?? 0}
                                    onClickHandler={() => {
                                        if (matches) {
                                            handleAppointClick(appoint, setAppointParams, setOpenAppointInfoForSP, resetAppointId);
                                            return;
                                        }
                                        if (operation === "edit") {
                                            return;
                                        }
                                        handleResetAndOpenPane(subPanesRef, viewSizes, reset, setIsPaneOpen);
                                        navigate({ 
                                            to: `appoints-list/appoint-info`,
                                            fromCurrent: false,
                                        });
                                        handleAppointClick(appoint, setAppointParams, setOpenAppointInfoForSP, resetAppointId);
                                    }}
                                />
                            </ErrorBoundary>
                        );
                    });

                    const relatedBlocks = blockMapByFacilityId?.[facility.id] ?? [];
                    const blockCards = relatedBlocks.map((block: BlockType) => {
                        return (
                            <ErrorBoundary key={block.id}>
                                <BlockCard
                                    block={block}
                                    firstCardTime={firstCardTime}
                                    quarterTimeStamp={quarterTimeStamp}
                                    isBlockEdit={operation === 'edit' && blockId === block.id}
                                    blockId={blockId}
                                    endTime={endTime ?? 0}
                                    onClickHandler={() => {
                                        if (matches) {
                                            handleBlockClick(block, setBlockParams, setOpenBlockInfoForSP, resetBlockId);
                                            return;
                                        }
                                        navigate({ to: `add-appoint`, fromCurrent: false });
                                        setBlockParams(getUnixTime(nowDate), block.id, block.startTime, block.endTime, block.facilityId);
                                        handleResetAndOpenPane(subPanesRef, viewSizes, reset, setIsPaneOpen);
                                    }}
                                />
                            </ErrorBoundary>
                        )
                    });

                    const interactionCard =
                        <InteractionCard
                            firstCardTime={firstCardTime}
                            quarterTimeStamp={quarterTimeStamp}
                            startTime={startTime ?? 0}
                            endTime={endTime ?? 0}
                        />


                    // シフト枠
                    const clinicTimeRange = Array.from({ length: shiftFrameEnd - shiftFrameStart + 1 }, (_, i) => i + shiftFrameStart);
                    const shiftFrames = clinicTimeRange.map((currentHour, index) => {
                        const isShiftStart = currentHour === shiftStartHours;
                        const isShiftEnd = currentHour === shiftEndHours;
                        const isShiftEndOnHourBoundary = isShiftEnd && shiftEndMinutes === 0;

                        // シフト開始のインデックス
                        const shiftStartQuarterIndex = (isShiftStart ? shiftStartMinutes : 0) / cardWidth;
                        // シフト終了のインデックス
                        const shiftEndQuarterIndex = (isShiftEnd ? shiftEndMinutes : 60) / cardWidth;

                        return (
                            <>
                                {
                                    !isShiftEndOnHourBoundary &&
                                    <OneHourSlots
                                        key={currentHour}
                                        intervalMinute={cardWidth}
                                        shiftStartHours={shiftStartHours}
                                        shiftStartMinutes={shiftStartMinutes}
                                        shiftEndHours={shiftEndHours}
                                        shiftEndMinutes={shiftEndMinutes}
                                        currentHour={currentHour}
                                        isShiftStart={isShiftStart}
                                        isShiftEnd={isShiftEnd}
                                        shiftStartQuarterIndex={shiftStartQuarterIndex}
                                        shiftEndQuarterIndex={shiftEndQuarterIndex}
                                        isHoliday={isHoliday}
                                        isBeforeDay={isBeforeDay}
                                        operation={operation}
                                        nowDate={nowDate}
                                        facility={facility}
                                        blockId={blockId}
                                        appointId={appointId}
                                        startTime={startTime ?? 0}
                                        endTime={endTime ?? 0}
                                        setValue={setValue}
                                        navigate={navigate}
                                        setIsPaneOpen={setIsPaneOpen}
                                        openPane={openPane}
                                        watch={watch}
                                        subPanesRef={subPanesRef}
                                        viewSizes={viewSizes}
                                        setCommonParams={setCommonParams}
                                        setBlockParams={setBlockParams}
                                        setAppointParams={setAppointParams}
                                        resetAppointId={resetAppointId}
                                        resetBlockId={resetBlockId}
                                    />
                                }
                            </>
                        );
                    })

                    // 戻り値
                    return (
                        <$FacilityFrame key={facility.id} facilityFrameWidth={facilityFrameWidth}>
                            {shiftFrames}
                            {
                                facilityId === facility.id && typeof endTime === 'number' &&
                                interactionCard
                            }
                            {blockCards}
                            {appointCards}
                        </$FacilityFrame>
                    )
                })}
            </Flex>
        </_AppointShiftWrapper>
    );
}; 
