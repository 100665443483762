import { GroupSelect } from '@/components/Reservation/components/parts/AppointSchedule/AppointHeader/GroupSelect';
import { CloseButton } from '@/components/Common';
import { useMediaQuery } from "@mantine/hooks";
import { useQueryClient } from "react-query";
import { StatusNumbers } from "./StatusNumbers";
import { DateSelector } from "./DateSelector";
import { AppointRefreshControler } from "./AppointRefreshControler";
import { Print } from "./Print";
import { TimeSlotHeightToggle } from "./TimeSlotHeightToggle";
import { useRefreshObserver } from "../../../../hooks";
import { $styledDiv } from "./$styledDiv";
import type { AppointHeaderPresenterPropsType } from '../../../../entities';
import { countAppointStatuses } from '../../../presenter/AppointPresenter';
import type { Dispatch, SetStateAction, RefObject } from 'react';
import type { AllotmentHandle } from 'allotment';
import { useDisplayDays } from '../../../../hooks/useDisplayDays';

interface LeftSectionProps {
  nowDate: Date;
  setNowDate: Dispatch<SetStateAction<Date>>;
  isMobile: boolean;
  numberOfReserved: number;
  numberOfCheckedIn: number;
  numberOfCompleted: number;
  numberOfCanceled: number;
}

interface RightSectionProps {
  isRefresh: boolean;
  setRefreshTriggered: (value: boolean) => void;
  isMobile: boolean;
  setFacilitiesFilter: Dispatch<SetStateAction<string>>;
  printRef: RefObject<HTMLDivElement | undefined>;
  subPanesRef: RefObject<AllotmentHandle>;
  DisplayDaysSelect: () => JSX.Element;
}

const LeftSection: React.FC<LeftSectionProps> = ({
  nowDate,
  setNowDate,
  isMobile,
  numberOfReserved,
  numberOfCheckedIn,
  numberOfCompleted,
  numberOfCanceled
}) => (
  <$styledDiv styleType="left" isMobile={isMobile}>
    <DateSelector
      nowDate={nowDate}
      setNowDate={setNowDate}
      isMobile={isMobile}
    />
    <StatusNumbers
      numberOfReserved={numberOfReserved}
      numberOfCheckedIn={numberOfCheckedIn}
      numberOfCompleted={numberOfCompleted}
      numberOfCanceled={numberOfCanceled}
    />
  </$styledDiv>
);

const RightSection: React.FC<RightSectionProps> = ({
  isRefresh,
  setRefreshTriggered,
  isMobile,
  setFacilitiesFilter,
  printRef,
  subPanesRef,
  DisplayDaysSelect
}) => {
  const queryClient = useQueryClient();
  const { displayDays } = useDisplayDays();
  const showPrintButton = displayDays === "1";

  return (
    <$styledDiv styleType="right">
      <AppointRefreshControler
        isRefresh={isRefresh}
        onRefresh={() => {
          console.log('予約更新ボタン押下');
          void queryClient.invalidateQueries();
          setRefreshTriggered(true);
        }}
      />
      <TimeSlotHeightToggle />
      <DisplayDaysSelect />
      <GroupSelect
        isMobile={isMobile}
        setFacilitiesFilter={setFacilitiesFilter}
      />
      <div className="flex items-bottom justify-end gap-1">
        {showPrintButton && <Print printRef={printRef} />}
        <CloseButton panesRef={subPanesRef} closeTarget="bottom" />
      </div>
    </$styledDiv>
  );
};

export const AppointHeader: React.FC<AppointHeaderPresenterPropsType> = ({
  printRef,
  subPanesRef,
  nowDate,
  setNowDate,
  allReservationList,
  isAllTime,
  setIsAllTime,
  setFacilitiesFilter,
  appointIsLoading
}) => {
  const { isRefresh, setRefreshTriggered } = useRefreshObserver(appointIsLoading);
  const {
    numberOfReserved,
    numberOfCheckedIn,
    numberOfCompleted,
    numberOfCanceled
  } = countAppointStatuses(allReservationList);
  const isMobile = useMediaQuery('(max-width: 928px)');
  const { DisplayDaysSelect } = useDisplayDays();

  return (
    <$styledDiv styleType="wrapper" isMobile={isMobile}>
      <LeftSection
        nowDate={nowDate}
        setNowDate={setNowDate}
        isMobile={isMobile}
        numberOfReserved={numberOfReserved}
        numberOfCheckedIn={numberOfCheckedIn}
        numberOfCompleted={numberOfCompleted}
        numberOfCanceled={numberOfCanceled}
      />
      <RightSection
        isRefresh={isRefresh}
        setRefreshTriggered={setRefreshTriggered}
        isMobile={isMobile}
        setFacilitiesFilter={setFacilitiesFilter}
        printRef={printRef}
        subPanesRef={subPanesRef}
        DisplayDaysSelect={DisplayDaysSelect}
      />
    </$styledDiv>
  );
};