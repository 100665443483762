import React from 'react';
import type { RecallStatus } from '@/_graphql/graphql-client';

interface StatusFilterGroupProps {
  selectedStatuses: Set<RecallStatus>;
  onStatusChange: (status: RecallStatus) => void;
  statusLabels: Record<RecallStatus, string>;
}

export const StatusFilterGroup: React.FC<StatusFilterGroupProps> = ({ 
  selectedStatuses, 
  onStatusChange, 
  statusLabels 
}) => (
  <div className="flex flex-wrap gap-4">
    {(Object.keys(statusLabels) as RecallStatus[]).map((status) => (
      <label key={status} className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={selectedStatuses.has(status)}
          onChange={() => onStatusChange(status)}
          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <span className="text-sm text-gray-700">{statusLabels[status]}</span>
      </label>
    ))}
  </div>
); 