import GridOffOutlinedIcon from '@mui/icons-material/GridOffOutlined';
import { getFacilityName } from '@/components/Common/utils/common/getInfo';
import { japanTimeFormatDate } from '@/components/Common/utils';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
// import { AppointBlockPresenterType } from '@/components/Block/entities';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { SelectViews } from '@/components/Common/components/parts/Select/views/SelectTimeForBlockViews';
import { dateFromUnixTime } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon';
import { useLocation, useNavigate, useSearch } from '@tanstack/react-location';
import { DeleteBlockType } from '@/components/Common';
import {
  AppointBlockPresenterType,
  BlockColorType,
  BlockCommentType,
  BlockDateType,
  BlockDescriptionType,
  BlockFormChangeDateButtonType,
  BlockFormDeleteButtonType,
  BlockFormOutlineType,
  BlockFormType,
  BlockSubmitButtonOutlineType,
} from '../../entities/components/AppointBlock';
import { validateFormData } from '../../utils';
import { OutlineType } from '@/components/Common/entities/parts';
import React from 'react';
import { DeleteConfirmationDialog } from '@/components/Reservation/components/ModalContents/DeleteConfirmationDialog';


export const AppointBlockPresenter: React.FC<AppointBlockPresenterType> = ({
  clinic,
  clinicTime,
  facilityNameList,
  methods,
  mutates,
  formData,
  navigate,
  operation,
  setEndTime,
  isBlockLoading,
  minTreatmentTime,
  stepsMinutes,
}) => {
  const { setValue, register, watch } = methods;
  const { addBlock, updateBlock, updateBlockStatus, deleteBlock } = mutates;

  const startTimeLabel = japanTimeFormatDate(dateFromUnixTime(formData.startTime));

  return (
    <BlockForm
      setValue={setValue}
      register={register}
      watch={watch}
      clinic_id={clinic.clinic_id}
      methods={methods}
      startTimeLabel={startTimeLabel}
      clinic={clinic}
      clinicTime={clinicTime}
      facilityNameList={facilityNameList}
      formData={formData}
      addBlock={addBlock}
      deleteBlock={deleteBlock}
      updateBlock={updateBlock}
      updateBlockStatus={updateBlockStatus}
      navigate={navigate}
      operation={operation}
      setEndTime={setEndTime}
      isBlockLoading={isBlockLoading}
      minTreatmentTime={minTreatmentTime}
      stepsMinutes={stepsMinutes}
    />
  );
};

// FIXME: 日付と施設の変更処理(updateBlock)を実装する
const BlockForm: React.FC<BlockFormType> = ({
  setValue,
  register,
  watch,

  clinic_id,
  clinicTime,

  addBlock,
  updateBlock,
  updateBlockStatus,
  methods,
  startTimeLabel,
  facilityNameList,
  deleteBlock,
  formData,
  navigate,
  operation,
  setEndTime,
  isBlockLoading,
  minTreatmentTime,
  stepsMinutes
}) => {

  const search = useSearch()

  //FIXME: type定義
  async function _addBlock(data: any) {
    const validated = validateFormData(data.block);
    await addBlock.mutate({ clinic_id, ...validated });
  }

  // FIXME: type定義
  async function _updateBlockStatus(data: any) {
    const validated = validateFormData(data.block);
    await updateBlockStatus.mutate({ clinic_id, ...validated, isNonAccepted: null });
  }

  // FIXME: type定義
  async function _updateBlock(data: any) {
    await updateBlock.mutate(data);
  }

  // フォームのsubmit時の処理
  //FIXME: type定義
  async function onSubmit(data: any) {
    if (operation === 'add') {
      // 新規登録
      await _addBlock(data);
      // navigate({ to: `?${search['calendar-date']}`, fromCurrent: false })
      return;
    }

    // 施設or時間の移動がある場合
    if (operation === 'update') {
      await _updateBlock({ ...data.block, endTime: Number(data.block.endTime), isNonAccepted: null, baseId: data.block.id });
      navigate({ to: `?${search['calendar-date']}`, fromCurrent: false })
      return;
    }

    if (operation === 'reference') {
      // それ以外の変更
      console.log('reference', data.block)
      _updateBlockStatus(data);
      return
    }
  }

  const { getValues, handleSubmit } = methods;
  const formLabel = formData.id ? 'ブロック更新' : 'ブロック登録';

  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  return (
    <div className="flex flex-col min-h-0">
      <BlockFormOutline handleSubmit={handleSubmit} onSubmit={onSubmit}>
        <BlockDescription
          facilityNameList={facilityNameList}
          getValues={getValues}
          formLabel={formLabel}
        />
        <BlockDate
          startTimeLabel={startTimeLabel}
          formData={formData}
          clinicTime={clinicTime}
          methods={methods}
          setEndTime={setEndTime}
          minTreatmentTime={minTreatmentTime}
          stepsMinutes={stepsMinutes}
        />

        <BlockColor setValue={setValue} watch={watch} />
        <BlockComment register={register} getValues={getValues} />
        <BlockFormButtons
          isBlockLoading={isBlockLoading}
        >
          {operation === 'add' ? (
            <BlockFormRegisterButton />

          ) : (
            <EditModePanel
              setIsOpenDeleteModal={setIsOpenDeleteModal}
              navigate={navigate}
            />
          )}
        </BlockFormButtons>
      </BlockFormOutline >
      {/* 予約削除確認ダイアログ */}
      {isOpenDeleteModal &&
        <>
          <DeleteConfirmationDialog
            label={'ブロック'}
            isChecked={isChecked}
            setIsChecked={() => setIsChecked(!isChecked)}
            onClose={() => setIsOpenDeleteModal(false)}
            onDelete={() => {
              deleteBlock.mutate({ id: formData.id, startTime: formData.startTime });
              navigate({ to: `/main/?${search}` })
              setIsOpenDeleteModal(false)
            }}
          />
        </>
      }
    </div>
  );
};

const EditModePanel = ({
  setIsOpenDeleteModal,
  navigate
}: //FIXME: type定義
  {
    setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    navigate: any;
  }) => {
  const location = useLocation();
  const search = useSearch()
  const href = location.current.href;

  return (
    <>
      <BlockFormUpdateButton />
      {/* FIXME: editに飛ぶとmain/mainで予約登録に飛ぶのでフラグがつくのでblockに戻ってくるようにする */}
      <BlockFormChangeDateButton
        location={location}
        navigate={navigate}
      />
      {/* NOTE 230609 確認なしでいきなり消える 削除のあと画面が遷移しない */}
      <BlockFormDeleteButton setIsOpenDeleteModal={setIsOpenDeleteModal} />

    </>
  );
};

const BlockFormOutline: React.FC<BlockFormOutlineType> = ({ handleSubmit, onSubmit, children }) => {
  const outlineStyle = "h-full overflow-scroll"
  const formStyle = "w-full px-4 py-2 w-full h-full flex flex-col gap-y-3 justify-between"
  return (
    <div className={outlineStyle}>
      <form
        className={formStyle}
        onSubmit={handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </div>
  );
};

const BlockDescription: React.FC<BlockDescriptionType> = ({
  facilityNameList,
  getValues,
  formLabel,
}) => {
  const containerStyle = "flex w-full items-center gap-4 pb-1 pl-1 text-xl spacing-ls tracking-wider border-b border-gray-400"
  return (
    <div className={containerStyle}>
      <div className="flex items-end mb-1">
        <DomainAddOutlinedIcon className="h-3 w-3" />
        <p className="text-xs font-bold">
          {getFacilityName(facilityNameList, getValues('block.facilityId'))}
        </p>
      </div>
      <div className="flex items-end">
        <GridOffOutlinedIcon />
        <p className="text-xs">
          <span className="text-red-500 font-bold"> {formLabel} </span>
        </p>
      </div>
    </div>
  );
};

const BlockDate: React.FC<BlockDateType> = ({
  startTimeLabel,
  formData,
  clinicTime,
  methods,
  setEndTime,
  minTreatmentTime,
  stepsMinutes
}) => {
  return (
    <div className="flex">
      <div className="pl-1 text-xs">{startTimeLabel}〜</div>
      <div className={`flex justify-between items-end`}>
        {/* 日時 */}
        {formData && (
          <SelectViews
            formType="block"
            setValue={methods.setValue}
            formData={formData}
            register={methods.register}
            setEndTime={setEndTime}
            minTreatmentTime={minTreatmentTime}
            stepsMinutes={stepsMinutes}
          />
        )}
      </div>
    </div>
  );
};

const BlockColor: React.FC<BlockColorType> = ({ setValue, watch }) => {
  return (
    <>
      {/* <p className='text-sm mb-2'>ブロックカラー</p> */}
      <div className="flex gap-x-3">
        {[{ color: '#ddd', styleName: 'bg-[#ddd]' }, { color: '#ebce7a', styleName: 'bg-[#ebce7a]' }, { color: '#e08484', styleName: 'bg-[#e08484]' }].map((color) => {
          return (
            <div
              key={color.color}
              onClick={() => setValue('block.color', color.color)}
              className={`${watch('block.color') === color.color ? 'border-2 border-blue-400' : ''
                } h-4 w-10 ${color.styleName}`}
            ></div>
          );
        })}
      </div>
    </>
  );
};

const BlockComment: React.FC<BlockCommentType> = ({ register, getValues }) => {
  const textareaStyle = "bg-white text-xs p-1 w-full h-full"
  return (
    <div className="h-14 w-full box-border">
      <textarea
        {...register('block.remarks')}
        defaultValue={getValues('block.remarks')}
        placeholder="コメントを入力してください"
        className={textareaStyle}
      ></textarea>
    </div>
  );
};

const BlockFormButtons: React.FC<BlockSubmitButtonOutlineType> = ({ isBlockLoading, children }) => {
  return <div className={`${isBlockLoading ? 'pointer-events-none opacity-30' : ''} flex gap-4 justify-end`}>{children}</div>;
};

const BlockFormRegisterButton: React.FC = () => {
  const buttonStyle = "bg-indigo-700 cursor-pointer py-1 mb-2 w-20 text-white inline-flex justify-center items-center text-xs bg-blue-700 hover:bg-opacity-70 font-medium rounded-sm text-center"
  return (
    <>
      <input
        className={buttonStyle}
        type="submit"
        value={'登録'}
      />
    </>
  );
};

const BlockFormUpdateButton: React.FC = () => {
  const buttonStyle = "bg-indigo-700 cursor-pointer py-1 mb-2 w-20 text-white inline-flex justify-center items-center text-xs bg-blue-700 hover:bg-opacity-70 font-medium rounded-sm text-center"
  return (
    <>
      <input
        className={buttonStyle}
        type="submit"
        value={'更新'}
      />
    </>
  );
};

const BlockFormChangeDateButton: React.FC<BlockFormChangeDateButtonType> = ({
  location,
  navigate
}) => {
  const buttonStyle = "bg-indigo-700 cursor-pointer py-1 mb-2 w-20 text-white inline-flex justify-center items-center text-xs bg-blue-700 hover:bg-opacity-70 font-medium rounded-sm text-center"
  return (
    <p
      onClick={() => {
        navigate({ to: `add-appoint/edit${location.current.searchStr}`, fromCurrent: false });
      }}
      className={buttonStyle}
    >
      <GridOffOutlinedIcon />
      日時変更
    </p>
  );
};

const BlockFormDeleteButton: React.FC<BlockFormDeleteButtonType> = ({
  setIsOpenDeleteModal
}) => {
  const buttonStyle = "bg-red-600 cursor-pointer py-1 mb-2 w-20 text-white inline-flex justify-center items-center text-xs bg-blue-700 hover:bg-opacity-70 font-medium rounded-sm text-center"
  return (
    <p
      onClick={() => {
        setIsOpenDeleteModal(true)
      }}
      className={buttonStyle}
    >
      <GridOffOutlinedIcon />
      削除
    </p>
  );
};
