import { useContext, useRef, useState, Dispatch, SetStateAction } from "react";
import { useBlockList_service } from "@/domain";
import { AllPanesContext } from "@/components/Main/provider/PanesProvider";
import { PaneVisibilityContext, SetDateContext } from "@/components/Main/provider/MainProvider";
import { useLocation, useNavigate } from "@tanstack/react-location";
import { getEndTimeOfToDayTimestamp, getStartTimeOfToDayTimestamp } from "@/components/Common";
import { useViewportSize } from "@mantine/hooks";
import { useQueryClient } from "react-query";
import { getOrderedFacilityList } from "@/components/Facility/utils";
import { getUnixTime, startOfDay, addDays } from "date-fns";
import { useGetActiveScheduleQuery, useGetLatestClinicConfigQuery, useListFacilitiesByFilterQuery } from "@/_graphql/graphql-client";
import { useFacilityGroupContext } from "@/domain/FacilityGroup/useFacilityGroup";
import type { FacilityType } from "@/components/Facility/entities";
import { AppointPresenter } from "../presenter/AppointPresenter";
import type { AppointViewsPropsType } from "../../entities";
import { useShiftTimeObserver } from "../../hooks/useShiftTimeObserver";
import { useAppointForAppointShift_service } from "@/domain/Appointment/services/hooks/Query/useAppointList_service";
import { TimeSlotHeightProvider } from "../../contexts/TimeSlotHeightContext";
import { DisplayDaysProvider } from '../../contexts/DisplayDaysContext';
import { useDisplayDays } from '../../hooks/useDisplayDays';

export const AppointViews: React.FC<AppointViewsPropsType> = ({
    clinic,
    graphqlClient,
    search,
    pathname,
    operation,
    patientId,
    blockId,
    startTime,
    endTime,
    facilityId,
    appointId
}) => {
    // group_id
    const { group_id } = useFacilityGroupContext()

    // ペイン開閉状況ステート
    const { setIsPaneOpen } = useContext(PaneVisibilityContext)
    //シフト表示設定
    const [isAllTime, setIsAllTime] = useState<boolean>(false)
    const [facilitiesFilter, setFacilitiesFilter] = useState<string>('all')
    const viewSizes = useViewportSize();

    const { nowDate, setNowDate } = useContext(SetDateContext)
    const LatestClinicTime = useGetLatestClinicConfigQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)), config_type: 'clinic_time' }, { retry: 0 })

    //診療時間とシフト枠数を管理
    const { shiftTime, shiftFrameCount } = useShiftTimeObserver(LatestClinicTime, nowDate, isAllTime)

    //施設一覧
    const listFacilitiesByFilter = useListFacilitiesByFilterQuery(graphqlClient, { inputs: { clinic_id: clinic.clinic_id, group_id: group_id || '', filterType: facilitiesFilter } })
    const allFacilityList = listFacilitiesByFilter.data?.listFacilitiesByFilter.Facilities || []
    const activeFacilityList = allFacilityList?.filter((facility: any) => !facility?.isDelete)
    const orderedFacilityList = getOrderedFacilityList(activeFacilityList as FacilityType[])

    const schedule = useGetActiveScheduleQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)) })
    const isHoliday = schedule.data?.getActiveSchedule?.isHoliday
    const isBeforeDay = schedule.data?.getActiveSchedule?.isBeforeDay

    //context - Allotmentコンポーネントの参照
    const { subPanesRef } = useContext(AllPanesContext)
    // ページ遷移関数
    const navigate = useNavigate();

    //FIXME: HOCからpropsで渡す
    const location = useLocation();
    const searchStr = location.current.searchStr

    const queryClient = useQueryClient();

    // FIXME: 印刷のためのref暫定実装
    const printRef = useRef();

    return (
        <TimeSlotHeightProvider>
            <DisplayDaysProvider>
                <AppointViewsContent
                    clinic={clinic}
                    graphqlClient={graphqlClient}
                    nowDate={nowDate}
                    isHoliday={isHoliday}
                    isBeforeDay={isBeforeDay}
                    subPanesRef={subPanesRef}
                    printRef={printRef}
                    setNowDate={setNowDate}
                    isAllTime={isAllTime}
                    setIsAllTime={setIsAllTime}
                    setFacilitiesFilter={setFacilitiesFilter}
                    shiftFrameCount={shiftFrameCount}
                    queryClient={queryClient}
                    search={search}
                    pathname={pathname}
                    operation={operation}
                    shiftTime={shiftTime}
                    patientId={patientId}
                    blockId={blockId}
                    startTime={startTime}
                    endTime={endTime}
                    facilityId={facilityId}
                    appointId={appointId}
                    viewSizes={viewSizes}
                    navigate={navigate}
                    searchStr={searchStr}
                    orderedFacilityList={orderedFacilityList}
                    setIsPaneOpen={setIsPaneOpen}
                    clinicTime={clinic.clinic_time}
                    intervalMinute={15}
                    shiftStartHours={shiftTime.startTime.hours}
                    shiftEndHours={shiftTime.endTime.hours}
                    shiftStartMinutes={shiftTime.startTime.minutes}
                    shiftEndMinutes={shiftTime.endTime.minutes}
                />
            </DisplayDaysProvider>
        </TimeSlotHeightProvider>
    )
}

interface AppointViewsContentProps extends Omit<AppointViewsPropsType, 'clinic'> {
    clinic: {
        clinic_id: string;
        clinic_time: any;
    };
    graphqlClient: any;
    nowDate: Date;
    isHoliday: boolean | null | undefined;
    isBeforeDay: boolean | null | undefined;
    subPanesRef: any;
    printRef: any;
    setNowDate: Dispatch<SetStateAction<Date>>;
    isAllTime: boolean;
    setIsAllTime: Dispatch<SetStateAction<boolean>>;
    setFacilitiesFilter: Dispatch<SetStateAction<string>>;
    shiftFrameCount: any;
    queryClient: any;
    viewSizes: any;
    navigate: any;
    searchStr: string;
    orderedFacilityList: FacilityType[];
    setIsPaneOpen: Dispatch<SetStateAction<boolean>> | null;
    intervalMinute: number;
    shiftStartHours: number;
    shiftEndHours: number;
    shiftStartMinutes: number;
    shiftEndMinutes: number;
    shiftTime: {
        startTime: { hours: number; minutes: number };
        endTime: { hours: number; minutes: number };
    };
    clinicTime?: any;
}

// 内部コンポーネントとして切り出し
const AppointViewsContent: React.FC<AppointViewsContentProps> = ({
    clinic,
    graphqlClient,
    nowDate,
    ...props
}) => {
    const { displayDays } = useDisplayDays();
    const displayDaysNum = parseInt(displayDays) - 1;

    // 表示日数分の予約情報を取得
    const appoints = useAppointForAppointShift_service(graphqlClient, {
        clinic_id: clinic.clinic_id,
        date: {
            startTime: getStartTimeOfToDayTimestamp(nowDate),
            endTime: getEndTimeOfToDayTimestamp(addDays(nowDate, displayDaysNum))
        }
    });
    const allReservationList = appoints.appointList || [];
    const { isRefetching } = appoints.req;

    // ブロック情報も表示日数分取得
    const allBlockList = useBlockList_service(graphqlClient, {
        clinic_id: clinic.clinic_id,
        startTime: getStartTimeOfToDayTimestamp(nowDate),
        endTime: getEndTimeOfToDayTimestamp(addDays(nowDate, displayDaysNum))
    }) || [];

    return (
        <AppointPresenter
            {...props}
            clinic={clinic}
            allReservationList={allReservationList}
            allBlockList={allBlockList}
            isRefetching={isRefetching}
            nowDate={nowDate}
        />
    );
};