import React, { useState } from 'react'
import { weeks } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { add, format, sub } from 'date-fns'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

interface TabBarType {
  tabTitles: string[]
  arrangement: boolean
  setTabName: (tabTitle: string) => void
  nowDate?: Date | null
  setNowDate?: React.Dispatch<React.SetStateAction<Date>> | null
  matches?: boolean | null
}

const TabList: React.FC<{
  matches?: boolean | null
  tabTitles: string[]
  actionTab: string
  setTab: (tab: string) => void
  setTabName: (tab: string) => void
  actionTabClass: string
  tabClass: string
}> = ({ matches, tabTitles, actionTab, setTab, setTabName, actionTabClass, tabClass }) => (
  <>
    {tabTitles.map((tabTitle: string) => (
      <li className="mr-2" key={tabTitle.toString()}>
        <div
          onClick={() => {
            setTab(tabTitle)
            setTabName(tabTitle)
          }}
          className={actionTab === tabTitle ? actionTabClass : tabClass}
        >
          {tabTitle}
        </div>
      </li>
    ))}
  </>
);

const DateDisplay: React.FC<{
  matches?: boolean | null
  nowDate?: Date | null
}> = ({ matches, nowDate }) => {
  if (matches !== null && matches !== undefined && !matches && nowDate !== null && nowDate !== undefined) {
    return (
      <div className="font-semibold flex items-center pl-5">
        {`${new Date(nowDate).getMonth() + 1}月${new Date(nowDate).getDate()}日(${weeks[nowDate.getDay()]}) ${nowDate.getFullYear()}`}
      </div>
    );
  }
  return null;
};

const DateInput: React.FC<{
  nowDate: Date
  setNowDate: React.Dispatch<React.SetStateAction<Date>>
}> = ({ nowDate, setNowDate }) => (
  <input 
    type="date" 
    value={format(nowDate, 'yyyy-MM-dd')} 
    onChange={(e) => { setNowDate(new Date(e.currentTarget.value)); }} 
  />
);

const NavigationButtons: React.FC<{
  nowDate: Date
  setNowDate: React.Dispatch<React.SetStateAction<Date>>
}> = ({ nowDate, setNowDate }) => (
  <span className="isolate inline-flex rounded-md shadow-sm">
    <button
      type="button"
      onClick={() => { moveDay('prev', nowDate, setNowDate) }}
      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
    >
      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
    </button>
    <button
      type="button"
      onClick={() => { moveDay('next', nowDate, setNowDate) }}
      className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
    >
      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  </span>
);

const DatePicker: React.FC<{
  matches?: boolean | null
  setNowDate?: React.Dispatch<React.SetStateAction<Date>> | null
  nowDate?: Date | null
}> = ({ matches, setNowDate, nowDate }) => {
  if (matches !== null && matches !== undefined && matches && 
      setNowDate !== null && setNowDate !== undefined && 
      nowDate !== null && nowDate !== undefined) {
    return (
      <div className="flex gap-4">
        <DateInput nowDate={nowDate} setNowDate={setNowDate} />
        <NavigationButtons nowDate={nowDate} setNowDate={setNowDate} />
      </div>
    );
  }
  return null;
};

const TabContent: React.FC<{
  matches?: boolean | null
  tabTitles: string[]
  actionTab: string
  setTab: (tab: string) => void
  setTabName: (tabTitle: string) => void
  actionTabClass: string
  tabClass: string
  nowDate?: Date | null
  setNowDate?: React.Dispatch<React.SetStateAction<Date>> | null
}> = (props) => (
  <li className="flex">
    <TabList {...props} />
    <DateDisplay matches={props.matches} nowDate={props.nowDate} />
    <DatePicker matches={props.matches} setNowDate={props.setNowDate} nowDate={props.nowDate} />
  </li>
);

export const BasicTabBar: React.FC<TabBarType> = ({
  tabTitles,
  arrangement,
  setTabName,
  nowDate,
  setNowDate,
  matches
}) => {
  const [actionTab, setTab] = useState(tabTitles[0])
  const TabArrangement = arrangement ? ' justify-center' : ' justify-start'
  const actionTabClass = 'inline-block p-2 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
  const tabClass = 'inline-block p-2 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'

  return (
    <ul className={'flex flex-wrap items-center justify-between text-sm font-medium text-center box-border text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400' + TabArrangement}>
      <TabContent
        matches={matches}
        tabTitles={tabTitles}
        actionTab={actionTab}
        setTab={setTab}
        setTabName={setTabName}
        actionTabClass={actionTabClass}
        tabClass={tabClass}
        nowDate={nowDate}
        setNowDate={setNowDate}
      />
    </ul>
  );
};

function moveDay (target: 'next' | 'prev', nowDate: Date, setNowDate: React.Dispatch<React.SetStateAction<Date>>): void {
  let moveDate = nowDate;
  switch (target) {
    case 'next':
      moveDate = add(nowDate, { days: 1 })
      break;
    case 'prev':
      moveDate = sub(nowDate, { days: 1 })
      break;
  }
  setNowDate(moveDate)
}