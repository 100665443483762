import { SelectViews } from '@/components/Common/components/parts/Select/views/SelectTimeForBlockViews';
import { MedicalCategoryModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/MedicalCategoryModal';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useContext, useState, useCallback } from 'react';
import { AppointStatusBudgePresenter } from '@/components/Appointment/components/AppointmentListByPatients/presenter/AppointStatusBudgePresenter';
import { fromUnixTime, getUnixTime, startOfDay } from 'date-fns';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import {
  RecallStatus,
  useGetActiveScheduleQuery,
  useGetLatestClinicConfigQuery,
  useListRecallItemsByClinicPatientAndMenuQuery
} from '@/_graphql/graphql-client';
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData';
import { MutateContext } from '@/provider/common/MutateProvider';

import { sortAndExtractIds } from '../utils/util';
import { japanTimeFormatDate } from '@/components/Common/utils';

import { useUpdateRecallStatus } from '@/components/Recall/hooks/useUpdateRecallStatus';

import {
  _styledDivTop,
  _styledDivRightTop,
  _styledDivMiddle,
  _styledDivBottom,
  _styledDivLeftBottom,
  $AppointDetailLabel,
  $FacilityName,
  $ConsultationStartTime,
  CheckIsLate,
  DeleteButton,
  DateChangeButton,
  InputRemarks,
  SelectMenuLabel,
  StaffSelector,
  SubmitButton,
} from '@/components/Reservation/components/parts/AppointDetail';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { isWithoutEndTime } from '../utils/AddAppoint';
import { SelectDentalFormulaLabel } from '../../parts/AppointDetail/common/SelectDentalFormulaLabel';
import { DentalFormulaModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/DentalFormulaModal';

import type { UpdateAppointType } from './schema';
import { SelectPatient } from './SelectPatient';
import { Badge, Button } from '@mantine/core';

const isClinicTimeConfig = (config: any): config is { __typename: "ClinicTimeConfig", clinicTime?: { endTime: any, startTime: any } | null } => {
  return config?.__typename === "ClinicTimeConfig";
};

const TopSection: React.FC<{
  facilityList: any[]
  watch: any
  operation: string
  formData: any
  patientId: string
  startTime: number
  changeAppointStatus: any
  selectedCancelReason: number
  setSelectedCancelReason: any
  clinicId: string
}> = ({ facilityList, watch, operation, formData, patientId, startTime, changeAppointStatus, selectedCancelReason, setSelectedCancelReason, clinicId }) => (
  <_styledDivTop>
    <$FacilityName facilityList={facilityList} facilityId={watch('appointData.facilityId')} />
    <$AppointDetailLabel label="予約参照" />
    <_styledDivRightTop>
      <div className="flex flex-row gap-2">
      <CheckIsLate
        checked={watch('appointData.isLate')}
        operation={operation as 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined}
        appointStatus={formData.appoint}
        onCheckboxChange={(checked) => {
          changeAppointStatus.mutate({
            id: patientId,
            startTime,
            isLate: checked,
            appoint: formData.appoint,
          });
        }}
      />
      {
        (formData.isPeriodicCheckup as boolean) && (
          <PeriodicCheckupStatus
            clinicId={clinicId}
            patientId={patientId}
            menuId={formData.menuId}
            isPeriodicCheckup={formData.isPeriodicCheckup}
          />
        )
      }
      </div>

      {operation === 'reference' && (
        <AppointStatusBudgePresenter
          id={formData.id}
          selectedCancelReason={selectedCancelReason}
          setSelectedCancelReason={setSelectedCancelReason}
          startTime={formData.startTime}
          appoint={formData.appoint}
          isLate={formData.isLate}
        />
      )}
    </_styledDivRightTop>
  </_styledDivTop>
);

const MiddleSection: React.FC<{
  watch: any
  formData: any
  setValue: any
  register: any
  setEndTime: any
  minTreatmentTime: number
  stepsMinutes: number
  isBeforeDay: boolean
  operation: string
  navigate: any
  setIsOpenDeleteModal: () => void
}> = ({ watch, formData, setValue, register, setEndTime, minTreatmentTime, stepsMinutes, isBeforeDay, operation, navigate, setIsOpenDeleteModal }) => (
  <_styledDivMiddle>
    <$ConsultationStartTime
      appointStartTime={japanTimeFormatDate(fromUnixTime(watch('appointData.startTime')))}
    />
    <SelectViews
      formType="appoint"
      formData={formData}
      setValue={setValue}
      register={register}
      setEndTime={setEndTime}
      minTreatmentTime={minTreatmentTime}
      stepsMinutes={stepsMinutes}
    />
    {!isBeforeDay && (
      <>
        <DateChangeButton
          disable={operation === 'edit'}
          onDateChange={() => {
            navigate({
              to: `appoints-list/appoint-info/edit${location.search.toString()}`,
              fromCurrent: false,
            });
          }}
        />
        <DeleteButton
          disable={operation === 'edit' || operation === 'update'}
          openDeleteConfirmDialog={() => {
            if (operation !== 'edit' && operation !== 'update') {
              setIsOpenDeleteModal();
            }
          }}
        />
      </>
    )}
  </_styledDivMiddle>
);

const PatientInfo: React.FC<{
  patientId: string
  displayPatientName: string
  watch: any
  setOpenSearchPatientModal: any
  openSearchPatientModal: boolean
  setOpenAddPatientModal: any
  menuListIncludeDelete: any[]
  setOpenMedicalCategoryModal: any
  openMedicalCategoryModal: boolean
  formData: any
  categoryList: any[]
  menuList: any[]
  setOpenDentalFormulaModal: any
  openDentalFormulaModal: boolean
  setValue: any
  register: any
}> = (props) => (
  <_styledDivLeftBottom>
    <SelectPatient
      disabled={!props.patientId.startsWith('UN-')}
      displayPatientName={props.displayPatientName}
      watch={props.watch}
      setOpenSearchPatientModal={props.setOpenSearchPatientModal}
      openSearchPatientModal={props.openSearchPatientModal}
      setOpenAddPatientModal={props.setOpenAddPatientModal}
    />
    <>
      <SelectMenuLabel
        allMenuList={props.menuListIncludeDelete}
        menuId={props.watch('appointData.menuId')}
        openSearchMenuModal={() => props.setOpenMedicalCategoryModal(true)}
      />
      {props.openMedicalCategoryModal && props.formData?.startTime != null && (
        <MedicalCategoryModal
          currentShiftTimestamp={props.formData.startTime}
          categoryList={props.categoryList}
          MenuList={props.menuList}
          setOpenMedicalCategoryModal={props.setOpenMedicalCategoryModal}
        />
      )}
    </>
    <SelectDentalFormulaLabel
      openSearchMenuModal={() => props.setOpenDentalFormulaModal(true)}
      dentalFormula={props.formData.dentalFormula}
    />
    {props.openDentalFormulaModal && (
      <DentalFormulaModal
        dentalFormula={props.formData.dentalFormula}
        setOpenDentalFormulaModal={props.setOpenDentalFormulaModal}
        setValue={props.setValue}
        setDentalFormula={() => {}}
      />
    )}
    <InputRemarks registerProps={props.register('appointData.remarks')} />
  </_styledDivLeftBottom>
);

const StaffInfo: React.FC<{
  register: any
  manegerListsIncludeDelete: any[]
  watch: any
  withoutEndTime: boolean
}> = ({ register, manegerListsIncludeDelete, watch, withoutEndTime }) => (
  <_styledDivLeftBottom>
    <StaffSelector
      register={register}
      manegerListIncludeDelete={manegerListsIncludeDelete}
      selectedStaffIds={sortAndExtractIds(watch('appointData.staffIds'))}
    />
    <SubmitButton disabled={withoutEndTime} label="更新" />
  </_styledDivLeftBottom>
);

const PeriodicCheckupStatus: React.FC<{
  clinicId: string;
  patientId: string;
  menuId: string;
  isPeriodicCheckup: boolean;
}> = ({ clinicId, patientId, menuId, isPeriodicCheckup }) => {
  console.log('clinicId', clinicId);
  console.log('patientId', patientId);
  console.log('menuId', menuId);
  console.log('isPeriodicCheckup', isPeriodicCheckup);

  const graphqlClient = useGraphqlClient();
  const { data } = useListRecallItemsByClinicPatientAndMenuQuery(graphqlClient, {
    clinic_id: clinicId,
    patientId,
    medicalMenuId: menuId,
  });

  console.log('data', data);

  const latestRecall = data?.listRecallItems?.[0];
  const isRecallCompleted = (
    recall: { status: RecallStatus } | null | undefined
  ): recall is { status: RecallStatus } => {
    return recall !== null && recall !== undefined && typeof recall.status === 'string';
  };

  const isCompleted = latestRecall !== null && latestRecall !== undefined 
    ? latestRecall.status === RecallStatus.Completed 
    : false;

  const updateRecallStatus = useUpdateRecallStatus();

  const handleComplete = useCallback(() => {
    if (latestRecall) {
      updateRecallStatus.mutate({
        id: latestRecall.id,
        status: RecallStatus.Completed,
        clinic_id: clinicId,
      });
    }
  }, [latestRecall, clinicId, updateRecallStatus.mutate]);

  return latestRecall !== null && latestRecall !== undefined ? (
    isCompleted ? (
      <Badge className="px-1 py-1 text-xs bg-green-100 text-green-800 rounded-md">
        定期診療 終了
      </Badge>
    ) : (
    <Badge
      onClick={handleComplete}
      className="text-xs bg-blue-100 text-blue-800 rounded-md hover:bg-blue-200"
    >
      定期診療を終了する
    </Badge>
  ) 
  ) : null;
};

interface BottomSectionProps {
  patientId: string
  displayPatientName: string
  watch: any
  setOpenSearchPatientModal: any
  openSearchPatientModal: boolean
  setOpenAddPatientModal: any
  menuListIncludeDelete: any[]
  setOpenMedicalCategoryModal: any
  openMedicalCategoryModal: boolean
  formData: any
  categoryList: any[]
  menuList: any[]
  setOpenDentalFormulaModal: any
  openDentalFormulaModal: boolean
  setValue: any
  register: any
  manegerListsIncludeDelete: any[]
  withoutEndTime: boolean
}

const BottomSection: React.FC<BottomSectionProps> = (props) => (
  <_styledDivBottom>
    <PatientInfo {...props} />
    <StaffInfo
      register={props.register}
      manegerListsIncludeDelete={props.manegerListsIncludeDelete}
      watch={props.watch}
      withoutEndTime={props.withoutEndTime}
    />
  </_styledDivBottom>
);

const useUpdateAppointLogic = (props: UpdateAppointType) => {
  const [openDentalFormulaModal, setOpenDentalFormulaModal] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState(0);
  const clinic = useContext(ClinicContext);
  const { setValue } = props.methods;
  const { nowDate } = useContext(SetDateContext);
  const { changeAppointStatus } = useContext(MutateContext);

  const graphqlClient = useGraphqlClient();
  const activeClinicTimeConfig = useGetLatestClinicConfigQuery(graphqlClient, {
    clinic_id: clinic.clinic_id,
    current_date: getUnixTime(startOfDay(nowDate)),
    config_type: 'clinic_time',
  });

  const configData = activeClinicTimeConfig.data?.getLatestClinicConfig?.config ?? null;
  const activeClinicTime = configData !== null && isClinicTimeConfig(configData) ? configData.clinicTime : null;
  const clinicTime = activeClinicTime ?? emptyClinicTimeConfig.config.clinicTime;
  const withoutEndTime = isWithoutEndTime(clinicTime, props.endTime, getUnixTime(nowDate));

  const { setEndTime } = useCommonParams();

  const schedule = useGetActiveScheduleQuery(graphqlClient, {
    clinic_id: clinic.clinic_id,
    current_date: props.startTime,
  });
  const isBeforeDay = schedule.data?.getActiveSchedule?.isBeforeDay ?? false;

  return {
    openDentalFormulaModal,
    setOpenDentalFormulaModal,
    selectedCancelReason,
    setSelectedCancelReason,
    setValue,
    changeAppointStatus,
    withoutEndTime,
    setEndTime,
    isBeforeDay
  };
};

const prepareProps = (props: UpdateAppointType, logic: ReturnType<typeof useUpdateAppointLogic>) => {
  const clinic = useContext(ClinicContext);
  const topProps = {
    facilityList: props.facilityList,
    watch: props.watch,
    operation: props.operation ?? '',
    formData: props.formData,
    patientId: props.patientId,
    startTime: props.startTime,
    changeAppointStatus: logic.changeAppointStatus,
    selectedCancelReason: logic.selectedCancelReason,
    setSelectedCancelReason: logic.setSelectedCancelReason,
    clinicId: clinic.clinic_id
  };

  const middleProps = {
    watch: props.watch,
    formData: props.formData,
    setValue: logic.setValue,
    register: props.register,
    setEndTime: logic.setEndTime,
    minTreatmentTime: props.minTreatmentTime,
    stepsMinutes: props.stepsMinutes,
    isBeforeDay: logic.isBeforeDay,
    operation: props.operation ?? '',
    navigate: props.navigate,
    setIsOpenDeleteModal: props.setIsOpenDeleteModal
  };

  const bottomProps = {
    patientId: props.patientId,
    displayPatientName: props.displayPatientName,
    watch: props.watch,
    setOpenSearchPatientModal: props.setOpenSearchPatientModal,
    openSearchPatientModal: props.openSearchPatientModal,
    setOpenAddPatientModal: props.setOpenAddPatientModal,
    menuListIncludeDelete: props.menuListIncludeDelete,
    setOpenMedicalCategoryModal: props.setOpenMedicalCategoryModal,
    openMedicalCategoryModal: props.openMedicalCategoryModal,
    formData: props.formData,
    categoryList: props.categoryList,
    menuList: props.menuList,
    setOpenDentalFormulaModal: logic.setOpenDentalFormulaModal,
    openDentalFormulaModal: logic.openDentalFormulaModal,
    setValue: logic.setValue,
    register: props.register,
    manegerListsIncludeDelete: props.manegerListsIncludeDelete,
    withoutEndTime: logic.withoutEndTime
  };

  return { topProps, middleProps, bottomProps };
};

export const UpdateAppoint: React.FC<UpdateAppointType> = (props) => {
  const logic = useUpdateAppointLogic(props);
  const { topProps, middleProps, bottomProps } = prepareProps(props, logic);

  return (
    <div className="flex flex-col h-full min-h-0">
        <TopSection {...topProps} />
        <MiddleSection {...middleProps} />
        <BottomSection {...bottomProps} />
    </div>
  );
};
