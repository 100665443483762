import React from 'react';
import type { RecallStatus } from '@/_graphql/graphql-client';

interface RecallStatusBadgeProps {
  status: string; // APIから返される大文字のステータスを受け入れる
}

const statusColors: Record<RecallStatus, string> = {
  PENDING: 'bg-yellow-100 text-yellow-800',
  SCHEDULED: 'bg-blue-100 text-blue-800',
  COMPLETED: 'bg-green-100 text-green-800',
  CANCELLED: 'bg-red-100 text-red-800',
};

const statusLabels: Record<RecallStatus, string> = {
  PENDING: '未対応',
  SCHEDULED: '予約済み',
  COMPLETED: '終了',
  CANCELLED: '無視',
};

export const RecallStatusBadge: React.FC<RecallStatusBadgeProps> = ({ status }) => {
  const normalizedStatus = status.toUpperCase() as RecallStatus;
  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${statusColors[normalizedStatus]}`}>
      {statusLabels[normalizedStatus]}
    </span>
  );
}; 