import { createContext, useContext, useState, type ReactNode } from 'react';

interface DisplayDaysContextType {
  displayDays: string;
  setDisplayDays: (days: string) => void;
}

const DisplayDaysContext = createContext<DisplayDaysContextType | undefined>(undefined);

export const DisplayDaysProvider = ({ children }: { children: ReactNode }) => {
  const [displayDays, setDisplayDays] = useState<string>("1");

  return (
    <DisplayDaysContext.Provider value={{ displayDays, setDisplayDays }}>
      {children}
    </DisplayDaysContext.Provider>
  );
};

export const useDisplayDaysContext = () => {
  const context = useContext(DisplayDaysContext);
  if (context === undefined) {
    throw new Error('useDisplayDaysContext must be used within a DisplayDaysProvider');
  }
  return context;
}; 