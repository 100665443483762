import { uuid4 } from '@sentry/utils';
import { type AppointCardPresenterType } from '../../entities';
import { getStatusBadgeStyle } from '../../utils';
import { ToothFormula, cleanTeethData, formatBabyDentalSequence, formatDentalSequence } from '../parts/AppointDetail/common/SelectDentalFormulaLabel';
import { useTimeSlotHeight } from '../../contexts/TimeSlotHeightContext';

const getCardStyles = (
  isSelected: boolean,
  startTime: number,
  reservationCardHigh: number,
  staffColor: string | null | undefined,
  isActive: boolean
): React.CSSProperties => {
  const { timeSlotHeight } = useTimeSlotHeight();
  return {
    border: isSelected ? '3px solid #000000' : 'none',
    top: `${startTime * timeSlotHeight / 100}px`,
    height: `${reservationCardHigh * timeSlotHeight / 100}px`,
    backgroundColor: staffColor ?? 'rgb(100,116,139)',
    boxShadow: isSelected
      ? '0 10px 15px -3px rgba(0, 0, 0, 0.4) inset'
      : '0 6px 10px -3px rgba(0, 0, 0, 0.4)',
    width: '99%',
    ...(!isActive && { zIndex: 100 }),
  };
};

interface CardContentProps {
  reservation: any;
  _tooth: React.ReactNode[];
}

const CardContent: React.FC<CardContentProps> = ({ reservation, _tooth }) => (
  <div className="break-words">
    <p className="flex gap-1 items-center font-bold text-sm">
      <StatusBadge status={reservation.appoint} />
      {`${reservation?.patientCardNumber ?? '未登録'}`}
    </p>
    <p className="font-bold text-xs">{`${reservation.patientName}`}</p>
    {`${reservation.menuAbbreviation} /`}
    <span className="text-white">{_tooth}</span> /
    {`${reservation.staffNames[0].name} /
      ${reservation.staffNames[1].name} /
      ${reservation.staffNames[2].name} /
      ${reservation.remarks === null ? '※' : '※' + reservation.remarks}`}
  </div>
);

export const AppointCardPresenter: React.FC<AppointCardPresenterType> = ({
  oparation,
  appointId,
  reservation,
  reservationCardHigh,
  startTime,
  endTime,
  onClickHandler,
}) => {
  console.log(reservation.dentalFormula);

  const { dentalFormula } = reservation;

  const isEditClass = oparation === 'edit' && appointId === reservation.appointId ? 'opacity-50 pointer-events-none' : '';
  const hasStaffClass = reservation.staffNames[0].name ? '' : 'text-white';
  const isSelected = appointId === reservation.appointId;
  const isActive = endTime !== reservation.endTime;

  const _tooth = [
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.rightTop ?? [])), "rightTop", 'white', 'white'),
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.leftTop ?? [])), "leftTop", 'white', 'white'),
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.rightBottom ?? [])), "rightBottom", 'white', 'white'),
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.leftBottom ?? [])), "leftBottom", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.rightTop ?? [])), "rightTop", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.leftTop ?? [])), "leftTop", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.rightBottom ?? [])), "rightBottom", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.leftBottom ?? [])), "leftBottom", 'white', 'white'),
  ];

  const styles = getCardStyles(
    isSelected,
    startTime,
    reservationCardHigh,
    reservation.staffNames[0].color,
    isActive
  );

  return (
    <div
      key={uuid4()}
      className={`absolute w-full p-1 overflow-hidden text-xs ${isEditClass} ${hasStaffClass}`}
      style={styles}
      onClick={onClickHandler}
    >
      <CardContent reservation={reservation} _tooth={_tooth} />
    </div>
  );
};

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const { text, color } = getStatusBadgeStyle(status);
  return (
    <span className={`text-xs h-6 w-6 flex justify-center items-center border ${color}`}>
      {text}
    </span>
  );
};

