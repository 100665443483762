import React, { useContext, useState } from 'react'
import { getCategoryList, getMenuList } from '@/feature/phr-28/components/common/Api'
import { type MedicalMenuViewsType, type MenuType, type CategoryType, type DeleteMenuDialogType } from '../util/common/type'
import { useDeleteMenusDialog } from '@/feature/phr-28/components/template/medicalCareRegistration/useDeleteMenusDialog'
import { ClinicContext, useGraphqlClient } from '@/App'
import { MedicalMenuPresenter } from '../presenter/MedicalMenuPresenter'
import { getLastIndexNumFromMenuList, getSortedMenuList } from '../util/common/util'

const getFilteredMenuLists = (menuLists: MenuType[], filterKeyword: string): MenuType[] => {
  return menuLists.filter((card) => {
    return new RegExp(filterKeyword.toLowerCase()).test(card.name.toLowerCase())
  })
}

const useMedicalMenuViewsLogic = (): {
  DeleteMenusDialog: React.FC<DeleteMenuDialogType>
  isOpenDeleteDialog: boolean
  setIsOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  filterKeyword: string
  setFilterKeyword: React.Dispatch<React.SetStateAction<string>>
  sortingFactorNumber: number
  setSortingFactorNumber: React.Dispatch<React.SetStateAction<number>>
  isSort: boolean
  setIsSort: React.Dispatch<React.SetStateAction<boolean>>
  order: string
  setOrder: React.Dispatch<React.SetStateAction<string>>
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  bulkDeleteArr: number[]
  setBulkDeleteArr: React.Dispatch<React.SetStateAction<number[]>>
  lastIndexNum: number
  categoryList: CategoryType[]
  filteredLists: MenuType[]
  tabName: string
  setTabName: React.Dispatch<React.SetStateAction<string>>
} => {
  const graphqlClient = useGraphqlClient()
  const clinicId = useContext(ClinicContext).clinic_id

  const { DeleteMenusDialog } = useDeleteMenusDialog()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [filterKeyword, setFilterKeyword] = useState('')
  const [sortingFactorNumber, setSortingFactorNumber] = useState(0)
  const [isSort, setIsSort] = useState(false)
  const [order, setOrder] = useState('DESC')
  const [show, setShow] = useState(false)
  const [bulkDeleteArr, setBulkDeleteArr] = useState<number[]>([])
  const [tabName, setTabName] = useState('診療メニュー登録')

  const menuLists = getSortedMenuList(getMenuList(graphqlClient, clinicId))
  const lastIndexNum = getLastIndexNumFromMenuList(menuLists)
  const categoryList = getCategoryList(graphqlClient, clinicId)
  const filteredLists = getFilteredMenuLists(menuLists, filterKeyword)

  return {
    DeleteMenusDialog,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    filterKeyword,
    setFilterKeyword,
    sortingFactorNumber,
    setSortingFactorNumber,
    isSort,
    setIsSort,
    order,
    setOrder,
    show,
    setShow,
    bulkDeleteArr,
    setBulkDeleteArr,
    lastIndexNum,
    categoryList,
    filteredLists,
    tabName,
    setTabName
  }
}

const tabTitles = ['診療メニュー登録', '診療カテゴリ登録']
const isCenter = false
const deleteBtnCss = 'leading-8 text-gray-100 text-sm font-medium h-8 w-32 text-center rounded-2xl bg-red-700 hover:bg-red-900'
const disableBtnCss = 'leading-8 text-gray-100 text-sm font-medium h-8 w-32 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'


export const MedicalMenuViews: React.FC<MedicalMenuViewsType> = (): JSX.Element => {
  const {
    DeleteMenusDialog,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    setFilterKeyword,
    sortingFactorNumber,
    setSortingFactorNumber,
    isSort,
    setIsSort,
    setOrder,
    show,
    setShow,
    bulkDeleteArr,
    setBulkDeleteArr,
    lastIndexNum,
    categoryList,
    filteredLists,
    tabName,
    setTabName
  } = useMedicalMenuViewsLogic()
  
  return (
    <MedicalMenuPresenter
      tabTitles={tabTitles}
      isCenter={isCenter}
      tabName={tabName}
      setTabName={setTabName}
      setFilterKeyword={setFilterKeyword}
      bulkDeleteArr={bulkDeleteArr}
      setBulkDeleteArr={setBulkDeleteArr}
      deleteBtnCss={deleteBtnCss}
      disableBtnCss={disableBtnCss}
      show={show}
      setShow={setShow}
      lastIndexNum={lastIndexNum}
      categoryList={categoryList}
      isOpenDeleteDialog={isOpenDeleteDialog}
      setIsOpenDeleteDialog={setIsOpenDeleteDialog}
      DeleteMenusDialog={DeleteMenusDialog}
      filteredLists={filteredLists}
      sortingFactorNumber={sortingFactorNumber}
      setSortingFactorNumber={setSortingFactorNumber}
      isSort={isSort}
      setIsSort={setIsSort}
      setOrder={setOrder}
    />
  )
}
