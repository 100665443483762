import React from 'react'
import { MedicalMenuRecordPresenter } from '../presenter/MedicalMenuRecordPresenter'
import { type MedicalMenuRecordViewsType } from '../util/common/type'
import { useDeleteMenuDialog } from '@/feature/phr-28/components/template/medicalCareRegistration/useDeleteMenuDialog'
import { useEditMenuModal } from '@/feature/phr-28/components/template/medicalCareRegistration/useEditMenuModal'
import { useMedicalMenuRecord } from '../hooks/useMedicalMenuRecord'
import type { Dispatch, SetStateAction } from 'react'

interface MedicalMenuRecordViewsProps extends Omit<MedicalMenuRecordViewsType, 'setBulkDeleteArr'> {
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>
}

const useMedicalMenuRecordViewsLogic = ({
  record,
  categoryList,
  filteredLists,
  lastIndexNum,
  setBulkDeleteArr
}: MedicalMenuRecordViewsProps) => {
  const { EditMenuModal, setCurrentMenu } = useEditMenuModal()
  const { DeleteMenuDialog, setDeleteMenu } = useDeleteMenuDialog()
  const {
    editMode,
    setEditMode,
    isCheck,
    setIsCheck,
    openDeleteDialog,
    setOpenDeleteDialog,
    updateMenuIndexExecute,
    currentCategory,
    styles
  } = useMedicalMenuRecord({ record, categoryList, filteredLists, lastIndexNum, setBulkDeleteArr })

  return {
    EditMenuModal,
    setCurrentMenu,
    DeleteMenuDialog,
    setDeleteMenu,
    editMode,
    setEditMode,
    isCheck,
    setIsCheck,
    openDeleteDialog,
    setOpenDeleteDialog,
    updateMenuIndexExecute,
    currentCategory,
    styles
  }
}

export const MedicalMenuRecordViews: React.FC<MedicalMenuRecordViewsProps> = (props) => {
  const {
    EditMenuModal,
    setCurrentMenu,
    DeleteMenuDialog,
    setDeleteMenu,
    editMode,
    setEditMode,
    isCheck,
    setIsCheck,
    openDeleteDialog,
    setOpenDeleteDialog,
    updateMenuIndexExecute,
    currentCategory,
    styles
  } = useMedicalMenuRecordViewsLogic(props)

  const { record, bulkDeleteArr, setBulkDeleteArr, index, lastIndexNum, categoryList, filteredLists } = props

  return (
    <MedicalMenuRecordPresenter
      editMode={editMode}
      setEditMode={setEditMode}
      EditMenuModal={EditMenuModal}
      categoryList={categoryList}
      bulkDeleteArr={bulkDeleteArr}
      setBulkDeleteArr={setBulkDeleteArr}
      isCheck={isCheck}
      setIsCheck={setIsCheck}
      updateMenuIndexExecute={updateMenuIndexExecute}
      record={record}
      index={index}
      lastIndexNum={lastIndexNum}
      currentCategory={currentCategory}
      disableBtnCss={styles.disableBtnCss}
      deleteBtnCss={styles.deleteBtnCss}
      setCurrentMenu={setCurrentMenu}
      DeleteMenuDialog={DeleteMenuDialog}
      setDeleteMenu={setDeleteMenu}
      openDeleteDialog={openDeleteDialog}
      setOpenDeleteDialog={setOpenDeleteDialog}
      filteredLists={filteredLists}
    />
  )
}
