export interface CheckIsLatePropsType {
    checked: boolean
    operation: 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined
    appointStatus: '予約済み' | '受付済み' | 'キャンセル' | '診療完了'
    onCheckboxChange: (checked: boolean) => void;
}

export const CheckIsLate: React.FC<CheckIsLatePropsType> = ({
    checked,
    operation,
    appointStatus,
    onCheckboxChange
}) => {
    const checkBoxCommonStyle = "text-xs"
    const checkBoxDisabledStyle = "opacity-30 pointer-events-none"
    const checkBoxStyle = `${checkBoxCommonStyle} ${appointStatus === '予約済み' || appointStatus === 'キャンセル' || operation === 'edit' || operation === 'update' ? checkBoxDisabledStyle : ''}`

    return (
        <div className={`${checkBoxStyle} flex gap-2`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => { onCheckboxChange(e.target.checked); }}
            />
            <p className="self-start">遅刻</p>
        </div>
    )
}
