import { IconWindowMaximize, IconWindowMinimize } from '@tabler/icons-react';
import { useTimeSlotHeight } from '../../../../contexts/TimeSlotHeightContext';

export const TimeSlotHeightToggle: React.FC = () => {
  const { timeSlotHeight, toggleTimeSlotHeight } = useTimeSlotHeight();
  const Icon = timeSlotHeight === 100 ? IconWindowMinimize : IconWindowMaximize;

  return (
    <button
      onClick={toggleTimeSlotHeight}
      className="flex items-center gap-1 px-2 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded hover:bg-gray-50"
      title={`予約枠の高さを${timeSlotHeight === 100 ? '縮小' : '拡大'}する`}
    >
      <Icon size={16} />
    </button>
  );
}; 