import { ClinicContext } from "@/App"
import { useCategoryList_service, useFacilityList_service, useMenuList_service, useStaffList_service } from "@/domain";
import { useContext, useState } from "react";
import type { AddAppointViewsType } from "@/components/Reservation/entities";
import { AddAppointPresenter } from "./AddAppointPresenter";
import { emptyPatients } from "@/components/Common/utils/common/emptyData";
import { getSortedManegerList } from "@/components/Staff/components/utils/common/util";
import { getUnixTime, startOfDay } from "date-fns";
import type { MenuType as FeatureMenuType } from '@/feature/phr-28/components/common/type';
import type { MenuType as ComponentMenuType } from '@/components/Menu/entities/common/types';

const convertToFeatureMenuType = (menu: ComponentMenuType): FeatureMenuType => ({
  ...menu,
  isPeriodicCheckup: false,
  checkupFrequency: null,
  checkupIntervalDays: null
});

interface AddAppointLogicResult {
  openSearchPatientModal: boolean;
  setOpenSearchPatientModal: React.Dispatch<React.SetStateAction<boolean>>;
  openAddPatientModal: boolean;
  setOpenAddPatientModal: React.Dispatch<React.SetStateAction<boolean>>;
  newPatient: any;
  setNewPatient: React.Dispatch<React.SetStateAction<any>>;
  facilityList: any[];
  menuList: FeatureMenuType[];
  menuListIncludeDelete: FeatureMenuType[];
  categoryList: any[];
  manegerLists: any[];
  manegerListsIncludeDelete: any[];
  isBeforeDay: boolean;
}

const useAddAppointLogic = (props: {
  graphqlClient: any;
  appoint: any;
  clinic_id: string;
}): AddAppointLogicResult => {
  const [openSearchPatientModal, setOpenSearchPatientModal] = useState(false);
  const [openAddPatientModal, setOpenAddPatientModal] = useState(false);
  const [newPatient, setNewPatient] = useState(emptyPatients);

  const facilityList = useFacilityList_service(props.graphqlClient, { clinic_id: props.clinic_id }).facilityList;
  const allMenuList = useMenuList_service(props.graphqlClient, { clinic_id: props.clinic_id });
  const categoryList = useCategoryList_service(props.graphqlClient, { clinic_id: props.clinic_id }).categoryList;
  const allStaffLists = useStaffList_service(props.graphqlClient, { clinic_id: props.clinic_id });

  const menuList = allMenuList.menuList.map(convertToFeatureMenuType);
  const menuListIncludeDelete = allMenuList.menuListIncludeDelete.map(convertToFeatureMenuType);
  const manegerLists = getSortedManegerList(allStaffLists.staffList);
  const manegerListsIncludeDelete = getSortedManegerList(allStaffLists.staffListIncludeDelete);

  const isBeforeDay = (getUnixTime(startOfDay(new Date())) > props.appoint.startTime) && props.appoint.startTime !== 0;

  return {
    openSearchPatientModal,
    setOpenSearchPatientModal,
    openAddPatientModal,
    setOpenAddPatientModal,
    newPatient,
    setNewPatient,
    facilityList,
    menuList,
    menuListIncludeDelete,
    categoryList,
    manegerLists,
    manegerListsIncludeDelete,
    isBeforeDay,
  };
};

export const AddAppointViews: React.FC<AddAppointViewsType> = (props) => {
  const clinic = useContext(ClinicContext);
  const logic = useAddAppointLogic({
    graphqlClient: props.graphqlClient,
    appoint: props.appoint,
    clinic_id: clinic.clinic_id,
  });

  return (
    <AddAppointPresenter
      {...props}
      {...logic}
    />
  );
};
