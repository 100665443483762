import React from 'react';
import { useUpdateRecallStatus } from '../hooks/useUpdateRecallStatus';
import type { Recall } from '@/_graphql/graphql-client';
import { RecallStatus } from '@/_graphql/graphql-client';

interface RecallStatusButtonProps {
  recall: Recall;
  newStatus: RecallStatus;
  label: string;
}

export const RecallStatusButton: React.FC<RecallStatusButtonProps> = ({
  recall, 
  newStatus,
  label 
}) => {
  const { mutate } = useUpdateRecallStatus();

  const handleClick = () => {
    if (window.confirm('ステータスを更新しますか？')) {
      mutate({ id: recall.id, status: newStatus, clinic_id: recall.clinic_id });
    }
  };

  const statusColors: Record<RecallStatus, string> = {
    [RecallStatus.Cancelled]: 'bg-red-50 text-red-700 hover:bg-red-100',
    [RecallStatus.Completed]: 'bg-green-50 text-green-700 hover:bg-green-100',
    [RecallStatus.Scheduled]: 'bg-blue-50 text-blue-700 hover:bg-blue-100',
    [RecallStatus.Pending]: 'bg-gray-50 text-gray-700 hover:bg-gray-100',
  };

  return (
    <button
      onClick={handleClick}
      className={`px-2 py-1 text-sm rounded ${statusColors[newStatus]}`}
    >
      {label}
    </button>
  );
}; 