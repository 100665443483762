import { createContext, useContext, useState, type ReactNode } from 'react';

interface TimeSlotHeightContextType {
  timeSlotHeight: number;
  toggleTimeSlotHeight: () => void;
}

const TimeSlotHeightContext = createContext<TimeSlotHeightContextType | undefined>(undefined);

interface TimeSlotHeightProviderProps {
  children: ReactNode;
}

export const TimeSlotHeightProvider: React.FC<TimeSlotHeightProviderProps> = ({ children }) => {
  const [timeSlotHeight, setTimeSlotHeight] = useState<number>(100);

  const toggleTimeSlotHeight = () => {
    setTimeSlotHeight(prev => prev === 100 ? 50 : 100);
  };

  return (
    <TimeSlotHeightContext.Provider value={{ timeSlotHeight, toggleTimeSlotHeight }}>
      {children}
    </TimeSlotHeightContext.Provider>
  );
};

export const useTimeSlotHeight = (): TimeSlotHeightContextType => {
  const context = useContext(TimeSlotHeightContext);
  if (context === undefined) {
    throw new Error('useTimeSlotHeight must be used within a TimeSlotHeightProvider');
  }
  return context;
}; 