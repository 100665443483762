import { ErrorBoundary } from '@/components/Common/components';
import { $DateHeader } from '../extendedStyled/$DateHeader';
import { $TimeBar } from '../extendedStyled/$TimeBar';
import { $FacilityFrame } from '../extendedStyled/$FacilityFrame';
import { _AppointShiftWrapper } from '../baseStyled/_AppointShiftWrapper';
import { AppointCardPresenter } from '@/components/Reservation/components/presenter/AppointCardPresenter';
import { BlockCard, InteractionCard } from '@/components/Reservation/components/parts/AppointSchedule/AppointShift';
import { OneHourSlots } from './OneHourSlots';
import { startOfTodayTimestamp } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon';
import { getUnixTime, startOfDay, addDays, endOfDay } from 'date-fns';
import type { FacilityType } from '@/components/Facility/entities';
import type { BlockType } from '@/components/Block/entities';
import type { AppointDetails } from '@/_graphql/graphql-client';
import type { NavigateType, viewSizesType } from '@/components/Common/entities';
import { type Dispatch, type SetStateAction, useRef, useState, useEffect } from 'react';

interface WeeklyShiftPresenterProps {
    printRef: React.RefObject<HTMLDivElement>;
    facilityList: FacilityType[];
    totalDays: number;
    facilityWidth: number;
    dayWidth: number;
    shiftFrameStart: number;
    shiftFrameEnd: number;
    shiftEndMinutes: number;
    shiftStartHours: number;
    shiftStartMinutes: number;
    shiftEndHours: number;
    nowDate: Date;
    operation: "add" | "reference" | "edit" | "copy" | "update" | undefined;
    appointId: string;
    blockId: string;
    facilityId: string;
    startTime?: number;
    endTime?: number;
    reservationList: AppointDetails[];
    allBlockList: BlockType[];
    cardWidth: number;
    hourTimeStamp: number;
    quarterTimeStamp: number;
    isDateHoliday: (date: Date) => boolean;
    matches: boolean;
    setAppointParams: (date: number, id: string, startTime: number, endTime: number, facilityId: string, appointId: string) => void;
    setBlockParams: (date: number, id: string, startTime: number, endTime: number, facilityId: string) => void;
    setOpenAppointInfoForSP: (open: boolean) => void;
    setOpenBlockInfoForSP: (open: boolean) => void;
    setIsPaneOpen: Dispatch<SetStateAction<boolean>> | null;
    navigate: NavigateType;
    reset: () => void;
    setValue: any;
    watch: any;
    subPanesRef: any;
    viewSizes: viewSizesType;
    setCommonParams: any;
    resetAppointId: () => void;
    resetBlockId: () => void;
    openPane: (ref: any, position: "top" | "bottom" | "left", height: number, width: number) => void;
}

export const WeeklyShiftPresenter: React.FC<WeeklyShiftPresenterProps> = ({
    printRef,
    facilityList,
    totalDays,
    facilityWidth,
    dayWidth,
    shiftFrameStart,
    shiftFrameEnd,
    shiftEndMinutes,
    shiftStartHours,
    shiftStartMinutes,
    shiftEndHours,
    nowDate,
    operation,
    appointId,
    blockId,
    facilityId,
    startTime,
    endTime,
    reservationList,
    allBlockList,
    cardWidth,
    hourTimeStamp,
    quarterTimeStamp,
    isDateHoliday,
    matches,
    setAppointParams,
    setBlockParams,
    setOpenAppointInfoForSP,
    setOpenBlockInfoForSP,
    setIsPaneOpen,
    navigate,
    reset,
    setValue,
    watch,
    subPanesRef,
    viewSizes,
    setCommonParams,
    resetAppointId,
    resetBlockId,
    openPane,
}) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [containerOffset, setContainerOffset] = useState<number>(100);
    const previousOffsetRef = useRef<number>(100);

    useEffect(() => {
        const updateOffset = (): void => {
            const element = scrollContainerRef.current;
            if (element !== null) {
                const rect = element.getBoundingClientRect();
                const newOffset = rect.top;
                
                // 前回の値と比較して変更があった場合のみ更新
                if (Math.abs(previousOffsetRef.current - newOffset) > 1) {
                    console.log('Offset changed:', { 
                        previous: previousOffsetRef.current, 
                        new: newOffset 
                    });
                    previousOffsetRef.current = newOffset;
                    setContainerOffset(newOffset);
                }
            }
        };

        // ResizeObserverの設定
        const resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(updateOffset);
        });

        // MutationObserverの設定（DOMの変更を監視）
        const mutationObserver = new MutationObserver(() => {
            requestAnimationFrame(updateOffset);
        });

        const element = scrollContainerRef.current;
        if (element !== null) {
            // 要素自体のリサイズを監視
            resizeObserver.observe(element);
            
            // 親要素の変更も監視
            let parent = element.parentElement;
            while (parent !== null) {
                resizeObserver.observe(parent);
                parent = parent.parentElement;
            }

            // DOM変更の監視
            mutationObserver.observe(document.body, {
                childList: true,
                subtree: true,
                attributes: true,
                attributeFilter: ['style', 'class']
            });
        }

        // 初期値を設定
        updateOffset();

        // イベントリスナーの設定
        window.addEventListener('resize', updateOffset);
        window.addEventListener('scroll', updateOffset);

        return () => {
            resizeObserver.disconnect();
            mutationObserver.disconnect();
            window.removeEventListener('resize', updateOffset);
            window.removeEventListener('scroll', updateOffset);
        };
    }, []);

    // 表示日数に応じてmin-widthを計算
    const getMinWidth = (days: number): number => {
        return (dayWidth * days) + 50; // 50pxは時間バーの幅
    };

    type BlockMapType = Record<string, BlockType[]>;
    type AppointMapType = Record<string, AppointDetails[]>;

    // 予約情報を日付ごとにフィルタリング
    const getAppointmentsForDate = (date: Date, appointments: AppointDetails[]): AppointDetails[] => {
        const dayStart = getUnixTime(startOfDay(date));
        const dayEnd = getUnixTime(endOfDay(date));
        return appointments.filter(appoint => 
            appoint.startTime >= dayStart && appoint.startTime <= dayEnd
        );
    };

    // ブロック情報を日付ごとにフィルタリング
    const getBlocksForDate = (date: Date, blocks: BlockType[]): BlockType[] => {
        const dayStart = getUnixTime(startOfDay(date));
        const dayEnd = getUnixTime(endOfDay(date));
        return blocks.filter(block => 
            block.startTime >= dayStart && block.startTime <= dayEnd
        );
    };

    const handleResetAndOpenPane = (
        subPanesRef: any,
        viewSizes: viewSizesType,
        reset: () => void,
        setIsPaneOpen: Dispatch<SetStateAction<boolean>> | null,
    ): void => {
        reset();
        if (setIsPaneOpen !== null) {
            setIsPaneOpen(true);
        }
        openPane(subPanesRef, 'top', viewSizes.height, viewSizes.width);
    };


    return (
        <_AppointShiftWrapper printRef={printRef}>
            <div className="relative">
                <div 
                    ref={scrollContainerRef}
                    className="overflow-x-auto overflow-y-auto"
                    style={{ maxHeight: `calc(100vh - ${containerOffset}px)` }}
                >
                    <div className="relative" style={{ minWidth: `${getMinWidth(totalDays)}px` }}>
                        {/* 日付ヘッダー */}
                        <div className="flex flex-row sticky top-0 z-50 pl-[50px] bg-white">
                            {Array.from({ length: totalDays }).map((_, dayOffset) => (
                                <div 
                                    key={dayOffset} 
                                    style={{ width: `${dayWidth}px` }}
                                >
                                    <$DateHeader
                                        date={addDays(nowDate, dayOffset)}
                                        facilityFrameWidth={`${dayWidth}px`}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* 施設ラベル一覧 */}
                        <div className="sticky top-[40px] z-40 bg-white pl-[50px]">
                            <div className="flex flex-row">
                                {Array.from({ length: totalDays }).map((_, dayOffset) => (
                                    <div 
                                        key={dayOffset} 
                                        style={{ width: `${dayWidth}px` }}
                                    >
                                        <div className="flex flex-row w-full">
                                            {facilityList.map((facility: FacilityType) => (
                                                <div key={facility.id} className="border-r-2 border-white text-center border-b-2 h-5 flex justify-center overflow-hidden text-xs" style={{ width: `${facilityWidth}px` }}>
                                                    {facility.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex relative">
                            {/* 時間ラベル - 固定位置 */}
                            <div className="sticky left-0 z-30 bg-white">
                                <$TimeBar
                                    shiftStartTime={shiftFrameStart}
                                    shiftEndTime={shiftFrameEnd}
                                    shiftEndMinutes={shiftEndMinutes}
                                />
                            </div>

                            {/* シフト枠コンテナ */}
                            <div className='box-border p-1 relative z-10'>
                                <div className="flex flex-row flex-nowrap">
                                    {Array.from({ length: totalDays }).map((_, dayOffset) => {
                                        const currentDate = addDays(nowDate, dayOffset);
                                        const dayAppointments = getAppointmentsForDate(currentDate, reservationList);
                                        const dayBlocks = getBlocksForDate(currentDate, allBlockList);

                                        // 日付ごとの予約マップを作成
                                        const dayAppointMapByFacilityId = dayAppointments.reduce<AppointMapType>((acc, reservation) => {
                                            if (!acc[reservation.facilityId]) {
                                                acc[reservation.facilityId] = [];
                                            }
                                            acc[reservation.facilityId].push(reservation);
                                            return acc;
                                        }, {});

                                        // 日付ごとのブロックマップを作成
                                        const dayBlockMapByFacilityId = dayBlocks.reduce<BlockMapType>((acc, block) => {
                                            if (!acc[block.facilityId]) {
                                                acc[block.facilityId] = [];
                                            }
                                            acc[block.facilityId].push(block);
                                            return acc;
                                        }, {});

                                        return (
                                            <div 
                                                key={dayOffset} 
                                                style={{ width: `${dayWidth}px` }}
                                                className={`border-r border-black ${dayOffset === 0 ? 'border-l' : ''}`}
                                            >
                                                <div className="flex flex-row w-full">
                                                    {facilityList.map((facility: FacilityType) => {
                                                        const firstCardTime: number = startOfTodayTimestamp(currentDate) + hourTimeStamp * shiftStartHours;

                                                        // 予約カード
                                                        const relatedAppoints = dayAppointMapByFacilityId[facility.id] ?? [];
                                                        const appointCards = relatedAppoints.map((appoint) => {
                                                            // カードの高さをcardWidthに基づいて計算
                                                            const durationMinutes = (appoint.endTime - appoint.startTime) / 60;
                                                            const cardBoxNum = Math.ceil(durationMinutes / (cardWidth ?? 15));
                                                            const appointCardHigh = cardBoxNum * 100 - 10;

                                                            // カードの位置をcardWidthに基づいて計算
                                                            const startMinutes = (appoint.startTime - firstCardTime) / 60;
                                                            const startPosition = (startMinutes / (cardWidth ?? 15)) * 100;

                                                            return (
                                                                <ErrorBoundary key={appoint.appointId + appoint.id}>
                                                                    <AppointCardPresenter
                                                                        oparation={operation}
                                                                        appointId={appointId}
                                                                        reservation={appoint}
                                                                        reservationCardHigh={appointCardHigh}
                                                                        startTime={startPosition}
                                                                        endTime={endTime ?? 0}
                                                                        onClickHandler={() => {
                                                                            if (matches) {
                                                                                setAppointParams(getUnixTime(currentDate), appoint.id, appoint.startTime, appoint.endTime, appoint.facilityId, appoint.appointId)
                                                                                setOpenAppointInfoForSP(true);
                                                                                return;
                                                                            }
                                                                            if (operation === "edit") {
                                                                                // 編集モードの場合は、日付を更新して編集画面を開く
                                                                                const updatedStartTime = appoint.startTime - getUnixTime(startOfDay(nowDate)) + getUnixTime(startOfDay(currentDate));
                                                                                const updatedEndTime = appoint.endTime - getUnixTime(startOfDay(nowDate)) + getUnixTime(startOfDay(currentDate));
                                                                                setAppointParams(getUnixTime(currentDate), appoint.id, updatedStartTime, updatedEndTime, appoint.facilityId, appoint.appointId);
                                                                                navigate({ 
                                                                                    to: 'appoint-info',
                                                                                    search: {
                                                                                        'calendar-date': getUnixTime(currentDate),
                                                                                        'patient-id': appoint.id,
                                                                                        'start-time': updatedStartTime,
                                                                                        'end-time': updatedEndTime,
                                                                                        'facility-id': appoint.facilityId,
                                                                                        'appoint-id': appoint.appointId
                                                                                    }
                                                                                });
                                                                                return;
                                                                            }
                                                                            reset();
                                                                            setIsPaneOpen?.(true);
                                                                            openPane(subPanesRef, 'top', viewSizes.height, viewSizes.width);
                                                                            setAppointParams(getUnixTime(currentDate), appoint.id, appoint.startTime, appoint.endTime, appoint.facilityId, appoint.appointId);
                                                                            navigate({ 
                                                                                to: 'appoint-info',
                                                                                search: {
                                                                                    'calendar-date': getUnixTime(currentDate),
                                                                                    'patient-id': appoint.id,
                                                                                    'start-time': appoint.startTime,
                                                                                    'end-time': appoint.endTime,
                                                                                    'facility-id': appoint.facilityId,
                                                                                    'appoint-id': appoint.appointId
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </ErrorBoundary>
                                                            );
                                                        });

                                                        // ブロックカード
                                                        const relatedBlocks = dayBlockMapByFacilityId[facility.id] ?? [];
                                                        const blockCards = relatedBlocks.map((block: BlockType) => (
                                                            <ErrorBoundary key={block.id}>
                                                                <BlockCard
                                                                    block={block}
                                                                    firstCardTime={firstCardTime}
                                                                    quarterTimeStamp={quarterTimeStamp}
                                                                    isBlockEdit={operation === 'edit' && blockId === block.id}
                                                                    blockId={blockId}
                                                                    endTime={endTime ?? 0}
                                                                    onClickHandler={() => {
                                                                        if (matches) {
                                                                            setOpenBlockInfoForSP(true);
                                                                            setBlockParams(getUnixTime(currentDate), block.id, block.startTime, block.endTime, block.facilityId);
                                                                            return;
                                                                        }
                                                                        navigate({ to: `add-appoint`, fromCurrent: false });
                                                                        setBlockParams(getUnixTime(currentDate), block.id, block.startTime, block.endTime, block.facilityId);
                                                                        handleResetAndOpenPane(subPanesRef, viewSizes, reset, setIsPaneOpen);
                                                                    }}
                                                                />
                                                            </ErrorBoundary>
                                                        ));

                                                        const interactionCard = facilityId === facility.id && endTime !== undefined && (
                                                            <InteractionCard
                                                                firstCardTime={firstCardTime}
                                                                quarterTimeStamp={quarterTimeStamp}
                                                                startTime={startTime ?? 0}
                                                                endTime={endTime}
                                                            />
                                                        );

                                                        // シフト枠
                                                        const clinicTimeRange = Array.from(
                                                            { length: shiftFrameEnd - shiftFrameStart + 1 },
                                                            (_, i) => i + shiftFrameStart
                                                        );

                                                        const shiftFrames = clinicTimeRange.map((currentHour) => {
                                                            const isShiftStart = currentHour === shiftStartHours;
                                                            const isShiftEnd = currentHour === shiftEndHours;
                                                            const isShiftEndOnHourBoundary = isShiftEnd && shiftEndMinutes === 0;

                                                            if (isShiftEndOnHourBoundary) return null;

                                                            const shiftStartQuarterIndex = (isShiftStart ? shiftStartMinutes : 0) / cardWidth;
                                                            const shiftEndQuarterIndex = (isShiftEnd ? shiftEndMinutes : 60) / cardWidth;

                                                            const isCurrentDayBeforeDay = getUnixTime(startOfDay(currentDate)) < getUnixTime(startOfDay(new Date()));

                                                            return (
                                                                <OneHourSlots
                                                                    key={currentHour}
                                                                    intervalMinute={cardWidth}
                                                                    shiftStartHours={shiftStartHours}
                                                                    shiftStartMinutes={shiftStartMinutes}
                                                                    shiftEndHours={shiftEndHours}
                                                                    shiftEndMinutes={shiftEndMinutes}
                                                                    currentHour={currentHour}
                                                                    isShiftStart={isShiftStart}
                                                                    isShiftEnd={isShiftEnd}
                                                                    shiftStartQuarterIndex={shiftStartQuarterIndex}
                                                                    shiftEndQuarterIndex={shiftEndQuarterIndex}
                                                                    isHoliday={isDateHoliday(currentDate)}
                                                                    isBeforeDay={isCurrentDayBeforeDay}
                                                                    operation={operation}
                                                                    nowDate={currentDate}
                                                                    facility={facility}
                                                                    blockId={blockId}
                                                                    appointId={appointId}
                                                                    startTime={startTime ?? 0}
                                                                    endTime={endTime ?? 0}
                                                                    setValue={setValue}
                                                                    navigate={navigate}
                                                                    setIsPaneOpen={setIsPaneOpen}
                                                                    openPane={openPane}
                                                                    watch={watch}
                                                                    subPanesRef={subPanesRef}
                                                                    viewSizes={viewSizes}
                                                                    setCommonParams={setCommonParams}
                                                                    setBlockParams={setBlockParams}
                                                                    setAppointParams={setAppointParams}
                                                                    resetAppointId={resetAppointId}
                                                                    resetBlockId={resetBlockId}
                                                                />
                                                            );
                                                        });

                                                        return (
                                                            <$FacilityFrame key={`${facility.id}-${dayOffset}`} width={`${facilityWidth}px`}>
                                                                {shiftFrames}
                                                                {interactionCard}
                                                                {blockCards}
                                                                {appointCards}
                                                            </$FacilityFrame>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </_AppointShiftWrapper>
    );
}; 