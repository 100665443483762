import {
    clinicScheduleMutations
} from '@/components/Common/hooks/Mutate/clinicSchedule'

import {
    patientMutations
} from '@/components/Common/hooks/Mutate/patient'

// FIXME: clinicScheduleMutationsと同様に↓を外部ファイルにまとめる
import {
    useAddAppointMutation,
    useUpdateAppointMutation,//更新
    useUpdateAppointInfoMutation,//更新(時刻除く)
    useDeleteAppointMutation,//削除(ハードデリート) 

    useAddBlockMutation,
    useUpdateBlockMutation,
    useUpdateBlockStatusMutation,
    useDeleteBlockMutation,

    useAddCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useTransactDeleteCategorysMutation,

    useAddCommentsMutation,

    useAddFacilityMutation,
    useUpdateFacilityMutation,
    useDeleteFacilityMutation,

    useAddMenuMutation,
    useUpDateMenuMutation,
    useDeleteMenuMutation,
    useTransactDeleteMenusMutation,

    useAddPhrInfoMutation,
    useDeletePhrInfoConfirmedMutation,

    useAddManegerMutation,
    useUpDateManegerMutation,
    useUpdateManegerColorMutation,
    useDeleteManegerMutation,

    useAddFloorMutation,
    useDeleteFloorMutation,
    useUpdateFloorMutation,
    useUpdateFloorIdxMutation,
    useAddClinicTimeConfigMutation,
    useDeleteClinicConfigMutation,
    useUpdateClinicTimeConfigMutation,
    useAddFacilityGroupMutation,
    useUpdateFacilityGroupMutation,
    useDeleteFacilityGroupMutation,
    useTransactDeleteGroupsMutation,

    useChangeStatusMutation,
    useUpdateFacilityIdxMutation

} from '@/_graphql/graphql-client'
import { GraphQLClient } from 'graphql-request'
import { UseMutationResult, useQueryClient } from 'react-query'
import { AddAppointMutation, AddBlockMutation, DeleteAppointMutation, DeleteBlockMutation, UpdateAppointInfoMutation, UpdateAppointMutation, UpdateBlockMutation, UpdateBlockStatusMutation } from '@/_graphql/graphql-client'


export const useInitMutate = (
    graphqlClient: GraphQLClient
) => {
    const queryClient = useQueryClient()

    const appointOption = {
        onSettled: () => {
            queryClient.invalidateQueries('getAppoint');
            queryClient.invalidateQueries('listAppointById');
            queryClient.invalidateQueries('listPeriodAppointById');
            queryClient.invalidateQueries('scanPeriodAppoint');

            queryClient.invalidateQueries('listAppointForAppointShift');
            queryClient.invalidateQueries('listAppointForAppointList');
            queryClient.invalidateQueries('listAppointByIdV2')
        },
        onSuccess: (data: AddAppointMutation | UpdateAppointMutation | UpdateAppointInfoMutation | DeleteAppointMutation) => {
            queryClient.invalidateQueries('scanPeriodAppoint').catch(() => {});
            if ('addAppoint' in data && data.addAppoint.isDubbleBooking ||
                'updateAppoint' in data && data.updateAppoint.isDubbleBooking ||
                'updateAppointInfo' in data && data.updateAppointInfo.isDubbleBooking
            ) {
                alert('登録時間が重複しています')
            }
            return;
        },
        onError: (err: Error) => {
            // console.log('予約のmutate処理に失敗しました')
        }
    }

    const changeAppointOption = {
        onSettled: () => {
            queryClient.invalidateQueries('getAppoint');
            queryClient.invalidateQueries('listAppointById');
            queryClient.invalidateQueries('listPeriodAppointById');
            queryClient.invalidateQueries('scanPeriodAppoint');
            queryClient.invalidateQueries('listRecallItemsByClinicAndStatus');
            queryClient.invalidateQueries('listRecallItemsByClinicPatientAndMenu');
            queryClient.invalidateQueries('listRecallItems');
            queryClient.invalidateQueries('getAppointByIdV2');

            queryClient.invalidateQueries('listAppointForAppointShift');
            queryClient.invalidateQueries('listAppointForAppointList');
            queryClient.invalidateQueries('listAppointByIdV2')
        },
        onError: (err: Error) => {
            // console.log('予約のステータス変更に失敗しました')
        }
    }

    const addAppoint = useAddAppointMutation(graphqlClient, appointOption)
    const updateAppoint = useUpdateAppointMutation(graphqlClient, appointOption);
    const updateAppointInfo = useUpdateAppointInfoMutation(graphqlClient, appointOption);
    const deleteAppoint = useDeleteAppointMutation(graphqlClient, appointOption);
    const changeAppointStatus = useChangeStatusMutation(graphqlClient, changeAppointOption)

    const blockOption = {
        onSettled: () => {
            queryClient.invalidateQueries('getBlock');
            queryClient.invalidateQueries('scanPeriodBlock');
        },
        onSuccess: (data: AddBlockMutation | UpdateBlockMutation | UpdateBlockStatusMutation | DeleteBlockMutation) => {
            queryClient.invalidateQueries('getBlock');
            queryClient.invalidateQueries('scanPeriodBlock');
            if ('addBlock' in data && data.addBlock.isNonAccepted ||
                'updateBlock' in data && data.updateBlock.isNonAccepted ||
                'updateBlockStatus' in data && data.updateBlockStatus.isNonAccepted
            ) {
                alert('登録時間が重複しています')
            }
        },
        onError: () => {
            alert('ブロック枠の処理に失敗しました')
        }
    }
    const addBlock = useAddBlockMutation(graphqlClient, blockOption)
    const updateBlock = useUpdateBlockMutation(graphqlClient, blockOption);
    const updateBlockStatus = useUpdateBlockStatusMutation(graphqlClient, blockOption);
    const deleteBlock = useDeleteBlockMutation(graphqlClient, blockOption)

    const categoryOption = {
        onSettled: () => {
            queryClient.invalidateQueries('listCategorys')
        }
    }
    const addCategory = useAddCategoryMutation(graphqlClient, categoryOption)
    const updateCategory = useUpdateCategoryMutation(graphqlClient, categoryOption)
    const deleteCategory = useDeleteCategoryMutation(graphqlClient, categoryOption)
    const transactDeleteCategorys = useTransactDeleteCategorysMutation(graphqlClient, categoryOption)

    const commentOption = {
        onSettled: () => {
            queryClient.invalidateQueries('getComments')
        }
    }
    const addComments = useAddCommentsMutation(graphqlClient, commentOption)

    const facilityOption = {
        onSettled: () => {
            queryClient.invalidateQueries('listFacilities')
            queryClient.invalidateQueries('listFacilityGroups')
            queryClient.invalidateQueries('listFacilitiesByGroupIds')
            queryClient.invalidateQueries('listGroupsByFacilityIds')
        }
    }
    const addFacility = useAddFacilityMutation(graphqlClient, facilityOption)
    const updateFacility = useUpdateFacilityMutation(graphqlClient, facilityOption)
    const deleteFacility = useDeleteFacilityMutation(graphqlClient, facilityOption)
    const updateFacilityIdx = useUpdateFacilityIdxMutation(graphqlClient, facilityOption)

    const facilityGroupOption = {
        onSettled: () => {
            queryClient.invalidateQueries('listFacilities')
            queryClient.invalidateQueries('listFacilityGroups')
            queryClient.invalidateQueries('listFacilitiesByGroupIds')
            queryClient.invalidateQueries('listGroupsByFacilityIds')
        }
    }

    const addFacilityGroup = useAddFacilityGroupMutation(graphqlClient, facilityGroupOption)
    const updateFacilityGroup = useUpdateFacilityGroupMutation(graphqlClient, facilityGroupOption)
    const deleteFacilityGroup = useDeleteFacilityGroupMutation(graphqlClient, facilityGroupOption)
    const transactDeleteGroups = useTransactDeleteGroupsMutation(graphqlClient, facilityGroupOption)

    const menuOption = {
        onSettled: () => {
            queryClient.invalidateQueries('listMenu')
        }
    }
    const addMenu = useAddMenuMutation(graphqlClient, menuOption)
    const updateMenu = useUpDateMenuMutation(graphqlClient, menuOption)
    const deleteMenu = useDeleteMenuMutation(graphqlClient, menuOption)
    const transactDeleteMenus = useTransactDeleteMenusMutation(graphqlClient, menuOption)


    const phrInfoOption = {
        onSettled: () => {
            queryClient.invalidateQueries('GetPhrInfoById')
            queryClient.invalidateQueries('listPeriodPhrInfoById')
        }
    }
    const addPhrInfo = useAddPhrInfoMutation(graphqlClient, phrInfoOption)
    const deletePhrInfo = useDeletePhrInfoConfirmedMutation(graphqlClient, phrInfoOption)

    const staffOption = {
        onSettled: () => {
            queryClient.invalidateQueries('listManeger')
        }
    }
    const addStaff = useAddManegerMutation(graphqlClient, staffOption)
    const updateStaff = useUpDateManegerMutation(graphqlClient, staffOption)
    const updateStaffColor = useUpdateManegerColorMutation(graphqlClient, staffOption)
    const deleteStaff = useDeleteManegerMutation(graphqlClient, staffOption)

    const floorOption = {
        onSettled: () => {
            queryClient.invalidateQueries('listFacilities')
            queryClient.invalidateQueries('listFloors')
        }
    }

    const addFloor = useAddFloorMutation(graphqlClient, floorOption)
    const deleteFloor = useDeleteFloorMutation(graphqlClient, floorOption)
    const updateFloor = useUpdateFloorMutation(graphqlClient, floorOption)
    const updateFloorIdx = useUpdateFloorIdxMutation(graphqlClient, floorOption)

    const clinicConfigOption = {
        onSettled: () => {
            queryClient.invalidateQueries()
            queryClient.invalidateQueries('listClinicTimeConfig')
        }
    }

    const addClinicTimeConfig = useAddClinicTimeConfigMutation(graphqlClient, clinicConfigOption)
    const updateClinicTimeConfig = useUpdateClinicTimeConfigMutation(graphqlClient, clinicConfigOption)
    //削除はclinicConfig共通
    const deleteClinicConfig = useDeleteClinicConfigMutation(graphqlClient, clinicConfigOption)

    const clinicSchedule = clinicScheduleMutations(graphqlClient, queryClient)
    const patient = patientMutations(graphqlClient, queryClient)


    return {
        addAppoint,
        updateAppoint,
        updateAppointInfo,
        deleteAppoint,
        changeAppointStatus,
        //block
        addBlock,
        updateBlock,
        updateBlockStatus,
        deleteBlock,
        //category
        addCategory,
        updateCategory,
        deleteCategory,
        transactDeleteCategorys,
        //comment
        addComments,
        //facility
        addFacility,
        updateFacility,
        deleteFacility,
        updateFacilityIdx,
        //faciltyGroup
        addFacilityGroup,
        updateFacilityGroup,
        deleteFacilityGroup,
        transactDeleteGroups,
        //menu
        addMenu,
        updateMenu,
        deleteMenu,
        transactDeleteMenus,
        //phr
        addPhrInfo,
        deletePhrInfo,
        //staff
        addStaff,
        updateStaff,
        updateStaffColor,
        deleteStaff,
        //floor
        addFloor,
        deleteFloor,
        updateFloor,
        updateFloorIdx,
        //clinicConfig
        addClinicTimeConfig,
        updateClinicTimeConfig,
        deleteClinicConfig,

        // clinicSchedules
        ...clinicSchedule,
        // 患者関連のMutation
        ...patient,
    }
}