import { useState } from 'react'
import { useUpDateMenuMutation, MedicalCheckupFrequency } from '@/_graphql/graphql-client'
import { useQueryClient } from 'react-query'
import { useGraphqlClient } from '@/App'
import { type CategoryType, type MenuType } from '../util/common/type'
import type { Dispatch, SetStateAction } from 'react'

interface UseMedicalMenuRecordProps {
  record: MenuType
  categoryList: CategoryType[]
  filteredLists: MenuType[]
  lastIndexNum: number
  setBulkDeleteArr: Dispatch<SetStateAction<number[]>>
}

type UpdateMenuInput = {
  SDK_ID: string
  amount?: number
  role?: string
  name?: string
  abbreviation?: string
  subCategoryId?: string
  parts?: string
  status?: string
  type?: string
  time?: number
  isDelete?: boolean
  index?: number
  clinic_id?: string
  isPeriodicCheckup?: boolean
  checkupFrequency?: MedicalCheckupFrequency | null
  checkupIntervalDays?: number | null
}

const convertCheckupFrequency = (frequency: string): MedicalCheckupFrequency | null => {
  switch (frequency) {
    case 'WEEKLY':
      return MedicalCheckupFrequency.Weekly
    case 'BIWEEKLY':
      return MedicalCheckupFrequency.Biweekly
    case 'MONTHLY':
      return MedicalCheckupFrequency.Monthly
    case 'BIMONTHLY':
      return MedicalCheckupFrequency.Bimonthly
    case 'QUARTERLY':
      return MedicalCheckupFrequency.Quarterly
    case 'CUSTOM':
      return MedicalCheckupFrequency.Custom
    default:
      return null
  }
}

export const useMedicalMenuRecord = ({
  record,
  categoryList,
  filteredLists,
  lastIndexNum,
  setBulkDeleteArr
}: UseMedicalMenuRecordProps): {
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  isCheck: boolean
  setIsCheck: Dispatch<SetStateAction<boolean>>
  openDeleteDialog: boolean
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>
  updateMenuIndexExecute: (isUp?: boolean) => void
  currentCategory: CategoryType[]
  styles: {
    deleteBtnCss: string
    disableBtnCss: string
  }
} => {
  const graphqlClient = useGraphqlClient()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const styles = {
    deleteBtnCss: 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-20 text-center rounded-2xl bg-red-700 hover:bg-red-900',
    disableBtnCss: 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-20 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'
  }

  const currentCategory = categoryList.filter((category: CategoryType) => {
    return category.id === record.subCategoryId
  })

  const queryClient = useQueryClient()
  const updateMenu = useUpDateMenuMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listMenu').catch(() => { console.log('on Settled') })
    },
    onSuccess: res => {
      console.log(res)
    },
    onError: error => {
      throw error
    },
    retry: 3
  })

  const convertToUpdateMenuInput = (menu: MenuType): UpdateMenuInput => ({
    SDK_ID: menu.SDK_ID,
    amount: menu.amount,
    role: menu.role,
    name: menu.name,
    abbreviation: menu.abbreviation,
    subCategoryId: menu.subCategoryId,
    parts: menu.parts,
    status: menu.status,
    type: menu.type,
    time: menu.time,
    isDelete: menu.isDelete,
    index: menu.index,
    clinic_id: menu.SDK_ID.split('_')[0],
    isPeriodicCheckup: menu.isPeriodicCheckup,
    checkupFrequency: menu.checkupFrequency ? convertCheckupFrequency(menu.checkupFrequency) : null,
    checkupIntervalDays: menu.checkupIntervalDays
  })

  const updateMenuIndexExecute = (isUp: boolean = false): void => {
    setBulkDeleteArr([])
    let isRecord2 = true
    if (record.index === 1) {
      record.index++
    } else if (record.index > 1 && record.index < lastIndexNum) {
      if (isUp) {
        record.index--
      } else {
        record.index++
      }
    } else if (record.index === lastIndexNum) {
      record.index--
    } else {
      record.index = lastIndexNum + 1
      isRecord2 = false
    }

    const record2 = filteredLists.filter((menu: MenuType) => {
      return menu.index === record.index
    })
    if (isRecord2) {
      if (isUp) {
        record2[0].index++
        updateMenu.mutate(convertToUpdateMenuInput(record2[0]))
      } else {
        record2[1].index--
        updateMenu.mutate(convertToUpdateMenuInput(record2[1]))
      }
    }
    updateMenu.mutate(convertToUpdateMenuInput(record))
  }

  return {
    editMode,
    setEditMode,
    isCheck,
    setIsCheck,
    openDeleteDialog,
    setOpenDeleteDialog,
    updateMenuIndexExecute,
    currentCategory,
    styles
  }
} 