import {
  shiftType,
  Appoints,
  listManegerType,
  BlockType,
  CategoryType,
  MenuType,
  ClinicTimeType
} from '@/feature/phr-28/components/common/type'
import { PatientsType } from "@/components/Patient/components/util/common/type";

export const emptyClinic = {
  clinic_id: '',
  org_id: '',
  clinic_label: '',
  org_label: '',
  clinic_name: '医院名未登録',
  clinic_time: {
    startTime: {
      hours: 0,
      minutes: 0,
    },
    endTime: {
      hours: 21,
      minutes: 0
    }
  }
}

export const emptyClinicTime: ClinicTimeType = {
  start: {
    hours: 0,
    minutes: 0,
  },
  end: {
    hours: 21,
    minutes: 0
  }
}

export const emptyComment = {
  clinic_id: '',
  date: 1,
  comment: '',
  commentId: ''
}

export const emptyAppoint: Appoints = {
  appoint: '0',
  appointId: '0',
  date: 0,
  endTime: 0,
  id: '0',
  menuId: '0',
  name: '0',
  staffIds: [{ index: 0, id: '0' }, { index: 1, id: '0' }, { index: 2, id: '0' }],
  startTime: 0,
  type: '0',
  secondStartTime: 0,
  secondEndTime: 0,
  thirdStartTime: 0,
  thirdEndTime: 0,
  remarks: '',
  facilityId: '',
  price: 0,
  confirmedTime: 0,
  confirmedEndTime: 0,
  created_at: 1,
  updated_at: 1
}

export const emptyBlock: BlockType = {
  id: '',
  startTime: 1,
  endTime: 1,
  facilityId: '',
  remarks: '',
  color: '',
  isNonAccepted: false,
  isDelete: false
}

export const emptyManeger: listManegerType = {
  id: '',
  role: '',
  name: '',
  rights: '',
  email: '',
  color: '',
  isDelete: false,
  index: 0
}

export const emptyPatients: PatientsType = {
  DNumber: null,
  SNumber: null,
  address: {
    house: '',
    post: '',
    prefectures: ''
  },
  age: 0,
  birthday: 0,
  comment: '',
  email: '',
  gender: '',
  group: '',
  id: '',
  invitationNumber: '',
  kana: { last: '', first: '' },
  lineId: '',
  name: { first: '', last: '' },
  remarks: '',
  searchName: '',
  tel: '',
  token: '',
  searchFull: '',
  searchKana: '',
  status: "",
  from_p5g: false,
}

export const emptyCategory: CategoryType = {
  id: '',
  name: '',
  mainCategory: '',
  isDelete: false
}

export const emptyMenu: MenuType = {
  SDK_ID: '',
  name: '',
  abbreviation: '',
  subCategoryId: '',
  parts: '',
  amount: 1,
  time: 1,
  type: '',
  role: '',
  status: '',
  courponId: '',
  isDelete: false,
  index: 0,
  isPeriodicCheckup: false,
  checkupFrequency: null,
  checkupIntervalDays: null
}

export const workingTimeAm: string[] = [
  '8:00',
  '8:15',
  '8:30',
  '8:45',
  '9:00',
  '9:15',
  '9:30',
  '9:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45'
]
export const workingTimePm: string[] = [
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45'
]

export const emptyShiftData: shiftType = {
  date: 0,
  id: '',
  name: '',
  shifts: [],
  workingTimes: { am: workingTimeAm, pm: workingTimePm }
}
