import { type ReactNode } from 'react';

interface $FacilityFrameProps {
    width: string;
    children?: ReactNode;
}

export const $FacilityFrame: React.FC<$FacilityFrameProps> = ({
    width,
    children,
}) => {
    const frameClassName = 'relative overflow-hidden border-r border-white';
    const frameStyle = {
        width,
        // minWidth: '150px'
    };

    return (
        <div style={frameStyle} className={frameClassName}>
            {children}
        </div>
    )
}