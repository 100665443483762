import React from 'react';
import type { SortKey } from '../types';

interface SortControlsProps {
  sortKey: SortKey;
  sortOrder: 'asc' | 'desc';
  onSortChange: (key: SortKey) => void;
}

const sortLabels: Record<SortKey, string> = {
  lastTreatmentDate: '前回治療日',
  nextDueDate: '次回予定日',
  daysUntilDue: '残り日数',
};

export const SortControls: React.FC<SortControlsProps> = ({ sortKey, sortOrder, onSortChange }) => (
  <div className="flex gap-2">
    {Object.entries(sortLabels).map(([key, label]) => (
      <button
        key={key}
        onClick={() => onSortChange(key as SortKey)}
        className={`px-3 py-1 text-sm rounded-md ${
          sortKey === key ? 'bg-blue-100 text-blue-800' : 'bg-gray-100'
        }`}
      >
        {label} {sortKey === key && (sortOrder === 'asc' ? '↑' : '↓')}
      </button>
    ))}
  </div>
); 