import type { AppointShiftPresenterPropsType } from '@/components/Reservation/entities';
import { getUnixTime, startOfDay, addDays, endOfDay } from 'date-fns';
import { openPane } from '@/components/Main/util';
import { type BlockType } from '@/components/Block/entities';
import { MethodsContext } from '@/components/Main/provider/FormModeProvider';
import { useContext, useState, useEffect } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { ErrorBoundary } from '@/components/Common/components';
import { AppointCardPresenter } from './AppointCardPresenter';
import { useDisplayDays } from '../../hooks/useDisplayDays';
import { NotificateEditMode } from '@/feature/phr-28/components/template/Appointment/ModalContents/NotificateEditMode';
import { useSPAppoint } from '@/feature/phr-28/components/template/Appointment/smartPhone/useSPAppoint';
import { useSPBlockInfo } from '@/feature/phr-28/components/template/Appointment/smartPhone/useBlockInfo';
import { $FacilityFrame, _AppointShiftWrapper, $TimeBar, BlockCard, OneHourSlots, InteractionCard } from '../parts/AppointSchedule/AppointShift';
import { type viewSizesType } from '@/components/Common/entities';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { useAppointParams } from '@/domain/Appointment/useAppointParams';
import { useBlockParams } from '@/domain/Block/useBlockParams';
import { type AppointDetails, useGetLatestClinicConfigQuery, useListClinicSchedulesByClinicIdQuery } from '@/_graphql/graphql-client';
import { ClinicContext, useGraphqlClient } from '@/App';
import { DailyShiftPrintView } from '../parts/AppointSchedule/AppointShift/presenter/DailyShiftPrintView';
import { WeeklyShiftPresenter } from '../parts/AppointSchedule/AppointShift/presenter/WeeklyShiftPresenter';
import { $DateHeader } from '../parts/AppointSchedule/AppointShift/extendedStyled/$DateHeader';

export const hourTimeStamp: number = 60 * 60

export const AppointShiftPresenter: React.FC<AppointShiftPresenterPropsType> = ({
    printRef,
    isHoliday,
    isBeforeDay,
    setIsPaneOpen,
    shiftFrameStart,
    shiftFrameEnd,
    operation,
    shiftStartHours,
    shiftEndHours,
    shiftStartMinutes,
    shiftEndMinutes,
    appointId,
    blockId,
    viewSizes,
    subPanesRef,
    navigate,
    clinic,
    nowDate,
    startTime,
    endTime,
    allReservationList,
    allBlockList,
    facilityList,
}) => {
    const graphqlClient = useGraphqlClient()
    const { clinic_id: clinicId } = useContext(ClinicContext);
  
    // clinic設定の取得
    const { data: cardWidthList } = useGetLatestClinicConfigQuery(graphqlClient, {
        clinic_id: clinicId,
        current_date: getUnixTime(startOfDay(nowDate)),
        config_type: 'card_width',
    })

    // 休診日情報の取得
    const { data: clinicSchedules } = useListClinicSchedulesByClinicIdQuery(graphqlClient, {
        clinic_id: clinicId
    })

    // 指定された日付が休診日かどうかを判定する関数
    const isDateHoliday = (date: Date): boolean => {
        const schedules = clinicSchedules?.listClinicSchedulesByClinicId?.clinic_schedules;
        if (schedules == null) return false;

        const targetTimestamp = getUnixTime(startOfDay(date));
        return schedules.some(schedule => 
            schedule.rule_type === 'SPECIFIC_DATES' && 
            schedule.specific_date === targetTimestamp && 
            schedule.service_day === false
        );
    };

    const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
        return config?.__typename === "CardWidthConfig";
    };

    const configData = cardWidthList?.getLatestClinicConfig?.config ?? null;
    const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 15;

    const quarterTimeStamp: number = hourTimeStamp / (60 / cardWidth)

    const matches = useMediaQuery('(max-width: 926px)')

    const { methods } = useContext(MethodsContext)
    const { facilityId, setCommonParams } = useCommonParams()
    const { setAppointParams, resetAppointId } = useAppointParams()
    const { setBlockParams, resetBlockId } = useBlockParams()

    const { watch, setValue, reset } = methods

    const { SPAppointModal } = useSPAppoint()
    const { BlockInfoModal } = useSPBlockInfo()
    const [openAppointInfoForSP, setOpenAppointInfoForSP] = useState(false)
    const [openBlockInfoForSP, setOpenBlockInfoForSP] = useState(false)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { displayDays } = useDisplayDays()
    const totalDays = parseInt(displayDays)
    // 1画面に14施設を表示したいので逆算する
    // 画面幅から時間バーの幅(50px)を引いた値を14で割って基本幅を計算
    const baseWidth = (windowWidth - 50) / 14 // 14分割した幅を基準とする
    const facilityWidth = baseWidth // 各施設の幅
    const dayWidth = facilityWidth * facilityList.length // 1日分の幅

    const facilityFrameWidth = 100 / facilityList.length;

    // appoint.statusが'キャンセル'の予約を除外したリスト
    const reservationList = allReservationList?.filter(appoint => appoint.appoint !== 'キャンセル') ?? [];

    const [currentAppoint, setCurrentAppoint] = useState<AppointDetails | null>(null)
    const [currentBlock, setCurrentBlock] = useState<BlockType | null>(null)

    const isOpenAppointModal = openAppointInfoForSP && currentAppoint !== null
    const isOpenBlockModal = openBlockInfoForSP && currentBlock !== null

    // 予約情報を施設IDごとにマッピング
    const AppointMapByFacilityId = reservationList.reduce<Record<string, AppointDetails[]>>((acc, appoint) => {
        const facilityId = appoint.facilityId;
        if (facilityId !== undefined && facilityId !== '') {
            acc[facilityId] = acc[facilityId] || [];
            acc[facilityId].push(appoint);
        }
        return acc;
    }, {});

    // ブロック情報を施設IDごとにマッピング
    const blockMapByFacilityId = allBlockList.reduce<Record<string, BlockType[]>>((acc, block) => {
        const facilityId = block.facilityId;
        if (facilityId !== undefined && facilityId !== '') {
            acc[facilityId] = acc[facilityId] || [];
            acc[facilityId].push(block);
        }
        return acc;
    }, {});

    const handleCloseAppointModal = (): void => {
        setOpenAppointInfoForSP(false);
        setCurrentAppoint(null);
        resetAppointId()
    };

    const handleCloseBlockModal = (): void => {
        setOpenBlockInfoForSP(false);
        setCurrentBlock(null);
        resetBlockId()
    };

    return (
        <>
            {isOpenAppointModal &&
                <ErrorBoundary>
                    <SPAppointModal
                        appointDetail={currentAppoint}
                        closeModal={handleCloseAppointModal} />
                </ErrorBoundary>
            }

            {isOpenBlockModal &&
                <ErrorBoundary>
                    <BlockInfoModal
                        block={currentBlock}
                        facilityList={facilityList}
                        closeModal={handleCloseBlockModal}
                    />
                </ErrorBoundary>
            }

            {/* コンポーネントwrapper */}
            {(operation === 'edit' || operation === 'copy') &&
                <ErrorBoundary>
                    <NotificateEditMode
                        navigate={navigate}
                        operation={operation}
                    />
                </ErrorBoundary>
            }

            {totalDays === 1 ? (
                <DailyShiftPrintView
                    printRef={printRef}
                    facilityList={facilityList}
                    facilityFrameWidth={facilityFrameWidth}
                    shiftFrameStart={shiftFrameStart}
                    shiftFrameEnd={shiftFrameEnd}
                    shiftEndMinutes={shiftEndMinutes}
                    shiftStartHours={shiftStartHours}
                    shiftStartMinutes={shiftStartMinutes}
                    shiftEndHours={shiftEndHours}
                    nowDate={nowDate}
                    operation={operation}
                    appointId={appointId}
                    blockId={blockId}
                    facilityId={facilityId ?? ''}
                    startTime={startTime ?? 0}
                    endTime={endTime ?? 0}
                    AppointMapByFacilityId={AppointMapByFacilityId}
                    blockMapByFacilityId={blockMapByFacilityId}
                    cardWidth={cardWidth}
                    hourTimeStamp={hourTimeStamp}
                    quarterTimeStamp={quarterTimeStamp}
                    isHoliday={isHoliday}
                    isBeforeDay={isBeforeDay}
                    matches={matches}
                    setAppointParams={setAppointParams}
                    setBlockParams={setBlockParams}
                    setOpenAppointInfoForSP={setOpenAppointInfoForSP}
                    setOpenBlockInfoForSP={setOpenBlockInfoForSP}
                    setIsPaneOpen={setIsPaneOpen}
                    navigate={navigate}
                    reset={reset}
                    setValue={setValue}
                    watch={watch}
                    subPanesRef={subPanesRef}
                    viewSizes={viewSizes}
                    setCommonParams={setCommonParams}
                    resetAppointId={resetAppointId}
                    resetBlockId={resetBlockId}
                    openPane={openPane}
                />
            ) : (
                <WeeklyShiftPresenter
                    printRef={printRef}
                    facilityList={facilityList}
                    totalDays={totalDays}
                    facilityWidth={facilityWidth}
                    dayWidth={dayWidth}
                    shiftFrameStart={shiftFrameStart}
                    shiftFrameEnd={shiftFrameEnd}
                    shiftEndMinutes={shiftEndMinutes}
                    shiftStartHours={shiftStartHours}
                    shiftStartMinutes={shiftStartMinutes}
                    shiftEndHours={shiftEndHours}
                    nowDate={nowDate}
                    operation={operation}
                    appointId={appointId}
                    blockId={blockId}
                    facilityId={facilityId ?? ''}
                    startTime={startTime ?? 0}
                    endTime={endTime ?? 0}
                    reservationList={reservationList}
                    allBlockList={allBlockList}
                    cardWidth={cardWidth}
                    hourTimeStamp={hourTimeStamp}
                    quarterTimeStamp={quarterTimeStamp}
                    isDateHoliday={isDateHoliday}
                    matches={matches}
                    setAppointParams={setAppointParams}
                    setBlockParams={setBlockParams}
                    setOpenAppointInfoForSP={setOpenAppointInfoForSP}
                    setOpenBlockInfoForSP={setOpenBlockInfoForSP}
                    setIsPaneOpen={setIsPaneOpen}
                    navigate={navigate}
                    reset={reset}
                    setValue={setValue}
                    watch={watch}
                    subPanesRef={subPanesRef}
                    viewSizes={viewSizes}
                    setCommonParams={setCommonParams}
                    resetAppointId={resetAppointId}
                    resetBlockId={resetBlockId}
                    openPane={openPane}
                />
            )}
        </>
    );
};