import React from 'react';
import type { Recall } from '@/_graphql/graphql-client';

interface RecallPatientBasicInfoProps {
  recall: Recall;
}

export const RecallPatientBasicInfo: React.FC<RecallPatientBasicInfoProps> = ({ recall }) => {
  const { patient, patientId, medicalMenu, medicalMenuId } = recall;

  const patientName = (() => {
    const firstName = patient?.name?.first;
    const lastName = patient?.name?.last;
    if (firstName === undefined && lastName === undefined) {
      return `患者ID: ${patientId}`;
    }
    return `${lastName ?? ''} ${firstName ?? ''}`.trim();
  })();

  const SNumber = patient?.SNumber ?? '';
  const menuName = medicalMenu?.name ?? `メニューID: ${medicalMenuId}`;

  return (
    <>
      <div className="flex items-center gap-2">
        <span className="text-sm text-gray-500">名前:</span>
        <p className="text-sm font-semibold leading-6 text-gray-900">
          {patientName}
        </p>
      </div>
      <div className="flex items-center gap-2 mt-1">
        <span className="text-sm text-gray-500">患者番号:</span>
        {(SNumber !== null && SNumber !== 0) && (
          <span className="text-sm text-gray-900">
            {SNumber}
          </span>
        )}
      </div>
      <div className="flex items-center gap-2 mt-1">
        <span className="text-sm text-gray-500">治療内容:</span>
        <p className="text-sm text-gray-900">
          {menuName}
        </p>
      </div>
    </>
  );
}; 