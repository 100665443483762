import React from 'react';
import type { Recall } from '@/_graphql/graphql-client';
import { RecallPatientBasicInfo } from './RecallPatientBasicInfo';
import { RecallDateInfo } from './RecallDateInfo';

interface RecallPatientInfoProps {
  recall: Recall;
}

export const RecallPatientInfo: React.FC<RecallPatientInfoProps> = ({ recall }) => {
  return (
    <div className="min-w-0 flex-auto">
      <RecallPatientBasicInfo recall={recall} />
      <RecallDateInfo recall={recall} />
    </div>
  );
}; 