import { ErrorBoundary } from '@/components/Common/components';
import type { BottomPaneType } from '@/components/Main/entities';
import { AppointViews } from '@/components/Reservation';

export const BottomPane: React.FC<BottomPaneType> = ({
    clinic,
    graphqlClient,
    search,
    pathname,
    operation,
    // パラメータ
    patientId,
    blockId,
    startTime,
    endTime,
    facilityId,
    appointId
}) => {
    return (
        <>
            <ErrorBoundary>
                <AppointViews
                    clinic={clinic}
                    graphqlClient={graphqlClient}

                    search={search}
                    pathname={pathname}
                    operation={operation}

                    //パラメータ
                    patientId={patientId}
                    blockId={blockId}
                    startTime={startTime}
                    endTime={endTime}
                    facilityId={facilityId}
                    appointId={appointId}
                />
            </ErrorBoundary>
        </>
    )
}
