import type { AppointRefreshControlerProps } from "../../../../entities"
import { RefreshButton } from "./RefreshButton"
import { RefreshIndicator } from "./RefreshIndicator"

export const AppointRefreshControler: React.FC<AppointRefreshControlerProps> = ({
    isRefresh,
    onRefresh
}) => {

    return (
        <>
            {/* ローダー */}
            <RefreshIndicator
                isRefresh={isRefresh}
            />
            {/* 更新ボタン */}
            <RefreshButton
                isRefresh={isRefresh}
                onRefresh={onRefresh}
            />
        </>
    )
}
